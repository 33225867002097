import React from 'react';

import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route,
} from "react-router-dom";

import Home from './component/Home';

import {RouteStrings} from './component/Routes';
import EditUser from './component/UserOperations/EditUser';
import SuccessPage from './component/CreateAccount/SuccessPage';
import Login from './component/Login';

function App() {
  return (
    <>
        <BrowserRouter>
          <Routes>
            <Route path={RouteStrings.HomePage} element={<Home/>} />
          </Routes>
          <Routes>
            <Route path={RouteStrings.UserRegister} element={<Home/>} />
          </Routes>
          <Routes>
            <Route path={RouteStrings.EditUser} element={<Home/>} />
          </Routes>
          <Routes>
            <Route path={RouteStrings.ViewUser} element={<Home/>} />
          </Routes>
          <Routes>
            <Route path={RouteStrings.Loans} element={<Home/>} />
          </Routes>
          <Routes>
            <Route path={RouteStrings.ViewLoans} element={<Home/>} />
          </Routes>
          <Routes>
            <Route path={RouteStrings.AddLoan} element={<Home/>} />
          </Routes>
          <Routes>
            <Route path={RouteStrings.EditLoan} element={<Home/>} />
          </Routes>
          <Routes>
            <Route path={RouteStrings.AllLoanProduct} element={<Home/>} />
          </Routes>
          <Routes>
            <Route path={RouteStrings.AddLoanProduct} element={<Home/>} />
          </Routes>
          <Routes>
            <Route path={RouteStrings.AddLoanRepayment} element={<Home/>} />
          </Routes>
          <Routes>
            <Route path={RouteStrings.ViewLoanRepayment} element={<Home/>} />
          </Routes>
          <Routes>
            <Route path={RouteStrings.SuccessPage} element={<SuccessPage/>} />
          </Routes>
          <Routes>
            <Route path={RouteStrings.login} element={<Login/>} />
          </Routes>
          <Routes>
            <Route path={RouteStrings.ChangePassword} element={<Home/>} />
          </Routes>
          <Routes>
            <Route path={RouteStrings.EditProfile} element={<Home/>} />
          </Routes>

        </BrowserRouter>


    </>
  );
}

export default App;
