import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Flex, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { MenuProps } from 'antd';
import axios, { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { BackendRootStrings, RouteStrings } from '../Routes';
import { MenuInfo } from 'rc-menu/lib/interface';
import { DownOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { ColorCode } from '../../resources/ColorCodes';
import Cookies from 'universal-cookie';

interface LoanRepaymentInterface {
    LoanID: String,
    Date: String,
    Balance: String,
    Borrower: String,

    Payment_Months: { Principal_Amount: String, Interest: String, Late_Penalty: String, Remarks: String, Repayment_Date: String, Total_Interest: String }[]
}

interface TransformedDataInterface {
    LoanID: String;
    Borrower: String;
    Repayment_Date: String;
    Principal_Amount: String;
    Interest: String;
    Late_Penalty: String;
}




const App: React.FC = () => {

    let dispatch = useDispatch();

    const cookies = new Cookies();

    const [renderTableKey, setRenderTableKey] = useState(Math.random());

    let [data, setData] = useState<LoanRepaymentInterface[]>([]);

    let [transformedData, setTransformedData] = useState<TransformedDataInterface[]>([]);

    let navigate = useNavigate();

    const [actionItems, setActionItems] = useState<MenuProps['items']>([]);

    const handleUserDataDelete = async (currentItem: any) => {
        // console.log(currentItem)
        // await axios.post(`${BackendRootStrings.apiHome}/deleteUserInfo`, currentItem);
    }

    const onMenuClick = (recordTNo: string, Repayment_Date: string) => (info: MenuInfo) => {
        const currentItem = transformedData.find((item, ind) => item.Borrower === recordTNo);
        switch (info.key) {
            case '1':
                // navigate(RouteStrings.EditUser, {
                //     state: {
                //         currentItem: currentItem
                //     }
                // });
                break;
            case '2':
                handleUserDataDelete(currentItem);
                break;
            case '3':
                navigate(RouteStrings.ViewLoans, {
                    state: {
                        path: 'ViewLoanRepayment',
                        currentItem: currentItem
                    }
                });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        let usertype = cookies.get("UserType");
        if (usertype === "Admin") {
            setActionItems(
                [
                    {
                        key: '1',
                        label: 'Loan Details',
                    },
                    {
                        key: '2',
                        label: 'Delete',
                    },
                    {
                        key: '3',
                        label: 'View',
                    },
                ]
            )
        } else {
            setActionItems(
                [
                    {
                        key: '3',
                        label: 'View',
                    },
                ]
            )
        }

    }, [])


    

    const columns: ColumnsType<TransformedDataInterface> = [
        {
            title: 'LoanID',
            dataIndex: 'LoanID',
            key: 'LoanID',
        },
        {
            title: 'Name',
            dataIndex: 'Borrower',
            key: 'Borrower',
        },
        {
            title: 'Payment Date',
            dataIndex: 'Repayment_Date',
            key: 'Repayment_Date',
        },
        {
            title: 'Principal Amount(₹)',
            dataIndex: 'Principal_Amount',
            key: 'Principal_Amount',
        },
        {
            title: 'Interest(%)',
            dataIndex: 'Interest',
            key: 'Interest',
        },
        {
            title: 'Late Penalties(₹)',
            dataIndex: 'Late_Penalty',
            key: 'Late_Penalty',
        },

        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                // <Space size="middle">
                //     <Flex className={record.TNo.toString()} align="flex-start" gap="small" vertical>
                //         <Dropdown.Button type="primary" menu={{ items,onClick: onMenuClick(record.TNo.toString())}}>Actions</Dropdown.Button>
                //     </Flex>
                // </Space>
                <Dropdown menu={{ items:actionItems, onClick: onMenuClick(record.Borrower.toString(), record.Repayment_Date.toString()) }}>
                    <Button type='primary'>
                        <Space>
                            Action
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>

            ),
        },

    ];


    useEffect(() => {
        axios.get(`${BackendRootStrings.apiHome}/getAllLoanRepayment`)
            .then((response: { data: { message: LoanRepaymentInterface[] } }) => {


                setData(response.data.message);
                data = response.data.message;

                setRenderTableKey(Math.random());
                setTransformedData([]);
                let skip = true;
                data.forEach(element => {

                    skip = true;
                    element.Payment_Months.forEach(childElement => {
                        if (!skip) {
                            setTransformedData((prevData) => [...prevData, {
                                LoanID: element.LoanID, Borrower: element.Borrower, Repayment_Date: childElement.Repayment_Date,
                                Principal_Amount: childElement.Principal_Amount, Interest: childElement.Interest, Late_Penalty: childElement.Late_Penalty
                            }]);
                        }
                        skip = false;
                    });
                });
            })
            .catch((error: AxiosError) => {
                console.error('Error:', error.message);
            });

    }, [])


    const handleAddLoanRepayment = () => {
        navigate(RouteStrings.AddLoanRepayment, {
            state: {
                path: 'AddLoanRepayment',
            }
        });
    }


    return (
        <>
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}> <h3>Repayments</h3></div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button style={{ backgroundColor: ColorCode.GreenCode, color: "white", fontWeight: "bold" }} onClick={handleAddLoanRepayment}>Add Repayment</Button>
            </div>
            <Table key={renderTableKey} columns={columns} pagination={{ position: ['topLeft'] }} dataSource={transformedData} />
        </>

    )
}

export default App;