import { Button, DatePicker, Space, Select, InputNumber, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { RouteStrings, BackendRootStrings } from '../Routes';
import axios, { AxiosError } from 'axios';
import { RegexStrings } from '../../resources/Regex';
import dayjs from 'dayjs';
// import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import { TextField } from '@material-ui/core';
import SuccessPage from '../CreateAccount/SuccessPage';
import moment from 'moment'
import Cookies from 'universal-cookie';

const EditUser = () => {

    const cookies = new Cookies();

    const userType = cookies.get('UserType');

    const [loading, setLoading] = useState(false);

    interface submitValueInterface {
        // Date: String,
        LoanID: String,
        Due_Payment_Date: String,
        Late_Penalties: String,
        Principal_Amount: String,
        Interest: string,
        Total_Amount: String,
        Remarks: String,
        Counter: String,
        Total_Interest: String,
        UniqueLoanID: String

    }

    interface CounterListDataType {
        key: React.Key,
        First_Name: String,
        Last_Name: String,
        User_Type: String,
        Email: String,
    }

    interface NewObject {
        label: string;
        value: any;
    }
    interface OriginalObject {
        [key: string]: any;
    }

    const nameRegex = RegexStrings.nameRegex;
    const emptyRegex = RegexStrings.emptyRegex;

    let [submitData, setSubmitData] = useState<submitValueInterface>({
        // Date: '',
        LoanID: '',
        Due_Payment_Date: '',
        Late_Penalties: '',
        Principal_Amount: '',
        Interest: '',
        Total_Amount: '',
        Remarks: '',
        Counter: '',
        Total_Interest: '',
        UniqueLoanID: ''
    });
    let [LoanIDData, setLoanIDData] = useState<NewObject[]>()

    const [renderKey, setRenderKey] = useState(Math.random());

    const [formSuccess, setFormSuccess] = useState(false);

    const [counterList, setCounterList] = useState<NewObject[]>([]);

    let [nextPaymntPenalties, setNextPaymntPenalties] = useState("0");

    const [initialPrincipalAmount, setInitialPrincipalAmount] = useState(0);

    let navigate = useNavigate();
    const [errorList, setErrorList] = useState({ first_name: "", last_name: "" });

    let [tempDateIncrement, setTempDateIncrement] = useState(1);

    let [nextAugust, setNextAugust] = useState<dayjs.Dayjs>();

    // function generateAlphanumericId() {
    //     const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    //     let result = '';

    //     for (let i = 0; i < 6; i++) {
    //         const randomIndex = Math.floor(Math.random() * charset.length);
    //         result += charset[randomIndex];
    //     }

    //     return result;
    // }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const checkError = (myString: string, name: string) => {
        if (emptyRegex.test(myString)) return `${name.split('_').join(' ')} must contain atleast one letter`;
        else if (!nameRegex.test(myString)) return `${name.split('_').join(' ')} must not contain numericals or spaces`;
        return "";
    }


    const handleChange = (e: { target: { value: string, id: string } }, name: string) => {
        let val = (checkError(e.target.value, name));
        if (!val) {
            setErrorList({ first_name: "", last_name: "" });
            setSubmitData((prevState: submitValueInterface) => {
                return {
                    ...prevState,
                    [name]: e.target.value,
                };


            });
        }
        else {

            setErrorList((prevState: { first_name: string, last_name: string } | undefined) => {
                if (!prevState) {
                    return {
                        first_name: "",
                        last_name: "",
                    };
                }
                else if (name === "first_name") {
                    return {
                        ...prevState,
                        first_name: val,
                        last_name: prevState.last_name,
                    };
                }
                else if (name === "last_name") {
                    return {
                        ...prevState,
                        first_name: prevState.first_name,
                        last_name: val,
                    };
                }
                return prevState;

            });
        }

    }

    const onSelectChange = (value: string, field: string) => {

        setSubmitData((prevState: submitValueInterface) => {
            return {
                ...prevState,
                [field]: value
            };
        })
        if (field === "LoanID") {

            // setSubmitData((prevState: submitValueInterface) => {
            //     // console.log(submitData)
            //     const principalAmount = parseFloat(submitData.Principal_Amount?.toString()) || 0;
            //     const interestRate = parseFloat(submitData?.Interest) || 0;
            //     const latePenalties = parseInt(prevState.Late_Penalties?.toString()) || 0;



            //     const tempRate = (principalAmount * interestRate) / 100;

            //     const newTotalAmount =
            //         principalAmount === null
            //             ? interestRate + latePenalties  // Set Total_Amount to Interest + Late_Penalties
            //             : principalAmount + tempRate;
            //     console.log(newTotalAmount," new total amount")
            //     return {
            //         ...prevState,
            //         Total_Amount: newTotalAmount.toString(),
            //     };
            // });
        }



    };

    // useEffect(() => {
    //     console.log(submitData)
    //     if (submitData.Interest !== "") {
    //         setSubmitData((prevState) => {
    //             return {
    //                 ...prevState,
    //                 Total_Amount: (parseInt(prevState?.Principal_Amount.toString()) ?? 0 + ((parseFloat(submitData.Principal_Amount?.toString()) ?? 0) * parseFloat(submitData?.Interest) / 100 || 0) + parseInt(prevState?.Late_Penalties.toString()) ?? 0).toString()
    //             }
    //         })
    //     }

    // }, [submitData.Interest])


    const handleTextChange = (e: any, field: string) => {
        setSubmitData((prevState: submitValueInterface) => {
            return {
                ...prevState,
                [field]: e?.target?.value ?? e
            };
        })

        submitData[field as keyof submitValueInterface] = e?.target?.value ?? e;

        setSubmitData((prevState: submitValueInterface) => {
            const principalAmount = parseFloat(submitData.Principal_Amount?.toString()) || 0;
            const interestRate = parseFloat(submitData?.Interest) || 0;
            const latePenalties = parseInt(prevState.Late_Penalties?.toString()) || 0;

            const tempRate = (initialPrincipalAmount * interestRate*tempDateIncrement) / 100;

            const newTotalAmount =
                principalAmount === null
                    ? interestRate + latePenalties  // Set Total_Amount to Interest + Late_Penalties
                    : principalAmount + tempRate + latePenalties;

            return {
                ...prevState,
                Total_Amount: newTotalAmount.toString(),
            };
        });

        setRenderKey(Math.random());
    };
    const handleSubmit = () => {

        if (!submitData.LoanID) {
            message.error("Loan ID is required")
            return false;
        }
        if (submitData.Principal_Amount === null) {
            submitData.Principal_Amount = "0";
        }
        if (submitData.Late_Penalties === null) {
            submitData.Late_Penalties = "0";
        }
        if (parseInt(submitData.Principal_Amount.toString()) > initialPrincipalAmount) {
            message.error(`Principal Amount must be less than ${initialPrincipalAmount}`)
            return false;
        }
        if (submitData.Due_Payment_Date !== "" && submitData.Counter !== "" && submitData.LoanID !== "") {

            submitData.LoanID = submitData.LoanID.split('-')[1];
            submitData.Total_Interest = (((parseFloat(initialPrincipalAmount?.toString()) ?? 0) * parseFloat(submitData?.Interest) / 100) * tempDateIncrement || 0).toString();
            if (errorList.first_name === "" && errorList.last_name === "") {
                // console.log(submitData)
                setLoading(true);
                axios.put(`${BackendRootStrings.apiHome}/updateLoanRepayment`, submitData)
                    .then(() => {
                        setTimeout(() => {
                            setFormSuccess(false);
                            navigate(0);
                            setLoanIDData([]);
                            LoanIDData = [];
                            setSubmitData((prevState) => {
                                return {
                                    ...prevState,
                                    LoanID: ''
                                }
                            });
                            // handleInitialSetData();
                            setLoading(false);
                        }, 2000);
                        setFormSuccess(true);
                        axios.get(`${BackendRootStrings.apiHome}/getAllLoanData`)
                            .then(({ data }) => {
                                const { message } = data;
                                const newArray: NewObject[] = message.map((obj: OriginalObject) => ({
                                    label: `${obj.Borrower} - ${obj.LoanID}`,
                                    value: `${obj.Borrower}-${obj.LoanID}`
                                }));
                                setLoanIDData(newArray);
                                setRenderKey(Math.random());
                            })
                    })
                    .catch((error: AxiosError) => {
                        console.error('Error:', error.message);
                    });
            }
        }
        else {
            if (submitData.Counter === "") {
                message.error("Counter is Empty");
            }
            else if (submitData.LoanID === "") {
                message.error("Loan ID is Empty");
            }
        }
    }

    const handleInitialSetData = () => {
        // const tempLoanId = submitData.LoanID.split('-')[1];
        if (submitData.UniqueLoanID !== '') {
            axios.get(`${BackendRootStrings.apiHome}/getLatePenaltyData/${submitData.UniqueLoanID}`)
                .then(({ data }) => {
                    const { message } = data;
                    const { mainArray } = message;
                    const { Date } = message;
                    const { Principal_Amount } = message;
                    const { DueAmount } = message;
                    const { UniqueLoanID } = message;
                    // console.log(message)



                    // console.log(mainArray)
                    // console.log("initial",Principal_Amount)
                    // setInitialPrincipalAmount(parseFloat(Principal_Amount));
                    // setInitialPrincipalAmount(mainArray[0].term_period === "Monthly" ? DueAmount / 12 : DueAmount);
                    setInitialPrincipalAmount(DueAmount);
                    // Parse the date string into a Date object
                    let currentDate = moment(Date);

                    const today = moment();

                    // Increment the month by 1
                    currentDate.add(1, 'months');

                    // // Format the result as a string
                    let nextMonth = currentDate.format('ddd MMM DD YYYY');


                    if (currentDate.isBefore(today)) {

                        // setNextPaymntPenalties(mainArray[0]?.late_payment_penalties); 
                        nextPaymntPenalties = mainArray[0]?.late_payment_penalties;
                    }

                    // console.log(mainArray[0])


                    setSubmitData((prevState) => {
                        return {
                            ...prevState,
                            // Interest: mainArray[0]?.rate ?? 0,
                            Interest: mainArray[0]?.rate ?? 0,
                            Late_Penalties: nextPaymntPenalties,
                            Due_Payment_Date: nextMonth,
                            Principal_Amount: DueAmount,
                            Total_Amount: (parseInt(DueAmount) +
                                ((parseInt(DueAmount?.toString()) ?? 0) * parseInt(mainArray[0]?.rate) / 100) || 0
                                + parseInt(nextPaymntPenalties)).toString()
                        }
                    })
                    setRenderKey(Math.random());
                })

        }

    }

    function getNextAugust(duePaymentDate: dayjs.Dayjs) {
        let year = duePaymentDate.year();
        const month = duePaymentDate.month();


        if (month === 8) {

            if (duePaymentDate.isAfter(dayjs().month(8).startOf('month'))) {
                year += 1;
            }
        } else {

            year = month < 8 ? duePaymentDate.year() : duePaymentDate.year() + 1;
        }


        return dayjs().year(year).month(8).startOf('month');
    }

    useEffect(() => {
        handleInitialSetData();
    }, [submitData.LoanID])

    useEffect(() => {
        axios.get(`${BackendRootStrings.apiHome}/getAllCounterDetails`)
            .then(({ data }) => {
                const { message } = data;


                let newArray: NewObject[] = message.map((obj: CounterListDataType) => ({
                    label: `${obj.First_Name} - ${obj.Last_Name}`,
                    value: obj.User_Type
                }));

                if (userType.toString().includes('Counter')) {
                    newArray = newArray.filter((item) => item.value === userType);
                }

                setCounterList(newArray);

            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [])


    // useEffect(() => {
    //     axios.get(`${BackendRootStrings.apiHome}/getAllLoanData`)
    //         .then(({ data }) => {
    //             const { message } = data;
    //             const newArray: NewObject[] = message.map((obj: OriginalObject) => ({
    //                 label: `${obj.Borrower} - ${obj.LoanID}`,
    //                 value: `${obj.Borrower}-${obj.LoanID}`
    //             }));
    //             setLoanIDData(newArray);
    //             setRenderKey(Math.random());
    //         })
    // }, [])

    useEffect(() => {
        setLoanIDData([]);
        LoanIDData = [];
        axios.get(`${BackendRootStrings.apiHome}/getAllLoanDataWithStatus`)
            .then(({ data }) => {
                const { message } = data;
                const approvedUsersArray = message.filter((obj: { Status: string }) => {
                    return obj.Status === "Approved";
                });
                const newArray: NewObject[] = approvedUsersArray.map((obj: OriginalObject) => ({
                    label: `${obj.Borrower} - ${obj.LoanID} - ${obj.UniqueLoanID}`,
                    value: `${obj.Borrower}- ${obj.UniqueLoanID}`
                }));
                setLoanIDData(newArray);
                setRenderKey(Math.random());
            })
    }, [])

    useEffect(() => {

        if (userType.toString().includes('Counter')) {
            setSubmitData((prevState) => {
                return {
                    ...prevState,
                    Counter: userType
                }
            })
        }

    }, [])



    return (
        <div style={{ width: "100%", height: 800, display: "flex", justifyContent: "center", alignItems: "center", }}>
            {(!formSuccess) ? <div style={{ width: "50%", height: "100%", border: "1px solid" }}>

                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "center", height: "90%", flexWrap: "wrap" }}>

                    <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-evenly" }}>
                        <div style={{ width: "80%", display: "flex", flexDirection: "column" }}>
                            <span>Payment Date</span>
                            <Space style={{ width: "100%", height: "100%" }} direction="vertical" size={12}>
                                <DatePicker
                                    format="YYYY-MM-DD HH:mm:ss"
                                    showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
                                    style={{ width: "100%", height: "192%" }}
                                    defaultValue={dayjs(new Date())}
                                    allowClear={false}
                                // onChange={handleDateChange}
                                />
                            </Space>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "80%", justifyContent: "space-between", marginTop: 25 }}>
                        <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", flexDirection: "column" }}>
                            <span>Loan ID</span>
                            <Select
                                showSearch
                                placeholder="Loan ID"
                                optionFilterProp="children"
                                onChange={(e: any, option: { label: string; value: string; } | { label: string; value: string; }[]) => {
                                    onSelectChange(e, 'LoanID');
                                    if (Array.isArray(option)) {
                                        option.forEach((item) => {
                                            setSubmitData((prevState) => {
                                                return {
                                                    ...prevState,
                                                    UniqueLoanID: item.label.split('- ')[2]
                                                }
                                            })
                                        });
                                    }
                                    else {
                                        setSubmitData((prevState) => {
                                            return {
                                                ...prevState,
                                                UniqueLoanID: option.label.split('- ')[2]
                                            }
                                        })
                                    }
                                }}
                                filterOption={filterOption}
                                style={{ width: "100%", height: "100%" }}
                                options={LoanIDData}
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "80%", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", flexDirection: "column" }}>
                            <span>Select Counter</span>
                            <Select
                                showSearch
                                placeholder="Due Payment Date"
                                optionFilterProp="children"
                                onChange={(e) => onSelectChange(e, 'Counter')}
                                filterOption={filterOption}
                                style={{ width: "100%", height: "100%" }}
                                options={counterList}
                                disabled={userType.toString().includes('Counter')}
                                value={userType.toString().includes('Counter') ? counterList[0]?.label : submitData?.Counter.toString()}
                            />

                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "80%", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", flexDirection: "column" }}>
                            <span>Due Payment Date</span>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Select
                                    showSearch
                                    placeholder="Due Payment Date"
                                    optionFilterProp="children"
                                    onChange={(e) => onSelectChange(e, 'Due_Payment_Date')}
                                    filterOption={filterOption}
                                    value={submitData.Due_Payment_Date.toString()}
                                    style={{ width: "100%", height: "100%" }}
                                    options={[
                                        // {
                                        //     value: 'Day',
                                        //     label: 'Day',
                                        // },
                                        // {
                                        //     value: 'Week',
                                        //     label: 'Week',
                                        // },
                                        {
                                            value: submitData.Due_Payment_Date.toString(),
                                            label: submitData.Due_Payment_Date.toString(),
                                        },
                                    ]}
                                />
                                <div style={{ height: 25, width: 25, border: "2px solid", marginLeft: 10, display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => {
                                    if (submitData.Due_Payment_Date) {
                                        const duePaymentDate = dayjs(submitData.Due_Payment_Date.toString());


                                        const newDuePaymentDate = duePaymentDate.add(1, 'month');
                                        if (nextAugust === undefined) {
                                            let temp1 = getNextAugust(newDuePaymentDate);
                                            setNextAugust(temp1);
                                            nextAugust = temp1;
                                        }

                                        if (newDuePaymentDate.isBefore(nextAugust)) {
                                            const formattedDate = newDuePaymentDate.format('ddd MMM DD YYYY');
                                            setTempDateIncrement(tempDateIncrement + 1);
                                            tempDateIncrement+=1;
                                           
                                            setSubmitData((prevState) => {
                                                return {
                                                    ...prevState,
                                                    Due_Payment_Date: formattedDate,
                                                    Total_Amount:(parseFloat(submitData.Principal_Amount.toString())+(parseFloat(submitData.Principal_Amount.toString())*(parseFloat(submitData.Interest)*tempDateIncrement/100))).toString()
                                                }
                                            })
                                        }


                                    }

                                }}>
                                    +1
                                </div>
                                <div style={{ height: 25, width: 25, border: "2px solid", marginLeft: 10, display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => {
                                    if (submitData.Due_Payment_Date) {
                                        const duePaymentDate = dayjs(submitData.Due_Payment_Date.toString());

                                        const newDuePaymentDate = duePaymentDate.subtract(1, 'month');

                                        const formattedDate = newDuePaymentDate.format('ddd MMM DD YYYY');
                                        if(tempDateIncrement-1>0){
                                            setTempDateIncrement(tempDateIncrement - 1);
                                            tempDateIncrement-=1;
                                            setSubmitData((prevState) => {
                                                return {
                                                    ...prevState,
                                                    Due_Payment_Date: formattedDate,
                                                    Total_Amount:(parseFloat(submitData.Principal_Amount.toString())+(parseFloat(submitData.Principal_Amount.toString())*(parseFloat(submitData.Interest)*tempDateIncrement/100))).toString()
                                                }
                                            })
                                        }
                                        
                                    }
                                }}>
                                    -1
                                </div>
                            </div>


                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "80%", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%" }}>
                            <span>Late Penalties (₹) ( It will apply if payment date is over )</span>
                            <InputNumber value={parseFloat(submitData?.Late_Penalties?.toString()) || 0} style={{ width: "100%", height: "100%" }} min={0} defaultValue={0} onChange={(e) => { handleTextChange(e, "Late_Penalties") }} />
                        </div>

                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "80%", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", flexDirection: "column", width: "80%", height: "100%" }}>
                            <span>Principal Amount (₹)</span>
                            <InputNumber value={parseInt(submitData?.Principal_Amount?.toString()) || 0} style={{ width: "80%", height: "100%" }} min={0} defaultValue={0} onChange={(e) => { handleTextChange(e, "Principal_Amount") }} />
                            <span style={{ display: (parseInt(submitData?.Principal_Amount?.toString()) > (initialPrincipalAmount) ? "block" : "none"), color: "red" }}>Principal Amount must be less than or equal to {initialPrincipalAmount}</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", width: "75%", height: "100%" }}>
                            <span>Interest (₹)</span>
                            <InputNumber value={((initialPrincipalAmount ?? 0) * parseFloat(submitData?.Interest) / 100) * tempDateIncrement || 0} style={{ width: "100%", height: "100%", fontWeight: "bold", color: "black" }} min={0} onChange={(e) => { handleTextChange(e, "Interest") }} />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-evenly" }}>
                        <div style={{ width: "80%", display: "flex", flexDirection: "column" }}>
                            <span>Total Amount (₹)</span>
                            <TextField
                                key={renderKey}
                                error={errorList.last_name === "" ? false : true}
                                id="outlined-error-helper-text"
                                disabled={true}
                                value={submitData.Total_Amount}
                                helperText={errorList.last_name}
                                variant="outlined"
                                onChange={(e) => { handleTextChange(e, "Total_Amount") }}
                                style={{ width: "100%" }}
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-evenly" }}>
                        <div style={{ width: "80%", display: "flex", flexDirection: "column" }}>
                            <span>Remarks</span>
                            <TextField
                                error={errorList.last_name === "" ? false : true}
                                id="outlined-error-helper-text"

                                helperText={errorList.last_name}
                                variant="outlined"
                                onChange={(e) => { handleTextChange(e, "description") }}
                                style={{ width: "100%" }}
                            />
                        </div>
                    </div>


                </div>
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "50%", display: "flex", justifyContent: "space-around" }}>
                        <Button type="primary" danger onClick={() => { navigate(RouteStrings.HomePage) }}>
                            Cancel
                        </Button>
                        <Button disabled={loading} type="primary" size={'middle'} onClick={handleSubmit}>
                            Submit
                        </Button>
                    </div>

                </div>
            </div> : <SuccessPage />}
        </div>
    )
}

export default EditUser