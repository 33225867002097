import { Button, DatePicker, Space, Select, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { RouteStrings, BackendRootStrings } from '../Routes';
import axios, { AxiosError } from 'axios';
import { RegexStrings } from '../../resources/Regex';
import dayjs from 'dayjs';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import { TextField } from '@material-ui/core';
import SuccessPage from '../CreateAccount/SuccessPage';
import { useSelector } from 'react-redux';

const EditUser = () => {

    const location = useLocation();

    const products: { allProducts: { totalCollectionAmount: number,cashInHand:number } } = useSelector((state: { allProducts: { totalCollectionAmount: number,cashInHand:number } }) => state);


    interface submitValueInterface {
        LoanID: String,
        Loan_Product: string,
        Borrower: string,
        First_Payment_Date: string,
        Release_Date: string,
        Applied_Amount: string,
        Late_Payments_Penalties: string,
        Description: string,
        Remarks: string,
        UniqueLoanID: string
    }

    interface NewObject {
        label: string;
        value: any;
    }
    interface OriginalObject {
        [key: string]: any;
    }

    const nameRegex = RegexStrings.nameRegex;
    const emptyRegex = RegexStrings.emptyRegex;

    let [submitData, setSubmitData] = useState<submitValueInterface>({
        LoanID: '',
        Loan_Product: '',
        Borrower: '',
        First_Payment_Date: new Date().toDateString(),
        Release_Date: new Date().toDateString(),
        Applied_Amount: '',
        Late_Payments_Penalties: '',
        Description: '',
        Remarks: '',
        UniqueLoanID: ''
    });
    const [borrowerData, setBorrowerData] = useState<NewObject[]>();
    const [loanProductName, setLoanProductName] = useState<NewObject[]>();
    const [loanProductTemp, setLoanProductTemp] = useState<{ late_payment_penalties: string, name: string }[]>();

    const [initialPrincipalAmount, setInitialPrincipalAmount] = useState(0);

    const [renderKey, setRenderKey] = useState(Math.random());

    const [cashInHand, setcashInHand] = useState(0);

    const [formSuccess, setFormSuccess] = useState(false);

    const [loading,setLoading]=useState(false);

    let navigate = useNavigate();
    const [errorList, setErrorList] = useState({ first_name: "", last_name: "" });

    // function generateAlphanumericId() {
    //     const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    //     let result = '';

    //     for (let i = 0; i < 6; i++) {
    //         const randomIndex = Math.floor(Math.random() * charset.length);
    //         result += charset[randomIndex];
    //     }

    //     return result;
    // }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const checkError = (myString: string, name: string) => {
        if (emptyRegex.test(myString)) return `${name.split('_').join(' ')} must contain atleast one letter`;
        else if (!nameRegex.test(myString)) return `${name.split('_').join(' ')} must not contain numericals or spaces`;
        return "";
    }


    // const handleChange = (e: { target: { value: string, id: string } }, name: string) => {
    //     let val = (checkError(e.target.value, name));
    //     if (!val) {
    //         setErrorList({ first_name: "", last_name: "" });
    //         setSubmitData((prevState: submitValueInterface) => {
    //             return {
    //                 ...prevState,
    //                 [name]: e.target.value,
    //             };


    //         });
    //     }
    //     else {

    //         setErrorList((prevState: { first_name: string, last_name: string } | undefined) => {
    //             if (!prevState) {
    //                 return {
    //                     first_name: "",
    //                     last_name: "",
    //                 };
    //             }
    //             return {
    //                 ...prevState,
    //                 [name]: val,
    //             }


    //         });
    //     }

    // }

    const onSelectChange = (value: string, field: string) => {

        if (field === 'Borrower') {
            setSubmitData((prevState: submitValueInterface) => {
                return {
                    ...prevState,
                    [field]: value.split('-')[0],
                    LoanID: value.split('-')[1],
                };
            })
        }
        else {
            setSubmitData((prevState: submitValueInterface) => {
                return {
                    ...prevState,
                    [field]: value
                };
            })
            const tempObj = loanProductTemp?.filter((text) => text.name === value);
            setSubmitData((prevState: submitValueInterface) => {
                return {
                    ...prevState,
                    Late_Payments_Penalties: tempObj !== undefined ? tempObj[0].late_payment_penalties : '0'
                };
            });
        }

    };

    const handleTextChange = (e: any, field: string) => {
        setSubmitData((prevState: submitValueInterface) => {
            return {
                ...prevState,
                [field]: e.target.value
            };
        })
    };

    const handleDateChange = (value: DatePickerProps['value'] | RangePickerProps['value'], field: string) => {
        setSubmitData((prevState: submitValueInterface) => {
            return {
                ...prevState,
                // [field]: value?.toString() ?? new Date().toString()
                [field]: (new Date(value?.toString()??'').toDateString())
            };
        })
        // console.log('onOk: ', (new Date(value?.toString()??'').toDateString()));
    };

    const handleSubmit = () => {
       
        if (errorList.first_name === "" && errorList.last_name === "" && parseInt(submitData.Applied_Amount) <= cashInHand && submitData.Borrower!=="" && submitData.Applied_Amount!=="" && submitData.Loan_Product!=="") {
             setLoading(true);
            if (location.state.currentItem===undefined) {
                axios.post(`${BackendRootStrings.apiHome}/createLoan`, submitData)
                    .then(() => {
                        setTimeout(() => {
                            setFormSuccess(false);
                            setLoading(false);
                            navigate(RouteStrings.Loans, {
                                state: {
                                  path: 'AllLoans',
                                }
                              });
                        }, 2000);
                        setFormSuccess(true);
                    })
                    .catch((error: AxiosError) => {
                        console.error('Error:', error.message);
                    });
            }
            else {
                console.log(submitData)
                axios.post(`${BackendRootStrings.apiHome}/updateLoan`, submitData)
                    .then(() => {
                        setTimeout(() => {
                            setFormSuccess(false);
                            setLoading(false);
                        }, 2000);
                        setFormSuccess(true);
                    })
                    .catch((error: AxiosError) => {
                        console.error('Error:', error.message);
                    });
            }
        }


    }

    const handleBorrowerdata = async () => {
        let dataFromLoan = [{ LoanID: "",Status:"" }];
        let hasLoanTakenByThisUser = [{LoanID:"",Status:""}];
        await axios.get(`${BackendRootStrings.apiHome}/getAllLoanDataWithStatus`)
            .then(({ data }) => {
                const { message } = data;
                dataFromLoan = message;
            })
        axios.get(`${BackendRootStrings.apiHome}/getAllDepositData`)
            .then(({ data }) => {
                const { message } = data;
                const newArray: NewObject[] = message.map((obj: OriginalObject) => {
                    hasLoanTakenByThisUser = dataFromLoan.filter((objChild: { LoanID: string,Status:string }) => {
                        return objChild.LoanID == obj.TNo;
                    });

                    // console.log(hasLoanTakenByThisUser,hasLoanTakenByThisUser.length , hasLoanTakenByThisUser[0]?.Status)
                
                    // if (hasLoanTakenByThisUser.length === 0 || hasLoanTakenByThisUser[0]?.Status === "Completed") {
                        return {
                            label: `${obj.NAME} - ${obj.TNo}`,
                            value: `${obj.NAME}-${obj.TNo}`,
                        };
                    // } 
                });

                const filteredArray: NewObject[] = newArray.filter(item => item !== undefined);
                
                setBorrowerData(filteredArray);
                setRenderKey(Math.random());
            })
    }

    useEffect(() => {
        if (!location.state.currentItem) {
            handleBorrowerdata();
            axios.get(`${BackendRootStrings.apiHome}/getLoanProductNameData`)
                .then(({ data }) => {
                    const { message } = data;

                    setLoanProductTemp(message);
                    const newArray: NewObject[] = message.map((obj: OriginalObject) => ({
                        label: obj.name,
                        value: obj.name
                    }));
                    setSubmitData((prevState: submitValueInterface) => {
                        return {
                            ...prevState,

                        }
                    })
                    setLoanProductName(newArray);
                    setRenderKey(Math.random());
                })
        }
        else {
            console.log("location curr", location.state.currentItem[0])
            setSubmitData(location.state.currentItem[0]);

            submitData = location.state.currentItem[0];

            console.log(location.state.currentItem[0])

            setInitialPrincipalAmount(location.state.currentItem[0].Applied_Amount);

            setRenderKey(Math.random());
        }

    }, [])


    useEffect(() => {
        setcashInHand(products.allProducts.cashInHand);
    }, [])




    return (
        <div style={{ width: "100%", height: 800, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "row" }}> <h3>{(location.state.currentItem===undefined?"Add Loan":"Edit Loan")}</h3></div>
            {(!formSuccess) ? <div style={{ width: "40%", height: "80%", border: "1px solid" }}>
                <div key={renderKey} style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "center", height: "90%", flexWrap: "wrap" }}>
                    <div style={{ display: "flex", justifyContent: "flex-start", width: "80%", flexDirection: "row" }}>
                        <p style={{ fontWeight: "bold", width: "14%" }}>Loan ID : </p> <p style={{ width: "85%", border: "1px solid", height: "61%", textAlign: "center", fontWeight: "bold", paddingTop: 5 }}>{submitData.LoanID}</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "80%", justifyContent: "space-between", height: "10%" }}>

                        <div style={{ display: "flex", justifyContent: "center", width: "43%", height: "100%", flexDirection: "column" }}>
                            <span><span style={{ color: 'red' }}>*</span> Loan Product </span>
                            <Select
                                showSearch
                                placeholder="Loan Product"
                                optionFilterProp="children"
                                onChange={(e) => onSelectChange(e, 'Loan_Product')}
                                filterOption={filterOption}
                                style={{ width: "100%", height: "100%" }}
                                options={loanProductName}
                                defaultValue={submitData.Loan_Product}
                                disabled={(location.pathname!=='/addLoan')? location?.state.currentItem[0].Status!=="Pending":false}
                            />
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", width: "43%", flexDirection: "column" }}>
                            <span><span style={{ color: 'red' }}>*</span> Borrower</span>
                            <Select
                                showSearch
                                placeholder="Borrower"
                                optionFilterProp="children"
                                onChange={(e) => onSelectChange(e, 'Borrower')}
                                filterOption={filterOption}
                                style={{ width: "100%", height: "100%" }}
                                options={borrowerData}
                                defaultValue={submitData.Borrower}
                                disabled={(location.pathname!=='/addLoan')? location?.state.currentItem[0].Status!=="Pending":false}
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "90%", justifyContent: "space-around", }}>
                        <div style={{ display: "flex", justifyContent: "center", width: "38%", flexDirection: "column" }}>
                            <span><span style={{ color: 'red' }}>*</span> Applied Date</span>
                            <Space style={{ width: "100%", height: "100%", }} direction="vertical" size={12}>
                                <DatePicker
                                    format="YYYY-MM-DD"
                                    // showTime={{ defaultValue: dayjs('00:00:00') }}
                                    style={{ width: "100%", height: "192%" }}
                                    defaultValue={dayjs(submitData.First_Payment_Date)}
                                    onChange={(value: DatePickerProps['value'] | RangePickerProps['value']) => handleDateChange(value, "First_Payment_Date")}
                                    disabled={(location.pathname!=='/addLoan')? location?.state.currentItem[0].Status!=="Pending":false}
                                    allowClear={false}
                                />
                            </Space>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", width: "38%", flexDirection: "column" }}>
                            <span><span style={{ color: 'red' }}>*</span> Approved Date</span>
                            <Space style={{ width: "100%", height: "100%" }} direction="vertical" size={12}>
                                <DatePicker
                                    format="YYYY-MM-DD"
                                    // showTime={{ defaultValue: dayjs('00:00:00') }}
                                    style={{ width: "100%", height: "192%" }}
                                    defaultValue={dayjs(submitData.First_Payment_Date)}
                                    onChange={(value: DatePickerProps['value'] | RangePickerProps['value']) => handleDateChange(value, "Release_Date")}
                                    disabled={(location.pathname!=='/addLoan')? location?.state.currentItem[0].Status!=="Pending":false}
                                    allowClear={false}
                                />
                            </Space>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-evenly", marginTop: 25 }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <span><span style={{ color: 'red' }}>*</span> Applied Amount ₹</span>
                            <TextField
                                error={errorList.first_name === "" ? false : true}
                                id="outlined-error-helper-text"
                                value={submitData.Applied_Amount}
                                helperText={errorList.first_name}
                                variant="outlined"
                                onChange={(e) => { handleTextChange(e, "Applied_Amount") }}
                                disabled={(location.pathname!=='/addLoan')? location?.state.currentItem[0].Status!=="Pending":false}
                            />
                            <span style={{ color: "red", 
                            display: (parseInt(submitData.Applied_Amount) > cashInHand) ? "block" : "none", fontSize: 10 }}>
                                Amount should be less than ₹{cashInHand} (Cash In Hand)</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <span><span style={{ color: 'red' }}>*</span> Late Payment Penalties ₹</span>
                            <TextField
                                error={errorList.last_name === "" ? false : true}
                                id="outlined-error-helper-text"
                                disabled
                                value={submitData.Late_Payments_Penalties}
                                helperText={errorList.last_name}
                                variant="outlined"
                                onChange={(e) => { handleTextChange(e, "Late_Payments_Penalties") }}
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-evenly", marginTop: 25 }}>
                        <div style={{ width: "80%", display: "flex", flexDirection: "column" }}>
                            <span>Description</span>
                            <TextField
                                error={errorList.last_name === "" ? false : true}
                                id="outlined-error-helper-text"

                                helperText={errorList.last_name}
                                variant="outlined"
                                onChange={(e) => { handleTextChange(e, "Description") }}
                                style={{ width: "100%" }}
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-evenly", marginTop: 25 }}>
                        <div style={{ width: "80%", display: "flex", flexDirection: "column" }}>
                            <span>Remarks</span>
                            <TextField
                                error={errorList.last_name === "" ? false : true}
                                id="outlined-error-helper-text"

                                helperText={errorList.last_name}
                                variant="outlined"
                                onChange={(e) => { handleTextChange(e, "Remarks") }}
                                style={{ width: "100%" }}
                            />
                        </div>
                    </div>


                </div>
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "50%", display: "flex", justifyContent: "space-around" }}>
                        <Button type="primary" danger onClick={() => { navigate(RouteStrings.HomePage) }}>
                            Cancel
                        </Button>
                        <Button disabled={loading} type="primary" size={'middle'} onClick={handleSubmit}>
                            {(location.pathname==='/addLoan')?"Submit":"Update"}
                        </Button>
                    </div>

                </div>
            </div> : <SuccessPage />}
        </div>
    )
}

export default EditUser