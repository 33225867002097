import React from 'react';
import CheckImage from '../../resources/Check.png';

const SuccessPage = () => {
    return (
        <div style={{ height: "20%", width: "70%", border: "1px solid", display: "flex", flexDirection: "row",justifyContent:"space-between" }}>
            <div style={{ height: "100%", width: "10%", backgroundColor: "#b8d901", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src={CheckImage} style={{height:"36%",width:"63%"}} alt="" />
            </div>
            <div style={{ height: "100%", width: "87%" }}>
                <h2>Success!</h2>
                <h3>The form has been submitted successfully</h3>
            </div>
        </div>
    )
}

export default SuccessPage