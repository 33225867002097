import { makeStyles } from '@material-ui/core';
import { Input } from 'antd';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { ColorCode } from '../resources/ColorCodes';
import CompanyMainLogo from '../resources/CompanyDashBoardLogo.png';


import Cookies from 'universal-cookie';
import axios from 'axios';
import { BackendRootStrings } from './Routes';


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    '@media (min-width: 780px)': {
        width: '80%'
    },

    mainbox1: {
        width: "100%",
        minHeight: 953,
        height: "fit-content",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // backgroundImage: "linear-gradient(60deg,#ff3a7c,#741eff)",
        backgroundColor:"#083e63"


    },
    box1: {
        minHeight: 555,
        height: "fit-content",
        width: "70%",
        border: "2px solid black",
        display: "flex",
        flexDirection: "row",
        backgroundColor: "white",
        borderRadius: 10,
        flexWrap: "wrap",
        justifyContent: "center"
    },

    postsEle: {
        '&:hover': {
            cursor: "pointer",
        },
    },
    childBox1: {
        width: 525,
        height: 555,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        '@media (max-width: 1500px)': {
            height:330
        },

    },
    childBox2: {
        width: 525,
        height: 555,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        '@media (max-width: 1500px)': {
            justifyContent:"flex-start"
        },


    },
    input1: {
        height: "10%",
        width: "67%",
        backgroundColor: "#d7d7d7;",
        borderRadius: 30,
        border: "2px solid black",
        "&.ant-input-affix-wrapper >input.ant-input": {
            backgroundColor: "#d7d7d7;"
        }
    },
    btn1: {
        height: "10%",
        width: "73%",
        backgroundColor: "#d7d7d7;",
        borderRadius: 30,
        border: "2px solid black",

        '&:hover': {
            cursor: "pointer",
        },

    },
    img1Res:{
        '@media (max-width: 1500px)': {
            height:"50% !important",

        },
    },




}));


interface userDetailsInterface {
    Email: String,
    Password: String
}


const LoginForm = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [userDetails, setUserDetails] = useState<userDetailsInterface>({ Email: '', Password: '' });

    const [isCredentialWrong, setIsCredentialWrong] = useState(false);

    //Initializing cookies to store login
    const cookies = new Cookies();

    const handleChange = (e: any, field: string) => {
        setUserDetails((prevState: userDetailsInterface) => {
            return {
                ...prevState,
                [field]: e.target.value
            }
        })
    }


    //store whether user is loggedin
    const handleLogin = () => {

        axios.post(`${BackendRootStrings.apiHome}/authenticateUser`, userDetails)
            .then((response) => {
                const { message } = response.data;

                const {UserType}=message;
                if (UserType !== 'Error') {
                    setIsCredentialWrong(false);
                    cookies.set("isAuthenticated", true);
                    cookies.set("defaultCounter", "");
                    cookies.set("First_Name", message.First_Name);
                    cookies.set("Last_Name", message.Last_Name);
                    cookies.set("UserType", message.UserType);
                    cookies.set("Email", message.Email);
                    //navigate to home
                    navigate('/');
                }
                else{
                    setIsCredentialWrong(true);
                }
            })
            .catch((error) => {
                console.log(error)
            });

    }

    useEffect(() => {
        let authenticated=cookies.get('isAuthenticated');
        if(authenticated){
            navigate('/');
        }
    }, [])



    return (
        <>
            <div className={classes.mainbox1} style={{}}>
                <div className={classes.box1} style={{ border: "1px solid", display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className={classes.childBox1} >
                            <img className={classes.img1Res} src={CompanyMainLogo} style={{ height: "35%", width: "70%" }} alt="Company Logo" />
                            {/* <h4>Demo logo</h4> */}
                        </div>
                        <div className={classes.childBox2}>
                            <div style={{ color: "red", display: (isCredentialWrong) ? "block" : "none" }}>Email or Password does not Match</div>
                            <input className={classes.input1} type="text" name="" onChange={(e) => handleChange(e, "Email")} placeholder='Email' style={{ marginBottom: 10, fontSize: 20, paddingLeft: 20 }} />
                            {/* <input className={classes.input1} type="text" name="" placeholder='Password' style={{ marginBottom: 45, fontSize: 20, paddingLeft: 20 }} /> */}
                            <Input.Password
                                placeholder="Password"
                                className={classes.input1}
                                onChange={(e) => handleChange(e, "Password")}
                                style={{ marginBottom: 45, fontSize: 20, paddingLeft: 20, backgroundColor: "#d7d7d7", width: "72%" }}
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                            <button className={classes.btn1} style={{ backgroundColor: ColorCode.GreenCode, fontSize: 20 }} onClick={handleLogin}>Login</button>
                        </div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <p>© 2024 HOSPITAL OFFICE FUND.</p>

                            {/* <p>Developed by <Link to='https://imaginativo.in/' style={{fontWeight:"bolder"}}>IMAGINATIVO</Link> </p> */}
                    </div>
                </div>


            </div>

        </>
    )
}

export default LoginForm