import { Button, DatePicker, DatePickerProps, Input, Select, Table, message } from 'antd'
import React, { useEffect, useState } from 'react';
import { ColorCode } from '../../resources/ColorCodes';
import { BackendRootStrings } from '../Routes';
import axios from 'axios';
import SuccessPage from '../CreateAccount/SuccessPage';
import Cookies from 'universal-cookie';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

interface InputDataInterface {
  SendingCounterName: String,
  ReceivingCounterName: String,
  MoneyToBeTransferred: String,
  Date: String
}

interface NewObject {
  label: string;
  value: any;
}

interface CounterListDataType {
  key: React.Key,
  First_Name: String,
  Last_Name: String,
  User_Type: String,
}
interface CounterListTableDataInterface {
  CounterFirstName: String,
  CounterLastName: String,
  CounterUserType: String,
  TotalAmountCollected: String,
  AmountTransferred: String,
  AmountReceived: String,
  Balance: String,
}





const CounterTransferMain = () => {

  const cookies = new Cookies();

  const userType = cookies.get('UserType');

  const [inputData, setInputData] = useState<InputDataInterface>({ SendingCounterName: "", ReceivingCounterName: "", MoneyToBeTransferred: "", Date: new Date().toDateString() });

  const [counterList, setCounterList] = useState<NewObject[]>([]);

  let [counterTableData, setCounterTableData] = useState<CounterListTableDataInterface[]>([]);

  const [senderMoneyMaxTotalAmount, setSenderMoneyMaxTotalAmount] = useState(0);

  const [loading, setLoading] = useState(false);

  const [formSuccess, setFormSuccess] = useState(false);

  const handleValueChange = (value: string, field: string) => {
    if (field === "SendingCounterName") {
      axios.get(`${BackendRootStrings.apiHome}/getCounterAmountDetails/${value}`)
        .then(({ data }) => {
          const { message } = data;
          setSenderMoneyMaxTotalAmount(parseInt(message?.TotalAmountCollected ?? 0) - parseInt(message?.AmountTransferred ?? 0) + parseInt(message?.AmountReceived ?? 0));
        })
    }
    setInputData((prevState) => {
      return {
        ...prevState,
        [field]: value
      }

    })
  }
  const handleDateChange = (value: DatePickerProps['value'] | RangePickerProps['value'], field: string) => {
    setInputData((prevState: InputDataInterface) => {
      return {
        ...prevState,
        // [field]: value?.toString() ?? new Date().toString()
        [field]: (new Date(value?.toString() ?? '').toDateString())
      };
    })
    // console.log('onOk: ', (new Date(value?.toString()??'').toDateString()));
  };

  const handleAmountSent = () => {
    console.log(inputData);

    if (inputData.SendingCounterName === "") {
      message.error("Sending counter can't be empty");
      return false;
    }
    if (inputData.ReceivingCounterName === "") {
      message.error("Receiving Counter can't be empty");
      return false;
    }
    if (inputData.MoneyToBeTransferred === "") {
      message.error("MoneyToBeTransferred can't be empty");
      return false;
    }
    if (parseInt(inputData.MoneyToBeTransferred.toString()) <= senderMoneyMaxTotalAmount) {
      setLoading(true);
      axios.post(`${BackendRootStrings.apiHome}/counterTransfer`, inputData)
        .then(() => {
          setTimeout(() => {
            setSenderMoneyMaxTotalAmount(0);
            setFormSuccess(false);
            setLoading(false);
          }, 2000);
          handleCounterTableData();
          setFormSuccess(true);
        })
    }
  }

  useEffect(() => {
    axios.get(`${BackendRootStrings.apiHome}/getAllCounterDetails`)
      .then(({ data }) => {
        const { message } = data

        const newArray: NewObject[] = message.map((obj: CounterListDataType) => ({
          label: `${obj.First_Name} - ${obj.Last_Name}`,
          value: obj.User_Type
        }));
        // console.log(newArray)
        setCounterList(newArray);

      })
  }, [])

  const handleCounterTableData = async () => {
    const CollectedAmountForEachCounterData = await axios.get(`${BackendRootStrings.apiHome}/getCollectedAmountForEachCounter`);

    axios.get(`${BackendRootStrings.apiHome}/getAllCounterAmountDetails`)
      .then(({ data }) => {
        let { message } = data;
        setCounterTableData([]);
        counterTableData = [];
        message = message.sort((val1: CounterListTableDataInterface, val2: CounterListTableDataInterface) => val1.CounterUserType.localeCompare(val2.CounterUserType.toString()));
        //   for (let i = 0; i < message.length; i++) {
        //     const currentCounter=message[i].CounterUserType;
        //     setCounterTableData((prevState) => [
        //       ...prevState,
        //       {
        //         CounterFirstName: message[i]?.CounterFirstName ?? "",
        //         CounterLastName: message[i]?.CounterLastName ?? "",
        //         CounterUserType: message[i]?.CounterUserType ?? "",
        //         TotalAmountCollected: (currentCounter!==="Counter-6")?CollectedAmountForEachCounterData.data[currentCounter].AmountCollected: message[i]?.TotalAmountCollected ?? "",
        //         AmountTransferred: message[i]?.AmountTransferred ?? "",
        //         AmountReceived: message[i]?.AmountReceived ?? "",
        //         Balance: (
        //           parseFloat(message[i]?.TotalAmountCollected ?? 0) +
        //           parseFloat(message[i]?.AmountReceived ?? 0) -
        //           parseFloat(message[i]?.AmountTransferred ?? 0)
        //         ).toString()
        //       }
        //     ]);

        //   }

        // })
        console.log(CollectedAmountForEachCounterData.data)
        const updatedCounterTableData: CounterListTableDataInterface[] = message.map((item: CounterListTableDataInterface) => {
          const currentCounter = item.CounterUserType;
          let FinalBalance=0;
          // if(currentCounter!=="Counter-6"){
            FinalBalance+=CollectedAmountForEachCounterData.data[currentCounter.toString()].AmountCollected+
            CollectedAmountForEachCounterData.data[currentCounter.toString()].totalCounterReceived-
            CollectedAmountForEachCounterData.data[currentCounter.toString()].totalCounterTransferred
          // }
          // else{
          //   FinalBalance+=parseFloat(item.TotalAmountCollected.toString() ?? "0") +
          //   parseFloat(item.AmountReceived.toString() ?? "0") -
          //   parseFloat(item.AmountTransferred.toString() ?? "0")
          // }
          return {
            CounterFirstName: item.CounterFirstName ?? "",
            CounterLastName: item.CounterLastName ?? "",
            CounterUserType: item.CounterUserType ?? "",
            TotalAmountCollected: CollectedAmountForEachCounterData.data[currentCounter.toString()].AmountCollected ,
            AmountTransferred: CollectedAmountForEachCounterData.data[currentCounter.toString()].totalCounterTransferred ,
            AmountReceived:CollectedAmountForEachCounterData.data[currentCounter.toString()].totalCounterReceived,
            Balance: (FinalBalance).toString()
          };
        });

        setCounterTableData(updatedCounterTableData);
      })
  }

  useEffect(() => {
    handleCounterTableData();
  }, [])




  const columns = [
    {
      title: 'First Name',
      dataIndex: 'CounterFirstName',
      key: 'CounterFirstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'CounterLastName',
      key: 'CounterLastName',
    },
    {
      title: 'User Type',
      dataIndex: 'CounterUserType',
      key: 'CounterUserType',
    },
    {
      title: 'Amount Collected',
      dataIndex: 'TotalAmountCollected',
      key: 'TotalAmountCollected',
    },
    {
      title: 'Amount Transferred',
      dataIndex: 'AmountTransferred',
      key: 'AmountTransferred',
    },
    {
      title: 'Amount Received',
      dataIndex: 'AmountReceived',
      key: 'AmountReceived',
    },
    {
      title: 'Balance',
      dataIndex: 'Balance',
      key: 'Balance',
    },
  ];


  return (
    <>
      <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center" }}>
        {(!formSuccess) ? <div style={{ width: "100%", height: "50%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          {(!userType.toString().includes('Counter')) ? <div style={{ width: "100%", height: "50%", border: "1px solid", display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", justifyContent: "space-evenly", }}>
              <div style={{ width: "20%", }}><span>From Counter Name</span><Select
                style={{ width: "100%", height: "calc(1.8em + 1.6rem + 2px)" }}
                onChange={(value) => handleValueChange(value, "SendingCounterName")}
                options={counterList}
              /></div>
              <div style={{ width: "20%", }}><span>To Counter Name</span><Select
                style={{ width: "100%", height: "calc(1.8em + 1.6rem + 2px)" }}
                onChange={(value) => handleValueChange(value, "ReceivingCounterName")}
                options={counterList}
              /></div>
              <div style={{ width: "20%", }}><span>Amount to be sent</span><Input min={0} max={100} style={{ height: "calc(1.8em + 1.6rem + 2px)" }} onChange={(e) => handleValueChange(e.target.value, "MoneyToBeTransferred")} /><span style={{ color: "red", display: (senderMoneyMaxTotalAmount < parseInt(inputData.MoneyToBeTransferred.toString()) ? "block" : "none") }}>Value must be less than or equal to {senderMoneyMaxTotalAmount}</span> </div>

              <div style={{ width: "20%", }}><span>Select Date</span> <DatePicker allowClear={false} value={dayjs(inputData.Date.toString())} style={{ height: "calc(1.8em + 1.6rem + 2px)", width: "100%" }}
                onChange={(value: DatePickerProps['value'] | RangePickerProps['value']) => handleDateChange(value, "Date")}
              /></div>
            </div>

            <div style={{ width: "100%", marginTop: 20, display: "flex", justifyContent: "center" }}><Button disabled={loading} onClick={handleAmountSent} style={{ width: "20%", height: "calc(1.8em + 1.6rem + 2px)", backgroundColor: ColorCode.GreenCode, fontWeight: "bold", color: "white" }} >Compare and Sent</Button></div>

          </div> : <div></div>}
          <div>
            <Table dataSource={counterTableData} columns={columns} />
          </div>

        </div> : <SuccessPage />}

      </div>
    </>
  )
}

export default CounterTransferMain