import { ActionTypes } from "../constants/ActionTypes";


const initialState = {
    //Initial list
    totalCollectionsArray: [

    ],
    isCounterSelected: false,
    noOfMembers: 0,
    menuItemToken: 0,
    totalCollectionAmount: 0,
    cashInHand: 0,
    depositLateFees: 0,
    currentCounterNo: 0,
    counterBalance: 0,
    counterPermission: [],

}

export const productReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case (ActionTypes.SET_TOTAL_COLLECTION):
            return {
                ...state,
                totalCollectionsArray: payload,
                isCounterSelected: false,
                noOfMembers: payload.length
            };
        case (ActionTypes.SET_COUNTER_COLLECTION):
            return {
                ...state,
                totalCollectionsArray: payload,
                isCounterSelected: true
            };
        case (ActionTypes.SET_COUNTER_COLLECTION):
            return {
                ...state,
                totalCollectionsArray: payload,
                isCounterSelected: true
            };
        case (ActionTypes.SET_TOTAL_COLLECTION_AMOUNT):
            return {
                ...state,
                totalCollectionAmount: payload,
            };
        case (ActionTypes.CASH_IN_HAND):
            return {
                ...state,
                cashInHand: payload,
            };
        case (ActionTypes.SET_DEPOSIT_LATE_FEES):
            return {
                ...state,
                depositLateFees: payload,
            };
        case (ActionTypes.SET_CURRENT_COUNTER_NO):
            return {
                ...state,
                currentCounterNo: payload,
            };
        case (ActionTypes.SET_COUNTER_BALANCE):
            return {
                ...state,
                counterBalance: payload,
            };
        case (ActionTypes.SET_COUNTER_PERMISSION):
            return {
                ...state,
                counterPermission: payload
            };

        default:
            return state;
    }
}