import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, message } from 'antd';
import axios from 'axios';
import { BackendRootStrings } from '../Routes';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

const ProfileSettings = () => {

    const navigate = useNavigate();
    const cookies = new Cookies();

    interface settingsOptionInterface {
        First_Name: string,
        Last_Name: string,
        Email: string,
    }


    let settingsOptions = { 'First Name': 'First_Name', 'Last Name': 'Last_Name', 'Email': 'Email' };

    const [loading, setLoading] = useState(false);

    const [profileDetails, setProfileDetails] = useState({
        First_Name: cookies.get("First_Name") as string,
        Last_Name: cookies.get("Last_Name") as string,
        Email: cookies.get("Email") as string,
        PreviousMail: ''
    })

    const [changeSelected, setChangeSelected] = useState(false);

    const handleSubmitData = async () => {
        const result = window.confirm("Are you Sure?");
        if (result) {
            setLoading(true);
            let newData = { ...profileDetails };
            newData['PreviousMail'] = cookies.get("Email");
            const { data } = await axios.put(`${BackendRootStrings.apiHome}/updateProfileDetails`, newData);

            message.info(data);


            setLoading(false);
            if (data === "Changed Successfully") {
                cookies.set("First_Name", profileDetails.First_Name);
                cookies.set("Last_Name", profileDetails.Last_Name);
                cookies.set("Email", profileDetails.Email);
                navigate(0);
            }

        }

    }

    return (
        <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center" }}>
            <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                {
                    Object.entries(settingsOptions).map(([key, value], ind) => {
                        let newKey = value as keyof settingsOptionInterface;
                        return (
                            <div style={{ display: "flex", width: "100%", flexDirection: "column", marginBottom: 5 }} key={ind}>
                                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>{key}</span> : <Input onChange={(e) => {
                                        setProfileDetails((prevState) => {
                                            return {
                                                ...prevState,
                                                [value]: e.target.value
                                            }
                                        })
                                    }} style={{ width: "80%", border: "1px solid" }} disabled={!changeSelected} value={profileDetails[newKey]} />
                                </div>

                                <div style={{ width: "100%", border: "1px solid" }}></div>
                            </div>
                        )
                    })
                }
                {
                    <div style={{ display: "flex", justifyContent: changeSelected ? "space-between" : "center", width: "40%", marginTop: 10 }}>
                        <Button disabled={loading} onClick={() => { changeSelected ? handleSubmitData() : setChangeSelected(true) }} style={{ backgroundColor: changeSelected ? "green" : "skyblue", width: "40%", color: 'white', fontWeight: 'bold' }}>
                            {changeSelected ? "SUBMIT" : "EDIT"}
                        </Button>
                        {changeSelected && (
                            <Button onClick={() => { setChangeSelected(false) }} style={{ backgroundColor: 'red', width: "40%", color: 'white', fontWeight: 'bold' }}>
                                CANCEL
                            </Button>
                        )}
                    </div>

                }

            </div>
        </div >
    )
}

export default ProfileSettings