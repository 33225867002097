import { Button, DatePicker, DatePickerProps, Descriptions, Input, Select, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import { ColorCode } from '../../resources/ColorCodes';
import generatePDF, { Margin } from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';
import type { DescriptionsProps } from 'antd';
import axios from 'axios';
import { BackendRootStrings } from '../Routes';

interface InputDataInterface {
    startDate: String,
    endDate: String,
    counter: String
}

interface viewValueInterface {
    totalDeposit: number
    totalLateFees: number
    totalLoanRepayment: number,
    totalLoanLateFine:number,
    totalCounterTransferred:number,
    totalCounterReceived:number,
    totalLoanAmountRepayed:number
}

const CounterTransactionReport = () => {

    const printRef = useRef<any>();
    let [isPdfTriggered, setIsPdfTriggered] = useState(false);
    const [isPrintTriggered, setIsPrintTriggered] = useState(false);
    const [renderKey, setRenderKey] = useState(Math.random());

    const [inputData, setInputData] = useState<InputDataInterface>({ startDate: new Date().toDateString(), endDate: new Date().toDateString(), counter: "" });
    let [viewValues, setViewValues] = useState<viewValueInterface>({ totalDeposit: 0, totalLateFees: 0, totalLoanRepayment: 0,totalLoanLateFine:0,totalCounterTransferred:0,totalCounterReceived:0,totalLoanAmountRepayed:0});

    const [loading, setLoading] = useState(false);

    let [finalBalance, setFinalBalance] = useState(0);


    const handleDateChange = (value: DatePickerProps['value'] | RangePickerProps['value'], field: string) => {
        setInputData((prevState: InputDataInterface) => {
            return {
                ...prevState,
                // [field]: value?.toString() ?? new Date().toString()
                [field]: (new Date(value?.toString() ?? '').toDateString())
            };
        })
        // console.log('onOk: ', (new Date(value?.toString()??'').toDateString()));
    };

    const handleSubmit = async () => {
        if(inputData.counter==="" || !inputData.counter){
            message.error("Counter cannot be empty");
            return false;
        }
        setLoading(true);
        const { data } = await axios.post(`${BackendRootStrings.apiHome}/getCounterTransactionReport`, inputData);
        setViewValues(data)
        setFinalBalance(0);
        finalBalance=0;
        
            setFinalBalance((finalBalance+
                data.totalCounterReceived+
                data.totalDeposit+
                data.totalLateFees+
                data.totalLoanLateFine+
                data.totalLoanRepayment-
                data.totalCounterTransferred+
                data.totalLoanAmountRepayed));
            finalBalance+=data.totalCounterReceived+
            data.totalDeposit+
            data.totalLateFees+
            data.totalLoanLateFine+
            data.totalLoanRepayment-
            data.totalCounterTransferred+
            data.totalLoanAmountRepayed;
        
        setLoading(false);
    }

    const options = {
        // default is `save`
        method: "save" as const,
        filename: `Counter Transaction Report - ${inputData.counter}.pdf`,
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        // resolution: Resolution.HIGH,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            format: 'A4',
            // default is 'portrait'
            orientation: "portrait" as const,
        },
        // canvas: {
        //    // default is 'image/jpeg' for better size performance
        //    mimeType: 'image/png',
        //    qualityRatio: 1
        // },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break, 
        // so use with caution.
        // overrides: {
        //    pdf: {
        //       compress: true
        //    },
        //    canvas: {
        //       useCORS: true
        //    }
        // },
    };

    const handlePDFPrint = () => {
        generatePDF(printRef, options);
    };


    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    useEffect(() => {
        setRenderKey(Math.random());
        if (isPdfTriggered === true) {
            handlePDFPrint();
            setIsPdfTriggered(false);
        }
    }, [isPdfTriggered])

    useEffect(() => {
        setRenderKey(Math.random());
        if (isPrintTriggered === true) {
            handlePrint();
            setIsPrintTriggered(false);
        }
    }, [isPrintTriggered])


    const headers = [
        {
            label: "Total Deposit", key: "totalDeposit"
        },
        {
            label: "Total Registration Fees", key: "totalLateFees"
        },
        {
            label: "Total Loan Interest", key: "totalLoanRepayment"
        },
        {
            label: "Total Loan LateFine", key: "totalLoanLateFine"
        },
        {
            label: "Total Amount Transferred", key: "totalCounterTransferred"
        },
        {
            label: "Total Amount Received", key: "totalCounterReceived"
        },

    ]


    const csvLink = {
        filename: `Counter Transaction Report - ${inputData.counter}.csv`,
        headers: headers,
        data: [viewValues],
    }

    const items: DescriptionsProps['items'] = [
        {
            key: '1',
            label: 'Total Deposit',
            children: '₹ ' + viewValues?.totalDeposit ?? '',
        },
        
        {
            key: '2',
            label: 'Total Loan Interest',
            children: '₹ ' + viewValues?.totalLoanRepayment ?? '',
        },
        {
            key: '3',
            label: 'Total Registration Fees',
            children: '₹ ' + viewValues?.totalLateFees ?? '',
        },
        {
            key: '4',
            label: 'Total Loan Late Fine',
            children: '₹ ' + (viewValues?.totalLoanLateFine ?? ''),
            span: 2,
        },
        {
            key: '5',
            label: 'Total Principal Amount Repayed',
            children: '₹ ' + (viewValues?.totalLoanAmountRepayed ?? ''),
            span: 2,
        },
        {
            key: '6',
            label: 'Total Amount Received from other counters',
            children: '₹ ' + (viewValues?.totalCounterReceived ?? ''),
            span: 2,
        },
        {
            key: '7',
            label: 'Total Amount Transferred to other counters',
            children: '₹ ' + (viewValues?.totalCounterTransferred ?? ''),
            span: 2,
        },
        
        // {
        //     key: '6',
        //     label: 'Balance',
        //     children: '₹ ' + parseFloat(viewValues?.total ?? ''),
        //     span: 2,
        // },


    ];

    return (
        <>
            <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "100%", height: "50%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div style={{ width: "100%", height: "50%", border: "1px solid", display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", justifyContent: "space-evenly", }}>
                            <div style={{ width: "20%", }}><span>Start Date</span> <DatePicker allowClear={false} value={dayjs(inputData.startDate.toString())} style={{ height: "calc(1.8em + 1.6rem + 2px)", width: "100%" }}
                                onChange={(value: DatePickerProps['value'] | RangePickerProps['value']) => handleDateChange(value, "startDate")}
                            /></div>
                            <div style={{ width: "20%", }}><span>End Date</span> <DatePicker allowClear={false} value={dayjs(inputData.endDate.toString())} style={{ height: "calc(1.8em + 1.6rem + 2px)", width: "100%" }}
                                onChange={(value: DatePickerProps['value'] | RangePickerProps['value']) => handleDateChange(value, "endDate")}
                            /></div>
                            <div style={{ width: "20%", }}><span>Counter</span><Select
                                showSearch
                                placeholder="Select Counter"
                                optionFilterProp="children"
                                onChange={(e) => { setInputData((prevState: InputDataInterface) => { return { ...prevState, counter: e } }); setViewValues({
                                    totalDeposit:0,
                                    totalLateFees:0,
                                    totalLoanLateFine:0,
                                    totalLoanRepayment:0,
                                    totalCounterTransferred:0,
                                    totalCounterReceived:0,
                                    totalLoanAmountRepayed:0
                                })}}

                                style={{ width: "100%", height: "75%" }}
                                options={[
                                    { value: 'Counter-1', label: 'Counter-1' },
                                    { value: 'Counter-2', label: 'Counter-2' },
                                    { value: 'Counter-3', label: 'Counter-3' },
                                    { value: 'Counter-4', label: 'Counter-4' },
                                    { value: 'Counter-5', label: 'Counter-5' },
                                ]}
                                defaultValue={inputData.counter}
                            /> </div>
                        </div>

                        <div style={{ width: "100%", marginTop: 20, display: "flex", justifyContent: "center" }}><Button disabled={loading} onClick={handleSubmit} style={{ width: "20%", height: "calc(1.8em + 1.6rem + 2px)", backgroundColor: ColorCode.GreenCode, fontWeight: "bold", color: "white" }} >Filter</Button></div>

                    </div>
                    <div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                            <div style={{ width: "9%", display: "flex", justifyContent: "space-between", marginTop: 20, alignItems: "center" }}>
                                <CSVLink {...csvLink} style={{ display: "flex", textDecoration: "none", color: "black", backgroundColor: "white", height: 32, borderRadius: 5, justifyContent: "center", alignItems: "center" }}><Button style={{ backgroundColor: "gray", color: "white" }}>Excel</Button></CSVLink>
                                <Button style={{ backgroundColor: "red", color: "white" }} onClick={() => { setRenderKey(Math.random()); setIsPdfTriggered(true) }}>PDF</Button>
                                <Button style={{ backgroundColor: "green", color: "white" }} onClick={() => { setRenderKey(Math.random()); setIsPrintTriggered(true) }}>Print</Button>
                            </div>
                        </div>
                        <div key={renderKey} ref={printRef}>
                            <div style={{ display: "flex", width: "100%" }}>
                                <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                    <h3>HOSPITAL OFFICE FUND</h3>
                                    <div style={{ width: 186, textAlign: "center" }}><h3>Counter Transaction Report - {inputData.counter}</h3></div>
                                    <div style={{ width: 186, textAlign: "center" }}><h3>Final Balance - {finalBalance}</h3></div>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                        <Descriptions style={{width:"100%"}} key={renderKey} title="" bordered items={items} column={1} />
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}

export default CounterTransactionReport