import React, { useEffect, useState } from 'react';
import { Badge, Descriptions } from 'antd';
import type { DescriptionsProps } from 'antd';
import { useLocation } from 'react-router-dom';



const App: React.FC = () => {

  const location = useLocation();

  interface viewValueInterface {
    LoanID: string,
    Borrower: string,
    Interest: string,
    Late_Penalty: string,
    Principal_Amount: string,
    Repayment_Date: string,

  }
  let [viewValues, setViewValues] = useState<viewValueInterface>();
  const [renderKey, setRenderKey] = useState(Math.random());



  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'LoanID',
      children: viewValues?.LoanID??'',
    },
    {
      key: '2',
      label: 'Borrower',
      children: viewValues?.Borrower??'',
    },
    {
      key: '3',
      label: 'Interest',
      children: (viewValues?.Interest??'')+'%',
    },
    {
      key: '4',
      label: 'Late Penalty',
      children: '₹'+parseFloat(viewValues?.Late_Penalty??'').toLocaleString('en-IN') ??'',
    },
    {
      key: '5',
      label: 'Principal_Amount',
      children: '₹'+parseFloat(viewValues?.Principal_Amount??'').toLocaleString('en-IN') ??'',
      span: 2,
    },
    {
        key: '5',
        label: 'Repayment_Date',
        children:viewValues?.Repayment_Date??'',
        span: 2,
      },

  ];


  useEffect(() => {
    
    setViewValues(location.state.currentItem);
    viewValues = location.state.currentItem;
    setRenderKey(Math.random());

  }, [])


  return (
    <Descriptions key={renderKey} title="View Loan Repayment Details" bordered items={items} column={1} />
  )
}

export default App;