import React, { useEffect, useRef, useState } from 'react';
import { Button, Table, Input } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { TableRowSelection } from 'antd/es/table/interface';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { ColorCode } from '../../resources/ColorCodes';
import { CSVLink } from 'react-csv';
import generatePDF, { Margin } from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    firstRow: {
        //     backgroundColor:`${ColorCode.GreenCode}`,
        //     color:"white",
        //     fontWeight:"bold",
        //     '&:hover': {
        //        visibility:"visible",
        //     },
    },
    rowHead: {
        "&.ant-table-wrapper .ant-table-thead >tr>th": {
            backgroundColor: `${ColorCode.GreenCode} !important`
        }
    }



}));



interface CounterDataType {
    key: React.Key;
    "SlNo": number,
    "NAME": string,
    "TNo": string,
    "September": number,
    "October": number,
    "November": number,
    "December": number,
    "January": number,
    "February": number,
    "March": number,
    "April": number,
    "May": number,
    "June": number,
    "July": number,
    "August": number,
}


const counterColumns: ColumnsType<CounterDataType> = [
    {
        title: 'SlNo',
        dataIndex: 'SlNo',
    },
    {
        title: 'Name',
        dataIndex: 'NAME',
    },
    {
        title: 'Account No',
        dataIndex: 'TNo',
    },
    {
        title: 'September',
        dataIndex: 'September',
    },
    {
        title: 'October',
        dataIndex: 'October',
    },
    {
        title: 'November',
        dataIndex: 'November',
    },
    {
        title: 'December',
        dataIndex: 'December',
    },
    {
        title: 'January',
        dataIndex: 'January',
    },
    {
        title: 'February',
        dataIndex: 'February',
    },
    {
        title: 'March',
        dataIndex: 'March',
    },
    {
        title: 'April',
        dataIndex: 'April',
    },
    {
        title: 'May',
        dataIndex: 'May',
    },
    {
        title: 'June',
        dataIndex: 'June',
    },
    {
        title: 'July',
        dataIndex: 'July',
    },
    {
        title: 'August',
        dataIndex: 'August',
    },
];

let data: CounterDataType[] = [];





const App: React.FC = () => {

    //Redux part
    const products: { allProducts: { totalCollectionsArray: CounterDataType[], isCounterSelected: Boolean, currentCounterNo: number,counterBalance:number } } = useSelector((state: { allProducts: { totalCollectionsArray: CounterDataType[], isCounterSelected: Boolean, currentCounterNo: number,counterBalance:number } }) => state);

    const classes = useStyles();

    const printRef = useRef<any>();

    let [isPdfTriggered, setIsPdfTriggered] = useState(false);
    const [isPrintTriggered, setIsPrintTriggered] = useState(false);
    const [renderKey, setRenderKey] = useState(Math.random());
    const [searchText, setSearchText] = useState("");

    const [tempSearchDataArray, setTempSearchDataArray] = useState<CounterDataType[]>([]);



    const [renderTableKey, setRenderTableKey] = useState(Math.random());

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const com = (a: CounterDataType, b: CounterDataType) => {
        return a.SlNo - b.SlNo;
    }

    useEffect(() => {
        let temp = products.allProducts.totalCollectionsArray;
        temp.sort(com);
        // console.log(temp, "here");
        data = [];


        temp.map((obj, ind, arr) => {
            // const monthsToSum = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

            data.push({
                key: ind,
                "SlNo": obj['SlNo'],
                "NAME": obj['NAME'],
                "TNo": obj['TNo'],
                "September": obj['September'] !== null ? obj['September'] : 0,
                "October": obj['October'] !== null ? obj['October'] : 0,
                "November": obj['November'] !== null ? obj['November'] : 0,
                "December": obj['December'] !== null ? obj['December'] : 0,
                "January": obj['January'] !== null ? obj['January'] : 0,
                "February": obj['February'] !== null ? obj['February'] : 0,
                "March": obj['March'] !== null ? obj['March'] : 0,
                "April": obj['April'] !== null ? obj['April'] : 0,
                "May": obj['May'] !== null ? obj['May'] : 0,
                "June": obj['June'] !== null ? obj['June'] : 0,
                "July": obj['July'] !== null ? obj['July'] : 0,
                "August": obj['August'] !== null ? obj['August'] : 0,
            });

        })
        setTempSearchDataArray(data);
        setRenderTableKey(Math.random());
        // console.log("data after push",data)


    }, [products.allProducts.totalCollectionsArray])

    const getRowClassName = (record: any, index: any) => {
        if (index === 0) {
            return `${classes.firstRow}`;
        }
        return '';
    };


    const rowSelection: TableRowSelection<CounterDataType> = {
        selectedRowKeys,
        onChange: onSelectChange,

        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
            {
                key: 'odd',
                text: 'Select Odd Row',
                onSelect: (changeableRowKeys) => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
                        if (index % 2 !== 0) {
                            return false;
                        }
                        return true;
                    });
                    setSelectedRowKeys(newSelectedRowKeys);
                },
            },
            {
                key: 'even',
                text: 'Select Even Row',
                onSelect: (changeableRowKeys) => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
                        if (index % 2 !== 0) {
                            return true;
                        }
                        return false;
                    });
                    setSelectedRowKeys(newSelectedRowKeys);
                },
            },
        ],
    };

    const headers = [
        {
            label: 'SlNo',
            key: 'SlNo',
        },
        {
            label: 'Name',
            key: 'NAME',
        },
        {
            label: 'Account No',
            key: 'TNo',
        },
        {
            label: 'September',
            key: 'September',
        },
        {
            label: 'October',
            key: 'October',
        },
        {
            label: 'November',
            key: 'November',
        },
        {
            label: 'December',
            key: 'December',
        },
        {
            label: 'January',
            key: 'January',
        },
        {
            label: 'February',
            key: 'February',
        },
        {
            label: 'March',
            key: 'March',
        },
        {
            label: 'April',
            key: 'April',
        },
        {
            label: 'May',
            key: 'May',
        },
        {
            label: 'June',
            key: 'June',
        },
        {
            label: 'July',
            key: 'July',
        },
        {
            label: 'August',
            key: 'August',
        },

    ]

    const csvLink = {
        filename: `Counter List Counter-${products.allProducts.currentCounterNo}.csv`,
        headers: headers,
        data: tempSearchDataArray,
    }


    const options = {
        // default is `save`
        method: "save" as const,
        filename: `Counter List Counter-${products.allProducts.currentCounterNo}.pdf`,
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        // resolution: Resolution.HIGH,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            format: 'A4',
            // default is 'portrait'
            orientation: "landscape" as const,
        },
        // canvas: {
        //    // default is 'image/jpeg' for better size performance
        //    mimeType: 'image/png',
        //    qualityRatio: 1
        // },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break, 
        // so use with caution.
        // overrides: {
        //    pdf: {
        //       compress: true
        //    },
        //    canvas: {
        //       useCORS: true
        //    }
        // },
    };

    const handlePDFPrint = () => {
        generatePDF(printRef, options);
    };


    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    useEffect(() => {
        setRenderKey(Math.random());
        if (isPdfTriggered === true) {
            handlePDFPrint();
            setIsPdfTriggered(false);
        }
    }, [isPdfTriggered])

    useEffect(() => {
        setRenderKey(Math.random());
        if (isPrintTriggered === true) {
            handlePrint();
            setIsPrintTriggered(false);
        }
    }, [isPrintTriggered])

    useEffect(() => {
        if (searchText === null || searchText === undefined || searchText === "") {
            // setTempSearchDataArray([]);
            setTempSearchDataArray(data)
        }
        else {

            setTempSearchDataArray((prevstate) => {

                let var1 = data.filter(
                    (info) => {
                        return (info.NAME?.toLowerCase().includes(searchText.toLowerCase()) || info.TNo?.toString().includes(searchText))
                    }

                );
                return var1;
            })


        }
    }, [searchText])

    return (
        <div>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                <div style={{ width: "9%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <CSVLink {...csvLink} style={{ display: "flex", textDecoration: "none", color: "black", backgroundColor: "white", height: 32, borderRadius: 5, justifyContent: "center", alignItems: "center" }}><Button style={{backgroundColor:"gray",color:"white"}}>Excel</Button></CSVLink>
                    <Button style={{backgroundColor:"red",color:"white"}} onClick={() => { setRenderKey(Math.random()); setIsPdfTriggered(true) }}>PDF</Button>
                    <Button style={{backgroundColor:"green",color:"white"}} onClick={() => { setRenderKey(Math.random()); setIsPrintTriggered(true) }}>Print</Button>
                </div>
                <div style={{ width: "15%", height: "15%", display: "flex", flexDirection: "row", border: `2px solid ${ColorCode.GreenCode}`, borderRadius: 10, alignItems: "center" }}>
                    {/* <span >SELECT MONTH</span> */}
                    <Input
                        style={{ width: "100%", height: "100%" }}
                        onChange={(e) => { setSearchText(e.target.value) }}
                        placeholder='Enter Name or TNo'
                    />
                </div>
            </div>
            <div key={renderKey} ref={printRef}>
                <div style={{display:"flex",flexDirection:"column"}}>
                    <h3 style={{ textAlign: "center" }}>Counter - {products.allProducts.currentCounterNo}</h3>
                    <h3 style={{ textAlign: "center" }}>Balance - {products.allProducts.counterBalance}</h3>
                </div>
                
                <Table key={renderTableKey} className={(isPrintTriggered || isPdfTriggered )?"":classes.rowHead} rowSelection={(isPrintTriggered || isPdfTriggered)?undefined:rowSelection} columns={counterColumns} dataSource={tempSearchDataArray} rowClassName={getRowClassName} pagination={isPrintTriggered || isPdfTriggered ? false : { position: ['topLeft'] }} />

            </div>

        </div>
    )

};

export default App;