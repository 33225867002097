import { Button, Checkbox, DatePicker, DatePickerProps, Input, message, Select, Table } from 'antd'
import React, { useEffect, useState, useRef } from 'react';
import { ColorCode } from '../../resources/ColorCodes';
import { BackendRootStrings } from '../Routes';
import axios from 'axios';
import SuccessPage from '../CreateAccount/SuccessPage';
import Cookies from 'universal-cookie';
import dayjs from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';
import { CSVLink } from 'react-csv';
import generatePDF, { Margin } from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';

interface InputDataInterface {
    startDate: String,
    endDate: String,
    loanType: String,
    status: String,
    accountNo: String,
    checked: Boolean
}

interface NewObject {
    label: string;
    value: any;
}

interface StatementTableDataInterface {
    SlNo: String,
    LoanID: String,
    Loan_Product: String,
    Borrower: String,
    First_Payment_Date: String,
    Release_date: String,
    Applied_Amount: String,
    Late_Payments_Penalties: String,
    Description: String,
    Remarks: String,
    Status: String,
    UniqueLoanID: String,
}

interface OriginalObject {
    [key: string]: any;
}





const LoanReport = () => {

    const cookies = new Cookies();

    const userType = cookies.get('UserType');

    const printRef = useRef<any>();
    let [isPdfTriggered, setIsPdfTriggered] = useState(false);
    const [isPrintTriggered, setIsPrintTriggered] = useState(false);

    const [renderKey, setRenderKey] = useState(Math.random());

    let [loanProductName, setLoanProductName] = useState<NewObject[]>();

    const [inputData, setInputData] = useState<InputDataInterface>({ startDate: new Date().toDateString(), endDate: new Date().toDateString(), accountNo: "", loanType: "", status: "",checked:false });


    let [statementTableData, setStatementTableData] = useState<StatementTableDataInterface[]>([]);

    const [formSuccess, setFormSuccess] = useState(false);

    let [totalAmount, seTotalAmount] = useState({Applied_Amount:0});

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const handleDateChange = (value: DatePickerProps['value'] | RangePickerProps['value'], field: string) => {
        setInputData((prevState: InputDataInterface) => {
            return {
                ...prevState,
                // [field]: value?.toString() ?? new Date().toString()
                [field]: (new Date(value?.toString() ?? '').toDateString())
            };
        })
        // console.log('onOk: ', (new Date(value?.toString()??'').toDateString()));
    };

    const handleLoanProductOption = () => {
        axios.get(`${BackendRootStrings.apiHome}/getLoanProductNameData`)
            .then(({ data }) => {
                const { message } = data;
                setLoanProductName([]);
                loanProductName = [];
                setLoanProductName(prevState => [
                    ...(prevState || []),
                    {
                        label: "All",
                        value: "All"
                    }
                ]);
                const newArray: NewObject[] = message.map((obj: OriginalObject) => ({
                    label: obj.name,
                    value: obj.name
                }));
                setLoanProductName(prevState => [
                    ...(prevState || []),
                    ...newArray
                ]);




                setRenderKey(Math.random());
            })
    }

    useEffect(() => {
        handleLoanProductOption();
    }, [])


    const handleSubmit = () => {
        if (inputData.accountNo === "" && inputData.checked===false) {
            message.error("Account No can't be empty");
        }
        else if (inputData.loanType === "") {
            message.error("Loan Type can't be empty");
        }
        else if (inputData.status === "") {
            message.error("Status can't be empty");
        }
        else {
            axios.post(`${BackendRootStrings.apiHome}/getLoanStatementById`, inputData)
                .then(({ data }) => {
                    const { message } = data;
                    setStatementTableData([]);
                    statementTableData = [];
                    seTotalAmount({Applied_Amount:0});
                    totalAmount.Applied_Amount=0;
                    message.map((val: StatementTableDataInterface, ind: number) => {
                        setStatementTableData(prevState => [
                            ...prevState,
                            {
                                SlNo: (ind + 1).toString(),
                                LoanID: val.LoanID,
                                Loan_Product: val.Loan_Product,
                                Borrower: val.Borrower,
                                First_Payment_Date: val.First_Payment_Date,
                                Release_date: val.Release_date,
                                Applied_Amount: val.Applied_Amount,
                                Late_Payments_Penalties: val.Late_Payments_Penalties,
                                Description: val.Description,
                                Remarks: val.Remarks,
                                Status: val.Status,
                                UniqueLoanID: val.UniqueLoanID,
                            }
                        ]);
                        seTotalAmount((prevState)=>{
                            return{
                                ...prevState,
                                Applied_Amount:prevState.Applied_Amount+parseFloat(val.Applied_Amount.toString())
                            }
                        })
                    })
                })
        }
    }

    const columns = [
        {
            title: 'SlNo',
            dataIndex: 'SlNo',
            key: 'SlNo',
        },
        {
            title: 'Member ID',
            dataIndex: 'LoanID',
            key: 'LoanID',
        },
        {
            title: 'Loan Product',
            dataIndex: 'Loan_Product',
            key: 'Loan_Product',
        },
        {
            title: 'Borrower',
            dataIndex: 'Borrower',
            key: 'Borrower',
        },
        {
            title: 'Applied Date',
            dataIndex: 'First_Payment_Date',
            key: 'First_Payment_Date',
        },
        {
            title: 'Approved Date',
            dataIndex: 'Release_date',
            key: 'Release_date',
        },
        {
            title: 'Applied Amount',
            dataIndex: 'Applied_Amount',
            key: 'Applied_Amount',
        },
        {
            title: 'Late Payments Penalties',
            dataIndex: 'Late_Payments_Penalties',
            key: 'Late_Payments_Penalties',
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            key: 'Description',
        },
        {
            title: 'Remarks',
            dataIndex: 'Remarks',
            key: 'Remarks',
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
        },
        {
            title: 'Loan ID',
            dataIndex: 'UniqueLoanID',
            key: 'UniqueLoanID',
        },
    ];


    const options = {
        // default is `save`
        method: "save" as const,
        filename: `Loan Report.pdf`,
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        // resolution: Resolution.HIGH,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            format: 'A4',
            // default is 'portrait'
            orientation: "portrait" as const,
        },
        // canvas: {
        //    // default is 'image/jpeg' for better size performance
        //    mimeType: 'image/png',
        //    qualityRatio: 1
        // },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break, 
        // so use with caution.
        // overrides: {
        //    pdf: {
        //       compress: true
        //    },
        //    canvas: {
        //       useCORS: true
        //    }
        // },
    };

    const handlePDFPrint = () => {
        generatePDF(printRef, options);
    };


    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    useEffect(() => {
        setRenderKey(Math.random());
        if (isPdfTriggered === true) {
            handlePDFPrint();
            setIsPdfTriggered(false);
        }
    }, [isPdfTriggered])

    useEffect(() => {
        setRenderKey(Math.random());
        if (isPrintTriggered === true) {
            handlePrint();
            setIsPrintTriggered(false);
        }
    }, [isPrintTriggered])

    const headers = [
        {
            label: "SlNo", key: "SlNo"
        },
        {
            label: "Member ID", key: "LoanID"
        },
        {
            label: "Loan_Product", key: "Loan_Product"
        },
        {
            label: "First_Payment_Date", key: "First_Payment_Date"
        },
        {
            label: "Release_date", key: "Release_date"
        },
        {
            label: "Applied_Amount", key: "Applied_Amount"
        },
        {
            label: "Late_Payments_Penalties", key: "Late_Payments_Penalties"
        },
        {
            label: "Description", key: "Description"
        },
        {
            label: "Remarks", key: "Remarks"
        },
        {
            label: "Status", key: "Status"
        },
        {
            label: "Loan ID", key: "UniqueLoanID"
        },

    ]

    const csvLink = {
        filename: `Loan Report.csv`,
        headers: headers,
        data: statementTableData,
    }



    return (
        <>
            <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center" }}>
                {(!formSuccess) ? <div style={{ width: "100%", height: "50%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div style={{ width: "100%", height: "50%", border: "1px solid", display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", justifyContent: "space-evenly", }}>
                            <div style={{ width: "15%", }}><span>Start Date</span> <DatePicker allowClear={false} value={dayjs(inputData.startDate.toString())} style={{ height: "calc(1.8em + 1.6rem + 2px)", width: "100%" }}
                                onChange={(value: DatePickerProps['value'] | RangePickerProps['value']) => handleDateChange(value, "startDate")}
                            /></div>
                            <div style={{ width: "15%", }}><span>End Date</span> <DatePicker allowClear={false} value={dayjs(inputData.endDate.toString())} style={{ height: "calc(1.8em + 1.6rem + 2px)", width: "100%" }}
                                onChange={(value: DatePickerProps['value'] | RangePickerProps['value']) => handleDateChange(value, "endDate")}
                            /></div>
                            <div style={{ width: "15%", height: "60%" }}><span>Loan Type</span> <Select
                                showSearch
                                placeholder="Loan Product"
                                optionFilterProp="children"
                                onChange={(e) => setInputData((prevState: InputDataInterface) => { return { ...prevState, loanType: e } })}
                                filterOption={filterOption}
                                style={{ width: "100%", height: "75%" }}
                                options={loanProductName}
                                defaultValue={inputData.loanType}
                            /></div>
                            <div style={{ width: "15%", height: "60%" }}><span>Status</span> <Select
                                showSearch
                                placeholder="Status"
                                optionFilterProp="children"
                                onChange={(e) => setInputData((prevState: InputDataInterface) => { return { ...prevState, status: e } })}
                                filterOption={filterOption}
                                style={{ width: "100%", height: "75%" }}
                                options={[
                                    { value: 'All', label: 'All' },
                                    { value: 'Pending', label: 'Pending' },
                                    { value: 'Completed', label: 'Completed' },
                                    { value: 'Approved', label: 'Approved' },
                                ]}
                                defaultValue={inputData.status}
                            /></div>
                            <div style={{ width: "15%", display: "flex", flexDirection: "column", }}><span>Account No</span><Input min={0} max={100} style={{ height: "calc(1.8em + 1.6rem + 2px)" }}
                                onChange={(e) => setInputData((prevState: InputDataInterface) => { return { ...prevState, accountNo: e.target.value } })}

                            />

                                <Checkbox style={{ marginTop: 10 }} onChange={(e) => {
                                    setInputData((prevState) => {
                                        return {
                                            ...prevState,
                                            checked: e.target.checked
                                        }
                                    })
                                }}>All Accounts</Checkbox></div>
                        </div>

                        <div style={{ width: "100%", marginTop: 20, display: "flex", justifyContent: "center" }}><Button onClick={handleSubmit} style={{ width: "20%", height: "calc(1.8em + 1.6rem + 2px)", backgroundColor: ColorCode.GreenCode, fontWeight: "bold", color: "white" }} >Filter</Button></div>

                    </div>
                    <div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                            <div style={{ width: "9%", display: "flex", justifyContent: "space-between", marginTop: 20, alignItems: "center" }}>
                                <CSVLink {...csvLink} style={{ display: "flex", textDecoration: "none", color: "black", backgroundColor: "white", height: 32, borderRadius: 5, justifyContent: "center", alignItems: "center" }}><Button style={{ backgroundColor: "gray", color: "white" }}>Excel</Button></CSVLink>
                                <Button style={{ backgroundColor: "red", color: "white" }} onClick={() => { setRenderKey(Math.random()); setIsPdfTriggered(true) }}>PDF</Button>
                                <Button style={{ backgroundColor: "green", color: "white" }} onClick={() => { setRenderKey(Math.random()); setIsPrintTriggered(true) }}>Print</Button>
                            </div>
                        </div>
                        <div key={renderKey} ref={printRef}>
                            <div style={{ display: "flex", width: "100%" }}>
                                <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                    <h3>HOSPITAL OFFICE FUND</h3>
                                    <div style={{ width: 186, textAlign: "center" }}><h3>Loan Report</h3></div>
                                    <div style={{ width: 186, textAlign: "center" }}><h3>Total Loan Applied ₹ {totalAmount.Applied_Amount}</h3></div>
                                </div>
                            </div>
                            <Table pagination={isPrintTriggered || isPdfTriggered ? false : { position: ['topLeft'] }} dataSource={statementTableData} columns={columns} />

                        </div>

                    </div>

                </div> : <SuccessPage />}

            </div>
        </>
    )
}

export default LoanReport