import React, { useEffect } from 'react';
import Menu from './Menu';
import { makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import Cookies from 'universal-cookie';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setCounterPermission } from '../redux/actions/ProductActions';
import { BackendRootStrings } from './Routes';

const useStyles = makeStyles((theme) => ({
  root: {
      '& .MuiTextField-root': {
          margin: theme.spacing(1),
          width: '25ch',
      },
  },
  '@media (min-width: 780px)': {
      width: '80%'
  },

  


}));


const Home = () => {

  const classes = useStyles();

  const navigate = useNavigate();

  const cookies = new Cookies();

  const dispatch=useDispatch();

  useEffect(() => {
    
    let val1 = cookies.get("isAuthenticated");
    if (val1 == "false" || val1 === undefined || !val1) {
      navigate('/login');
    }
    // else{
    //   navigate('/')
    // }
  }, [])

  useEffect(() => {
    (async () => {
        const { data } = await axios.get(`${BackendRootStrings.apiHome}/getPermissionData`);
        const { Counter } = data[0];
        dispatch(setCounterPermission(Counter))
    })()
}, [])

  


  return (
    <div style={{height:932,width:"99%",border:"2px solid",display:"flex",justifyContent:"center",overflow:'hidden',overflowX:'hidden'}}>
      <div style={{height:"100%",width:"100%"}}>
        <Menu/>
      </div>
    </div>
    
  )
}

export default Home