import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { BackendRootStrings } from '../Routes'
import { Button, Input, Spin, Table } from 'antd'
import generatePDF, { Margin } from 'react-to-pdf'
import { useReactToPrint } from 'react-to-print';
import { CSVLink } from 'react-csv'
import { makeStyles } from '@material-ui/core'
import { ColorCode } from '../../resources/ColorCodes'

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    defaulterClass: {
        backgroundColor: "#ef8080 !important"
    },
    nonDefaulterClass: {
        backgroundColor: "#cbe9cb !important"
    },
    rowHead: {
        "&.ant-table-wrapper .ant-table-thead >tr>th": {
            backgroundColor: `${ColorCode.GreenCode} !important`,
            color: "white !important"
        }
    }


}));


interface TableDataInterface {
    SlNo: number,
    NAME: string,
    TNo: number,
    TOTAL: string,
    defaulterMonths: Array<string>
}

const AllDepositReport = () => {
    const classes = useStyles();

    const printRef = useRef<any>();
    let [isPdfTriggered, setIsPdfTriggered] = useState(false);
    const [isPrintTriggered, setIsPrintTriggered] = useState(false);

    const [renderKey, setRenderKey] = useState(Math.random());

    const [loading, setLoading] = useState(false);

    let [tableData, setTableData] = useState<TableDataInterface[]>([]);
    let [filterTableData, setFilterTableData] = useState<TableDataInterface[]>([]);

    let [totalAmount, setTotalAmount] = useState(0);

    let [deductionValueState, setDeductionValueState] = useState({ amount: "", unit: "", id: "" })

    let [totalAmountCollected, setTotalAmountCollected] = useState(0);
    let [totalAmountDeducted, setTotalAmountDeducted] = useState(0);

    const getRowClassName = (record: TableDataInterface) => {
        return record.defaulterMonths.length === 0 ? '' : `${classes.defaulterClass}`;
    };


    useEffect(() => {
        (async () => {
            setLoading(true);
            const data1 = await axios.get(`${BackendRootStrings.apiHome}/getFinalReportWithDefaulterAndAmount`);
            const finalInterestDistribution = await axios.post(`${BackendRootStrings.apiHome}/distributeFinalInterest`);
            const { data } = await axios.get(`${BackendRootStrings.apiHome}/getDeductionValue`) as { data: { Amount: string, Unit: string, id: string }[] };
            setDeductionValueState((prevState) => {
                return {
                    ...prevState,
                    amount: data[0]?.Amount ?? "0",
                    unit: data[0]?.Unit ?? "0",
                    id: data[0]?.id ?? "0",
                }
            })
            deductionValueState.amount = data[0]?.Amount ?? "0";
            deductionValueState.unit = data[0]?.Unit ?? "0";
            deductionValueState.id = data[0]?.id ?? "0";

            setTotalAmountCollected(0);
            setTotalAmountDeducted(0);
            totalAmountCollected = 0;
            totalAmountDeducted = 0;


            let distributionCreditMap = new Map();
            finalInterestDistribution.data.map((val: Array<number>, ind: number) => {
                distributionCreditMap.set(val[0], val[1]);
            })
            setTableData([]);
            tableData = [];
            filterTableData = [];
            setFilterTableData([]);
            setTotalAmount(0);
            totalAmount = 0;
            data1.data.map((val: TableDataInterface, ind: number) => {
                let interest = distributionCreditMap.get(val.TNo) as number;
                let total = "0";
                if (parseInt(val.TOTAL) > 0) {
                    total = (val.TOTAL) + ((deductionValueState.unit === "p") ?
                        (interest - (interest * (parseFloat(deductionValueState.amount) ?? 0) / 100)) : interest - parseFloat(deductionValueState.amount ?? 0));
                }


                setTableData((prevState) => {
                    return prevState.concat({
                        SlNo: ind + 1,
                        NAME: val.NAME,
                        TNo: val.TNo,
                        TOTAL: (total),
                        defaulterMonths: val.defaulterMonths
                    })
                })
                setFilterTableData((prevState) => {
                    return prevState.concat({
                        SlNo: ind + 1,
                        NAME: val.NAME,
                        TNo: val.TNo,
                        TOTAL: total,
                        defaulterMonths: val.defaulterMonths
                    })
                })
                let totalInNo = JSON.parse(JSON.stringify(parseFloat(total)))
                setTotalAmount(totalAmount + totalInNo);
                totalAmount += totalInNo;

                setTotalAmountCollected(totalAmountCollected + parseFloat(val.TOTAL) + interest);
                totalAmountCollected += parseFloat(val.TOTAL) + interest;
                let temp1=0;
                if(parseFloat(val.TOTAL)>0){
                    temp1 = ((deductionValueState.unit === "p") ?
                    ((interest * (parseFloat(deductionValueState.amount) ?? 0) / 100)) : parseFloat(deductionValueState.amount ?? 0));
                }

                

                setTotalAmountDeducted(totalAmountDeducted + temp1)
                totalAmountDeducted += temp1;

            })
            setLoading(false);
        })()
    }, [])



    const columns = [
        {
            title: 'SlNo',
            dataIndex: 'SlNo',
            key: 'SlNo',
            render: (val: any) => <span style={{ fontWeight: "bold" }}>{val}</span>
        },
        {
            title: 'Member ID',
            dataIndex: 'TNo',
            key: 'TNo',
            render: (val: any) => <span style={{ fontWeight: "bold" }}>{val}</span>
        },
        {
            title: 'NAME',
            dataIndex: 'NAME',
            key: 'NAME',
            render: (val: any) => <span style={{ fontWeight: "bold" }}>{val}</span>
        },
        {
            title: 'TOTAL',
            dataIndex: 'TOTAL',
            key: 'TOTAL',
            render: (val: any) => <span style={{ fontWeight: "bold" }}>{parseFloat(val).toFixed(2)}</span>
        },
        {
            title: 'Defaulter Months',
            dataIndex: 'defaulterMonths',
            key: 'defaulterMonths',
            render: (val: Array<string>) => <div style={{ width: "80%", display: "flex", flexWrap: "wrap", height: "fit-content" }}>
                {val.map((current, ind) => {
                    return (
                        <span style={{ fontWeight: "bold" }} key={ind}>
                            {current.substring(0, 3)},
                        </span>
                    )
                })}

            </div>
        },
        {
            title: 'Signature',
            dataIndex: 'Signature',
            key: 'Signature',
        }

    ];


    const options = {
        // default is `save`
        method: "save" as const,
        filename: `All Deposit Report.pdf`,
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        // resolution: Resolution.HIGH,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            format: 'A4',
            // default is 'portrait'
            orientation: "portrait" as const,
        },
        // canvas: {
        //    // default is 'image/jpeg' for better size performance
        //    mimeType: 'image/png',
        //    qualityRatio: 1
        // },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break, 
        // so use with caution.
        // overrides: {
        //    pdf: {
        //       compress: true
        //    },
        //    canvas: {
        //       useCORS: true
        //    }
        // },
    };

    const handlePDFPrint = () => {
        generatePDF(printRef, options);
    };


    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    useEffect(() => {
        setRenderKey(Math.random());
        if (isPdfTriggered === true) {
            handlePDFPrint();
            setIsPdfTriggered(false);
        }
    }, [isPdfTriggered])

    useEffect(() => {
        setRenderKey(Math.random());
        if (isPrintTriggered === true) {
            handlePrint();
            setIsPrintTriggered(false);
        }
    }, [isPrintTriggered])

    const headers = [
        {
            label: "SlNo", key: "SlNo"
        },
        {
            label: "NAME", key: "NAME"
        },
        {
            label: "Account No", key: "TNo"
        },
        {
            label: "TOTAL", key: "TOTAL"
        },
        {
            label: "Defaulter Months", key: "defaulterMonths"
        },

    ]

    const csvLink = {
        filename: `All Deposit Report.csv`,
        headers: headers,
        data: tableData,
    }





    return (
        <div>
            <Spin size='large' style={{ marginTop: 100 }} spinning={loading} delay={500}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ width: "9%", display: "flex", justifyContent: "space-between", marginTop: 20, alignItems: "center" }}>
                        <CSVLink {...csvLink} style={{ display: "flex", textDecoration: "none", color: "black", backgroundColor: "white", height: 32, borderRadius: 5, justifyContent: "center", alignItems: "center" }}><Button style={{ backgroundColor: "gray", color: "white" }}>Excel</Button></CSVLink>
                        <Button style={{ backgroundColor: "red", color: "white" }} onClick={() => { setRenderKey(Math.random()); setIsPdfTriggered(true) }}>PDF</Button>
                        <Button style={{ backgroundColor: "green", color: "white" }} onClick={() => { setRenderKey(Math.random()); setIsPrintTriggered(true) }}>Print</Button>
                    </div>
                </div>
                <div key={renderKey} ref={printRef}>
                    <div style={{ display: "flex", width: "100%" }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                            <h3>HOSPITAL OFFICE FUND</h3>
                            <div style={{ width: 186, textAlign: "center" }}><h3>All Deposit Report</h3></div>
                            {(!isPrintTriggered && !isPdfTriggered) && <div><h3>Total Amount Collected - {totalAmountCollected.toFixed(2)} <br /> Total Amount Deducted - {totalAmountDeducted.toFixed(2)}</h3></div>}
                            <div style={{ width: 220, textAlign: "center" }}><h3>Total Amount ₹ {totalAmount.toFixed(2)}</h3></div>
                        </div>
                    </div>
                    {(!isPrintTriggered && !isPdfTriggered) && <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Input onChange={(e) => {
                            const tempData = tableData.filter((val) => {
                                if (val.TNo.toString().includes(e.target.value)) {
                                    return val;
                                }
                                else if (val.NAME.toLowerCase().includes(e.target.value.toLowerCase())) {
                                    return val;
                                }
                            })
                            setTotalAmount(0);
                            totalAmount = 0;
                            if (tempData.length !== 0) {
                                setFilterTableData(tempData);
                                tempData.map((val) => { setTotalAmount(totalAmount + parseFloat(val.TOTAL.toString())); totalAmount += parseFloat(val.TOTAL.toString()) })
                            } else {
                                setFilterTableData(tableData)
                                tableData.map((val) => { setTotalAmount(totalAmount + parseFloat(val.TOTAL.toString())); totalAmount += parseFloat(val.TOTAL.toString()) })
                            }

                        }} placeholder='Enter MID or Name' style={{ width: "20%" }} />
                    </div>}

                    <Table className={classes.rowHead} rowClassName={getRowClassName} rowKey="TNo" pagination={isPrintTriggered || isPdfTriggered ? false : { position: ['topLeft'] }} dataSource={filterTableData} columns={columns} />
                    {(isPrintTriggered || isPdfTriggered) && <div style={{ display: "flex", width: "100%", justifyContent: "center", marginTop: 100 }}>
                        <div style={{ display: "flex", width: "80%", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", width: "20%", flexDirection: "column" }}>
                                <div style={{ borderBottom: '3px solid black', width: "100%" }}>

                                </div>
                                <span style={{ textAlign: "center" }}>Date</span>
                            </div>
                            <div style={{ display: "flex", width: "20%", flexDirection: "column" }}>
                                <div style={{ borderBottom: '3px solid black', width: "100%" }}>

                                </div>
                                <span style={{ textAlign: "center" }}>Signature</span>
                            </div>

                        </div>

                    </div>}
                </div>
            </Spin>
        </div>
    )
}

export default AllDepositReport