import React, { ReactElement, useEffect, useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DownOutlined,
  DashboardOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';

import { FaRupeeSign, FaListAlt } from "react-icons/fa";
import { GiClawHammer, GiReceiveMoney } from "react-icons/gi";
import { RiBankFill } from "react-icons/ri";
import { MdManageAccounts } from "react-icons/md";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { TbReport } from "react-icons/tb";
import { BsCashCoin } from "react-icons/bs";

import { Layout, Menu, Button, theme, Dropdown, Space, message } from 'antd';
import DashBoardItem1 from './DashBoardItems/DashBoardMain';
import AccountReports from './AccountReports/AccountReports';
import Register from './CreateAccount/Register';
import TotalClient from './DashBoardItems/TotalClient';
import DepositMain from './Deposit/DepositMain';
import CreateCounter from './CreateCounter/CreateCounter';
import CounterList from './CounterList/CounterList';
import AllLoans from './Loans/AllLoans';
import LoanView from './Loans/LoanView'

import type { MenuProps } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BackendRootStrings, RouteStrings } from './Routes';
import ViewUser from './UserOperations/ViewUser';
import AddLoan from './Loans/AddLoan';
import LoanProducts from './Loans/LoanProducts';
import AddLoanProduct from './Loans/AddLoanProduct';
import Repayments from './Repayments/Repayments';
import AddLoanRepayments from './Repayments/AddLoanRepayments';
import ViewLoanRepayments from './Repayments/ViewLoanRepayments';
import CounterTransferMain from './CounterTransfer/CounterTransferMain';
import DefaultImage from '../resources/DefaultImage.png';
import { makeStyles } from '@material-ui/core';
import Cookies from 'universal-cookie';
import EditUser from './UserOperations/EditUser';
import LoanCalculater from './Loans/LoanCalculater';

import CompanyLogo from '../resources/CompanyDashBoardLogo.png'
import AccountStatement from './AccountReports/AccountStatement';
import LoanReports from './AccountReports/LoanReports';
import LoanDueReport from './AccountReports/LoanDueReport';
import TransferReport from './AccountReports/TransferReport';
import TransferDepositReport from './AccountReports/CounterDepositReport';
import DepositDetails from './Deposit/DepositDetails';
import CounterLoanRepaymentReport from './AccountReports/CounterLoanRepaymentReport';
import FinalReport from './AccountReports/FinalReport';
import AllDepositReport from './AccountReports/AllDepositReport';
import ChangePassword from './UserOperations/ChangePassword';
import CounterTransactionReport from './AccountReports/CounterTransactionReport';
import ManageAccount from './ManageAccount/ManageAccount';
import axios from 'axios';
import { useSelector } from 'react-redux';
import EditProfile from './UserOperations/EditProfile';



const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  '@media (min-width: 780px)': {
    width: '80%'
  },

  loginBox: {
    '&:hover': {
      cursor: "pointer",
    },

  },
  dropdown1: {
    "&.anticon svg": {
      color: "black",
    }
  },
  layoutClass: {
    "&.ant-layout .ant-layout-sider-children": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    }
  }
}));



const { Header, Sider, Content } = Layout;


const App: React.FC = () => {

  const classes = useStyles();

  const products: { allProducts: { counterPermission: { monthlyDeposit: number, loanRepayments: number }[] } } = useSelector((state: { allProducts: { counterPermission: { monthlyDeposit: number, loanRepayments: number }[] } }) => state);


  const cookies = new Cookies();

  const [userName, setUserName] = useState("Default");
  const [userType, setUserType] = useState("");


  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    location.state?.path ? location.state.path[0] : '1'
  );

  const [menuItems, setMenuItems] = useState<{ key: string; icon?: ReactElement<any, any>; label: string; onClick?: () => void, children?: any }[]>([]);


  type MenuItem = Required<MenuProps>['items'][number];

  const componentsSwitch = (key: string) => {
    switch (key) {
      case '1':
        return (<DashBoardItem1 />);
      case '2':
        return (<Register />);
      case '3':
        return (<TotalClient />);
      case '3-3':
        return (<ViewUser />);
      case '3-4':
        return (<Register />);
      case '4-1':
        return (<DepositMain />);
      case '5-1':
        return (<AllLoans />);
      case '5-4':
        return (<LoanProducts />);
      case '5-4-1':
        return (<AddLoanProduct />);
      case '5-1-1':
        return (<LoanView />);
      case '5-1-2':
        return (<AddLoan />);
      case '5-3':
        return (<LoanCalculater />);
      case '5-5':
        return (<Repayments />);
      case '5-5-1':
        return (<AddLoanRepayments />);
      case '5-5-2':
        return (<ViewLoanRepayments />);
      case '7':
        return (<CreateCounter />);
      case '8':
        return (<CounterList />);
      case '11':
        return (<CounterTransferMain />);
      case '12-1':
        return (<AccountReports />);
      case '12-2':
        return (<AccountStatement />);
      case '12-4':
        return (<LoanReports />);
      case '12-5':
        return (<LoanDueReport />);
      case '12-6':
        return (<TransferReport />);
      case '12-7':
        return (<TransferDepositReport />);
      case '12-8':
        return (<CounterLoanRepaymentReport />);
      case '12-9':
        return (<FinalReport />);
      case '12-10':
        return (<AllDepositReport />);
      case '12-11':
        return (<CounterTransactionReport />);
      case '13':
        return (<DepositDetails />);
      case '14':
        return (<ManageAccount />);
      case '20-1':
        return (<ChangePassword />);
      case '20-2':
        return (<EditProfile />);
      default:
        break;
    }
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[] | MenuItem,
    type?: 'group',
  ): MenuItem | undefined {

    if (userType.includes("Counter") && key == "4-1") {
      if (products.allProducts.counterPermission[0].monthlyDeposit !== 0) {
        return {
          key,
          icon,
          children,
          label,
          type,
        } as MenuItem;
      }
      return undefined;

    } 
    else if (userType.includes("Counter") && key == "5-5") {
      if (products.allProducts.counterPermission[1].loanRepayments !== 0) {
        return {
          key,
          icon,
          children,
          label,
          type,
        } as MenuItem;
      }
      return undefined;

    } 
    else {
      return {
        key,
        icon,
        children,
        label,
        type,
      } as MenuItem;
    }
  }


  const items: MenuProps['items'] = [
    {
      label: <Link to={RouteStrings.EditProfile} state={{ path: "EditProfile" }}>Profile Settings</Link>,
      key: '0',
    },
    {
      label: <Link to={RouteStrings.ChangePassword} state={{ path: "ChangePassword" }}>Change Password</Link>,
      key: '1',
    },
    {
      type: 'divider',
    },
    {
      label: 'Logout',
      danger: true,
      key: '3',
      onClick: () => {
        cookies.set("isAuthenticated", "false");
        cookies.set("userLevel", ""); navigate('/login')
      }
    },
  ];

  useEffect(() => {
    let tempUserName = cookies.get("First_Name");
    let tempUserType = cookies.get("UserType");
    setUserName(tempUserName);
    setUserType(tempUserType);
  }, [])



  useEffect(() => {

    if (location.state?.path) {
      switch (location.state.path) {
        case 'ViewLoan':
          setSelectedMenuItem('5-1-1');
          break;
        case 'RegisterUser':
          setSelectedMenuItem('2')
          // setSelectedMenuItem('3-4');
          break;
        case 'ViewUser':
          setSelectedMenuItem('3-3');
          break;
        case 'AllLoans':
          setSelectedMenuItem('5-1');
          break;
        case 'EditUser':
          setSelectedMenuItem('3-4');
          break;
        case 'AddLoan':

          setSelectedMenuItem('5-1-2');
          break;
        case 'AllLoanProduct':
          setSelectedMenuItem('5-4');
          break;
        case 'AddLoanProduct':
          setSelectedMenuItem('5-4-1');
          break;
        case 'AddLoanRepayment':
          setSelectedMenuItem('5-5-1');
          break;
        case 'ViewLoanRepayment':
          setSelectedMenuItem('5-5-2');
          break;
        case 'ChangePassword':
          setSelectedMenuItem('20-1');
          break;
        case 'EditProfile':
          setSelectedMenuItem('20-2');
          break;

        default:
          break;
      }
    }
  }, [location]);

  useEffect(() => {
    if (userType === "Admin") {
      setMenuItems(
        [
          {
            key: '1',
            icon: <DashboardOutlined />,
            label: 'DASHBOARD',
            onClick: () => { navigate('/') }
          },
          {
            key: '2',
            icon: <UserAddOutlined />,
            label: 'CREATE ACCOUNT',
            onClick: () => {
              navigate('/', {
                state: {
                  path: 'RegisterUser'
                }
              })
            }
          },
          {
            key: '3',
            icon: <UsergroupAddOutlined />,
            label: 'ACCOUNT LIST',
          },
          {
            key: '13',
            icon: <BsCashCoin />,
            label: 'DEPOSIT DETAILS',
          },
          {
            key: '4',
            icon: <FaRupeeSign />,
            label: 'TRANSACTION',
            children: [
              getItem('Monthly Deposit', '4-1'),
              getItem('Loan Repayments', '5-5'),
            ]
          },
          {
            key: '5',
            icon: <GiReceiveMoney />,
            label: 'LOANS',
            children: [
              getItem('All Loans', '5-1'),
              getItem('Loan Calculator', '5-3'),
              getItem('Loan Products', '5-4'),

            ]
          },
          {
            key: '11',
            icon: <FaMoneyBillTransfer />,
            label: 'COUNTER TRANSFER',
          },
          {
            key: '7',
            icon: <RiBankFill />,
            label: 'CREATE COUNTER',
            // style:{display:(userType!=="Admin")?"none":"block"},
            onClick: () => {
              if (userType) {
                navigate("/");
              }
            }
          },

          {
            key: '8',
            icon: <FaListAlt />,
            label: 'COUNTER LIST',
            // style:{display:(userType!=="Admin")?"none":"block"},
            onClick: () => {
              if (userType) {
                navigate("/");
              }
            }
          },
          // {
          //   key: '9',
          //   icon: <UploadOutlined />,
          //   label: 'MANAGE ACCOUNT',
          // },
          // {
          //   key: '10',
          //   icon: <MdManageAccounts />,
          //   label: 'MANAGE COUNTER',
          // },

          {
            key: '12',
            icon: <TbReport />,
            label: 'REPORTS',
            children: [
              // getItem('Counter Report', '12-1'),
              getItem('Account Statement', '12-2'),
              // getItem('Account Balance', '12-3'),
              getItem('Loan Report', '12-4'),
              getItem('Loan Due Report', '12-5'),
              getItem('Counter Transfer Report', '12-6'),
              getItem('Counter Deposit Report', '12-7'),
              getItem('Counter Loan Repayment Report', '12-8'),
              getItem('All Deposit Report', '12-10'),
              getItem('Counter Transaction Report', '12-11'),
              getItem('Final Report', '12-9'),

            ]
          },
          {
            key: '14',
            icon: <FaMoneyBillTransfer />,
            label: 'MANAGE ACCOUNT',
          },
        ]
      )
    } else {
      setMenuItems(
        [
          {
            key: '1',
            icon: <DashboardOutlined />,
            label: 'DASHBOARD',
            onClick: () => { navigate('/') }
          },
          {
            key: '2',
            icon: <UserAddOutlined />,
            label: 'CREATE ACCOUNT',
            onClick: () => {
              navigate('/', {
                state: {
                  path: 'RegisterUser'
                }
              })
            }
          },
          {
            key: '3',
            icon: <UsergroupAddOutlined />,
            label: 'ACCOUNT LIST',
          },
          // {
          //   key: '13',
          //   icon: <BsCashCoin />,
          //   label: 'DEPOSIT DETAILS',
          // },
          {
            key: '4',
            icon: <FaRupeeSign />,
            label: 'TRANSACTION',
            children: [
              getItem('Monthly Deposit', '4-1'),
              getItem('Loan Repayments', '5-5'),
            ]
          },
          {
            key: '5',
            icon: <GiReceiveMoney />,
            label: 'LOANS',
            children: [
              getItem('All Loans', '5-1'),
              getItem('Loan Calculator', '5-3'),
              getItem('Loan Products', '5-4'),

            ]
          },
          {
            key: '11',
            icon: <FaMoneyBillTransfer />,
            label: 'COUNTER TRANSFER',
          },



          // {
          //   key: '9',
          //   icon: <UploadOutlined />,
          //   label: 'MANAGE ACCOUNT',
          // },
          // {
          //   key: '10',
          //   icon: <MdManageAccounts />,
          //   label: 'MANAGE COUNTER',
          // },

          {
            key: '12',
            icon: <TbReport />,
            label: 'REPORTS',
            children: [
              // getItem('Counter Report', '12-1'),
              getItem('Account Statement', '12-2'),
              // getItem('Account Balance', '12-3'),
              getItem('Loan Report', '12-4'),
              getItem('Loan Due Report', '12-5'),
              getItem('Counter Transfer Report', '12-6'),
              getItem('Counter Deposit Report', '12-7'),
              getItem('Counter Loan Repayment Report', '12-8'),
              getItem('All Deposit Report', '12-10'),
              getItem('Counter Transaction Report', '12-11'),
              getItem('Final Report', '12-9'),

            ]
          },
        ]
      )
    }
  }, [userType])






  return (
    <Layout className={classes.layoutClass} style={{ height: "100%" }}>
      <Sider trigger={null} collapsible collapsed={collapsed} style={{}}>

        <div style={{ overflow: "hidden", overflowY: "visible" }}>
          <div className="demo-logo-vertical" style={{ display: "flex", justifyContent: "center", height: "13%", width: "100%", marginTop: 1 }} >
            <div style={{ height: "100%", width: "95%" }}>
              <img style={{ height: "100%", width: "100%", borderRadius: 10 }} src={CompanyLogo} alt="" />
            </div>

          </div>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[selectedMenuItem]}
            onClick={(e) => setSelectedMenuItem(e.key)}
            items={menuItems}
          />
        </div>

        <div style={{ backgroundColor: "yellow", border: "3px solid black", height: "5%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <div><p style={{ fontSize: (!collapsed) ? 10 : 4, marginBottom: 0, fontWeight: (!collapsed) ? "bolder" : "normal", textAlign: "center" }}>© 2024 HOSPITAL OFFICE FUND.</p>
              {/* <p style={{marginTop:0,textAlign:"center",fontSize:(!collapsed)?13:5}}>Developed by <Link target='_blank' to="https://imaginativo.in/"style={{fontWeight:"bold"}}>IMAGINATIVO</Link></p> */}
            </div>
          </div>
        </div>
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer, display: "flex", justifyContent: "space-between" }}>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
          <div style={{ marginRight: 8, marginTop: -4, backgroundColor: "#17a2b8", width: "15%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", }}>
            <div style={{ height: 32, width: 35, display: "flex", alignItems: "center", borderRadius: 85 }}><img style={{ height: "100%", width: "100%", borderRadius: 85 }} src={DefaultImage} alt="" /></div>
            <div style={{ fontWeight: "bold", color: 'white' }}>{userName}</div>
            <div className={classes.loginBox} style={{}}>
              <Dropdown menu={{ items }} trigger={['click']}>
                <a onClick={(e) => e.preventDefault()}>
                  <Space>

                    <DownOutlined className={classes.dropdown1} />
                  </Space>
                </a>
              </Dropdown>
            </div>

          </div>
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
            overflow: "hidden",
            overflowY: "scroll",
            overflowX: "scroll",
          }}
        >
          {componentsSwitch(selectedMenuItem)}
        </Content>
      </Layout>
    </Layout >
  );
};

export default App;