import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { BackendRootStrings } from '../Routes';
import { Button, Checkbox, Input, message, Select } from 'antd';
import type { GetProp } from 'antd';



interface CheckBoxInterface {
    label: string,
    value: string
}
interface permissionsDefaultValueInterface {
    Counter: string[],
}
interface counterPermissionDataInterface {
    [key: string]: string;
}

const ManageAccount = () => {

    let [counterOptions, setCounterOptions] = useState<CheckBoxInterface[]>([]);
    let [permissionDefaultValues, setPermissionDefaultValues] = useState<permissionsDefaultValueInterface>({ Counter: [] });
    let [deductionValueState, setDeductionValueState] = useState({amount:"",unit:"",id:""})

    const [renderKey, setRenderKey] = useState(Math.random());

    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    useEffect(() => {
        (async () => {
            const { data } = await axios.get(`${BackendRootStrings.apiHome}/getPermissionData`);
            const { Counter } = data[0];
            setCounterOptions([]);
            counterOptions = [];
            permissionDefaultValues = { Counter: [] };
            setPermissionDefaultValues({ Counter: [] });
            Counter.map((val: counterPermissionDataInterface, ind: number) => {
                if (Object.values(val)[0]) {
                    setPermissionDefaultValues((prevState) => {
                        return {
                            ...prevState,
                            Counter: prevState.Counter.concat(ind.toString())
                        }
                    })
                }
                setCounterOptions((prevState) => {
                    return prevState.concat({ label: Object.keys(val)[0], value: ind.toString() })
                })
            })
            setRenderKey(Math.random())
        })()
    }, [])

    useEffect(() => {
      (async()=>{
        const {data}=await axios.get(`${BackendRootStrings.apiHome}/getDeductionValue`) as {data:{Amount: string,Unit: string,id: string}[]};
        setDeductionValueState((prevState)=>{
            return{
                ...prevState,
                amount:data[0]?.Amount??"",
                unit:data[0]?.Unit??"",
                id:data[0]?.id??"",
            }
        })
      })()
    }, [])
    


    const handleChange: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
        let newChakedValues = checkedValues.map((val) => val.toString())
        setPermissionDefaultValues((prevState) => {
            return {
                ...prevState,
                Counter: newChakedValues
            }
        })
    };

    const handlePermissionChange = async () => {
        axios.put(`${BackendRootStrings.apiHome}/updatePermissions`, permissionDefaultValues)
            .then(({ data }) => {
                message.info("Saved Successfully");
            })
            .catch((err) => {
                message.error(err)
            })
    }

    const handleClearDatabase = async () => {
        const confirm = window.confirm("Are you absolutely sure??? This operation will clear the databse permanently!!!");
        if (confirm) {
            setLoading(true);
            await axios.post(`${BackendRootStrings.apiHome}/clearDatabase`);
            message.success("Deletion done successfully");
            setLoading(false);
        }

    }

    const handleUpdateDeduction=async()=>{
        setLoading2(true);
        if(deductionValueState.id===""){
            deductionValueState.id=Date.now().toString();
        }
        await axios.post(`${BackendRootStrings.apiHome}/updateDeduction`,deductionValueState)
        setLoading2(false);
    }

    return (
        <div style={{ height: 900, width: "100%", justifyContent: "center", alignItems: "center" }}>
            <div style={{ height: "100%", width: "100%", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <div>
                    <h4>Counter Permission</h4><hr />
                    <Checkbox.Group onChange={handleChange} key={renderKey} options={counterOptions} defaultValue={permissionDefaultValues.Counter}>
                        {/* {driverOptions.map((option, index) => {
                        const label = Object.keys(option)[0];
                        const value = Object.values(option)[0];
                        return (
                            <Checkbox
                                key={index}
                                value={label} // Use the label as the value
                                checked={value === 1} // Check if the value is 1
                            >
                                {label}
                            </Checkbox>
                        );
                    })} */}
                    </Checkbox.Group>
                    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={handlePermissionChange} type='primary'>Submit</Button>
                    </div>
                    <hr />

                    <div style={{ display: "flex", flexDirection: "row", marginTop: 20 ,width:"50%"}}>
                        <div style={{ display: "flex", flexDirection: "column",marginRight:5 }}>
                            <p>Deduction Amount</p><Input value={deductionValueState.amount} onChange={(e)=>{
                                setDeductionValueState((prevState)=>{
                                    return{
                                        ...prevState,
                                        amount:e.target.value
                                    }
                                })
                            }} style={{ width: "100%", height: "50%" }} />
                        </div>

                        <div style={{ display: "flex", flexDirection: "column",width:"50%" }}>
                            <p>Select ₹ / %</p>
                            <Select value={deductionValueState.unit} onChange={(e)=>{
                                setDeductionValueState((prevState)=>{
                                    return{
                                        ...prevState,
                                        unit:e
                                    }
                                })
                            }}  style={{ width: "50%", height: "50%" }} options={[
                                { label: "%", value: "p" },
                                { label: "₹", value: "r" },
                            ]} />


                        </div>

                        <div style={{ marginTop: 20,display:"flex",alignItems:"flex-end" }}>
                            <Button onClick={handleUpdateDeduction} loading={loading2} type='primary'>Submit</Button>
                        </div>
                    </div>
                    <hr />
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button loading={loading} onClick={handleClearDatabase} type='primary' style={{ backgroundColor: "red" }}>Clear database</Button>
                    </div>
                    <hr />
                </div>
            </div>
        </div>
    )
}

export default ManageAccount;