import { Button, DatePicker, Space, Select, InputNumber, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { RouteStrings, BackendRootStrings } from '../Routes';
import axios, { AxiosError } from 'axios';
import { RegexStrings } from '../../resources/Regex';
import dayjs from 'dayjs';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import { TextField } from '@material-ui/core';
import SuccessPage from '../CreateAccount/SuccessPage';

const AddLoanProduct = () => {

    const location = useLocation();

    interface submitValueInterface {
        name: String,
        rate: String,
        type: String,
        max_term: String,
        term_period: String,
        late_payment_penalties: String,
        status: String,
        description: String,
    }

    interface NewObject {
        label: string;
        value: any;
    }
    interface OriginalObject {
        [key: string]: any;
    }

    const nameRegex = RegexStrings.nameRegex;
    const emptyRegex = RegexStrings.emptyRegex;

    const [prevName, setPrevName] = useState("");

    const [submitData, setSubmitData] = useState<submitValueInterface>({
        name: '',
        rate: '',
        type: 'Fixed_Rate',
        max_term: '12',
        term_period: 'Yearly',
        late_payment_penalties: '0',
        status: '',
        description: '',
    });
    const [borrowerData, setBorrowerData] = useState<NewObject[]>()

    const [renderKey, setRenderKey] = useState(Math.random());

    const [formSuccess, setFormSuccess] = useState(false);

    let navigate = useNavigate();
    const [errorList, setErrorList] = useState({ first_name: "", last_name: "" });

    // function generateAlphanumericId() {
    //     const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    //     let result = '';

    //     for (let i = 0; i < 6; i++) {
    //         const randomIndex = Math.floor(Math.random() * charset.length);
    //         result += charset[randomIndex];
    //     }

    //     return result;
    // }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const checkError = (myString: string, name: string) => {
        if (emptyRegex.test(myString)) return `${name.split('_').join(' ')} must contain atleast one letter`;
        else if (!nameRegex.test(myString)) return `${name.split('_').join(' ')} must not contain numericals or spaces`;
        return "";
    }


    const handleChange = (e: { target: { value: string, id: string } }, name: string) => {
        let val = (checkError(e.target.value, name));
        if (!val) {
            setErrorList({ first_name: "", last_name: "" });
            setSubmitData((prevState: submitValueInterface) => {
                return {
                    ...prevState,
                    [name]: e.target.value,
                };


            });
        }
        else {

            setErrorList((prevState: { first_name: string, last_name: string } | undefined) => {
                if (!prevState) {
                    return {
                        first_name: "",
                        last_name: "",
                    };
                }
                else if (name === "first_name") {
                    return {
                        ...prevState,
                        first_name: val,
                        last_name: prevState.last_name,
                    };
                }
                else if (name === "last_name") {
                    return {
                        ...prevState,
                        first_name: prevState.first_name,
                        last_name: val,
                    };
                }
                return prevState;

            });
        }

    }

    const onSelectChange = (value: string, field: string) => {

        setSubmitData((prevState: submitValueInterface) => {
            return {
                ...prevState,
                [field]: value
            };
        })


    };

    const handleTextChange = (e: any, field: string) => {
        setSubmitData((prevState: submitValueInterface) => {
            return {
                ...prevState,
                [field]: e?.target?.value ?? e
            };
        })
    };
    const handleSubmit = () => {
        if (location.state.currentItem === undefined) {
            if(submitData.name===""){
                message.error("Name is required");
                return false;
            }
            if(submitData.rate===""){
                message.error("Interest Rate is required");
                return false;
            }
            if(parseFloat(submitData.rate.toString())<=0){
                message.error("Interest Rate must be greater than 0");
                return false;
            }
            if(submitData.status===""){
                message.error("Status is required");
                return false;
            }
            
            axios.post(`${BackendRootStrings.apiHome}/createLoanProduct`, submitData)
                .then(() => {
                    setTimeout(() => {
                        setFormSuccess(false);
                        navigate(RouteStrings.AllLoanProduct, {
                            state: {
                                path: 'AllLoanProduct',
                            }
                        });
                    }, 2000);
                    setFormSuccess(true);
                })
                .catch((error: AxiosError) => {
                    console.error('Error:', error.message);
                });
        }
        else {
            axios.put(`${BackendRootStrings.apiHome}/updateLoanProduct`, { submitData, prevName })
                .then(() => {
                    setTimeout(() => {
                        setFormSuccess(false);
                        navigate(RouteStrings.AllLoanProduct, {
                            state: {
                                path: 'AllLoanProduct',
                            }
                        });
                    }, 2000);
                    setFormSuccess(true);
                })
                .catch((error: AxiosError) => {
                    console.error('Error:', error.message);
                });
        }
    }

    useEffect(() => {
        axios.get(`${BackendRootStrings.apiHome}/getAllDepositData`)
            .then(({ data }) => {
                const { message } = data;
                const newArray: NewObject[] = message.map((obj: OriginalObject) => ({
                    label: `${obj.NAME} - ${obj.TNo}`,
                    value: `${obj.NAME}-${obj.TNo}`
                }));
                setBorrowerData(newArray);
                setRenderKey(Math.random());
            })
    }, [])

    useEffect(() => {
        console.log(location.state.currentItem);
        if (location.state.currentItem !== undefined) {
            setSubmitData((prevState) => {
                return {
                    ...prevState,
                    name: location.state.currentItem[0].name,
                    rate: location.state.currentItem[0].rate,
                    late_payment_penalties: location.state.currentItem[0].late_payment_penalties,
                    status: location.state.currentItem[0].status,
                    description: location.state.currentItem[0].description
                }
            })
            setPrevName(location.state.currentItem[0].name);
        }
    }, [])



    return (
        <div style={{ width: "100%", height: 800, display: "flex", justifyContent: "center", alignItems: "center",flexDirection:"column" }}>
            <div style={{ width: "80%", display: "flex", justifyContent: "center", marginTop: 10 }}>
                    <h2 style={{ color: "rgb(4 27 193)", marginBottom: 75 }}>{(location.state.currentItem !== undefined) ? "Update LoanProduct" : "Create LoanProduct"}</h2>
                </div>
            {(!formSuccess) ? <div style={{ width: "50%", height: "80%", border: "1px solid" }}>
                
                <div key={renderKey} style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "center", height: "90%", flexWrap: "wrap" }}>

                    <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-evenly", marginTop: 25 }}>
                        <div style={{ width: "80%", display: "flex", flexDirection: "column" }}>
                            <span><span style={{ color: 'red' }}>*</span> Name</span>
                            <TextField
                                error={errorList.last_name === "" ? false : true}
                                id="outlined-error-helper-text"
                                helperText={errorList.last_name}
                                variant="outlined"
                                value={submitData.name}
                                onChange={(e) => { handleTextChange(e, "name") }}
                                style={{ width: "100%" }}
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "80%", justifyContent: "space-between", marginTop: 25 }}>
                        <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%" }}>
                            <span><span style={{ color: 'red' }}>*</span> Interest Rate (%)</span>
                            <InputNumber value={parseFloat(submitData.rate?.toString())} style={{ width: "100%", height: "100%" }} onChange={(e) => { handleTextChange(e, "rate") }} min={0} defaultValue={0} />

                        </div>
                        {/* <div style={{ display: "flex", justifyContent: "center", width: "53%", height: "100%", flexDirection: "column" }}>
                            <span><span style={{ color: 'red' }}>*</span> Interest Type</span>
                            <Select
                                showSearch
                                placeholder="Interest Type"
                                optionFilterProp="children"
                                onChange={(e) => onSelectChange(e, 'type')}
                                filterOption={filterOption}
                                style={{ width: "100%", height: "100%" }}
                                options={[
                                    {
                                        value: 'Flat_Rate',
                                        label: 'Flat Rate',
                                    },
                                    {
                                        value: 'Fixed_Rate',
                                        label: 'Fixed Rate',
                                    },
                                    // {
                                    //     value: 'Mortgage_amortization',
                                    //     label: 'Mortgage amortization',
                                    // },
                                    // {
                                    //     value: 'Reducing_Amount',
                                    //     label: 'Reducing Amount',
                                    // },
                                    // {
                                    //     value: 'one-time_payment',
                                    //     label: 'One time payment',
                                    // },
                                ]}
                            />
                        </div> */}
                    </div>
                    {/* <div style={{ display: "flex", flexDirection: "row", width: "80%", justifyContent: "space-between", marginTop: 25 }}> */}
                    {/* <div style={{ display: "flex", flexDirection: "column", width: "34%", height: "100%" }}> */}
                    {/* <span><span style={{ color: 'red' }}>*</span> Max Term</span> */}
                    {/* <InputNumber style={{ width: "100%", height: "100%" }} onChange={(e) => { handleTextChange(e, "max_term") }} min={0} defaultValue={0} /> */}
                    {/* <Select
                                showSearch
                                placeholder="Term Period"
                                optionFilterProp="children"
                                onChange={(e) => onSelectChange(e, 'max_term')}
                                filterOption={filterOption}
                                style={{ width: "100%", height: "100%" }}
                                options={[
                                    // {
                                    //     value: 'Day',
                                    //     label: 'Day',
                                    // },
                                    // {
                                    //     value: 'Week',
                                    //     label: 'Week',
                                    // },
                                    {
                                        value: '6',
                                        label: '6 Months',
                                    },
                                    {
                                        value: '12',
                                        label: '12 Months',
                                    },
                                ]}
                            /> */}
                    {/* </div> */}
                    {/* <div style={{ display: "flex", justifyContent: "center", width: "53%", height: "100%", flexDirection: "column" }}>
                            <span><span style={{ color: 'red' }}>*</span> Term Period</span>
                            <Select
                                showSearch
                                placeholder="Term Period"
                                optionFilterProp="children"
                                onChange={(e) => onSelectChange(e, 'term_period')}
                                filterOption={filterOption}
                                style={{ width: "100%", height: "100%" }}
                                options={[
                                    // {
                                    //     value: 'Day',
                                    //     label: 'Day',
                                    // },
                                    // {
                                    //     value: 'Week',
                                    //     label: 'Week',
                                    // },
                                    {
                                        value: 'Monthly',
                                        label: 'Monthly',
                                    },
                                    {
                                        value: 'Yearly',
                                        label: 'Yearly',
                                    },
                                ]}
                            />
                        </div> */}
                    {/* </div> */}
                    <div style={{ display: "flex", flexDirection: "row", width: "80%", justifyContent: "space-between", marginTop: 25 }}>
                        <div style={{ display: "flex", flexDirection: "column", width: "34%", height: "100%" }}>
                            <span> Late Payment Penalties ₹ </span>
                            <InputNumber value={parseFloat(submitData.late_payment_penalties?.toString())} style={{ width: "100%", height: "100%" }} min={0} defaultValue={0} onChange={(e) => { handleTextChange(e, "late_payment_penalties") }} />
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", width: "53%", height: "100%", flexDirection: "column" }}>
                            <span><span style={{ color: 'red' }}>*</span> Status</span>
                            <Select
                                showSearch
                                value={submitData.status?.toString()}
                                placeholder="Status"
                                optionFilterProp="children"
                                onChange={(e) => onSelectChange(e, 'status')}
                                filterOption={filterOption}
                                style={{ width: "100%", height: "100%" }}
                                options={[
                                    {
                                        value: 'Activate',
                                        label: 'Activate',
                                    },
                                    {
                                        value: 'DeActivate',
                                        label: 'DeActivate',
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-evenly", marginTop: 25 }}>
                        <div style={{ width: "80%", display: "flex", flexDirection: "column" }}>
                            <span>Description</span>
                            <TextField
                                error={errorList.last_name === "" ? false : true}
                                id="outlined-error-helper-text"
                                value={submitData.description}
                                helperText={errorList.last_name}
                                variant="outlined"
                                onChange={(e) => { handleTextChange(e, "description") }}
                                style={{ width: "100%" }}
                            />
                        </div>
                    </div>


                </div>
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "50%", display: "flex", justifyContent: "space-around" }}>
                        <Button type="primary" danger onClick={() => { navigate(RouteStrings.HomePage) }}>
                            Cancel
                        </Button>
                        <Button type="primary" size={'middle'} onClick={handleSubmit}>
                            {(location.state.currentItem === undefined) ? "Submit" : "Update"}
                        </Button>
                    </div>

                </div>
            </div> : <SuccessPage />}
        </div>
    )
}

export default AddLoanProduct