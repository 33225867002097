import React, { useEffect, useState } from 'react';
import { UserOutlined, BankOutlined, MailOutlined,StarOutlined} from '@ant-design/icons';
import { Button, Input, Select, Upload } from 'antd';
import { ColorCode } from '../../resources/ColorCodes';
import { RegexStrings } from '../../resources/Regex';
import axios from 'axios';
import { BackendRootStrings } from '../Routes';
import SuccessPage from './SuccessPage';

const Register = () => {

    interface dataInterface {
        First_Name: string
        Last_Name: string
        User_Type: String,
        Password: String,
        Email:String
    }

    const [renderTableKey, setRenderTableKey] = useState(Math.random());

    const [formSuccess, setFormSuccess] = useState(false);

    const [errorList, setErrorList] = useState<dataInterface>({ First_Name: "", Last_Name: "", User_Type: "", Password: "",Email:"" });

    const nameRegex = RegexStrings.nameRegex;
    const emptyRegex = RegexStrings.emptyRegex;
    const phoneNumberRegex = RegexStrings.phoneNumberRegex;




    const [submitData, setSubmitData] = useState<dataInterface>({ First_Name: "", Last_Name: "", User_Type: "", Password: "", Email: "" });


    const checkError = (myString: string, name: string) => {
        if (name === "First_Name") { if (emptyRegex.test(myString)) return `${name.split('_').join(' ')} must contain atleast one letter`; }
        if (name === "First_Name") { if (!nameRegex.test(myString)) return `${name.split('_').join(' ')} must not contain numericals or spaces`; }
        if (name === "phone_no") { if (!phoneNumberRegex.test(myString)) return `phone no must be equal to 10 and should contain only numbers` };
        return "";
    }

    const onChange = (value: string) => {
        setSubmitData((prevState: dataInterface) => {
            return {
                ...prevState,
                User_Type: value
            }
        })
        // console.log(`selected ${value}`);
    };

    const onSearch = (value: string) => {
        // console.log('search:', value);
    };

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    const handleSubmit = () => {
        let canUserSubmit = true;

        // Object.keys(submitData).forEach((key) => {

        //     const typedKey = key as keyof dataInterface;
        //     // const error = checkError(submitData[typedKey], typedKey);
        //     if (errorList[typedKey] || !submitData[typedKey]) {
        //         // console.log(errorList[typedKey], submitData[typedKey], typedKey)
        //         canUserSubmit = false;
        //     }
        // });
        if (submitData.First_Name !== "" && submitData.Last_Name !== "" && submitData.Email !== "" && submitData.User_Type !== "" && submitData.Password !== "") {

            axios.post(`${BackendRootStrings.apiHome}/createCounter`, submitData)
                .then(({ data }) => {
                    setTimeout(() => {
                        setFormSuccess(false);
                        setRenderTableKey(Math.random());
                        setSubmitData((prevState) => {
                            return {
                                ...prevState,
                                First_Name: "",
                                Last_Name: "",
                                Email: "",
                                User_Type: "",
                                Password: ""
                            }

                        })
                    }, 2000);
                    if (data.message === "Limit Exceeded") {
                        window.alert("Your Limit has been Exceeded");
                    } else {
                        setFormSuccess(true);
                    }

                    setRenderTableKey(Math.random());

                })
                .catch((e) => {

                })
        }


    }

    const handleDataChange = (e: { target: { value: string, id: string } }, name: string) => {

        let val = (checkError(e.target.value, name));
        if (!val) {
            setErrorList((prevState: dataInterface) => {
                return {
                    ...prevState,
                    [name]: '',
                };
            });

            setSubmitData((prevState: dataInterface) => {
                if (prevState === undefined) {
                    return {
                        First_Name: 'DefaultFirstName',
                        Last_Name: 'DefaultLastName',
                        User_Type: 'DefaultImage',
                        Password: 'DefaultTNo',
                        Email: "DefaultEmail"
                    };
                }

                return {
                    ...prevState,
                    [name]: e.target.value,
                };


            });
        }
        else {
            setErrorList((prevState: dataInterface) => {
                if (!prevState) {
                    return {
                        First_Name: "",
                        Last_Name: "",
                        User_Type: "",
                        Password: "",
                        Email: "",
                    };
                }

                return {
                    ...prevState,
                    [name]: val,
                };


            });
        }

    }



    return (

        <div key={renderTableKey} style={{ display: "flex", height: 900, width: "100%", justifyContent: "center" }}>
            {(!formSuccess) ? <div style={{ display: "flex", width: "55%", height: "75%", border: "1px solid", justifyContent: "center", alignItems: "center", backgroundImage: "linear-gradient(0deg, rgb(4 27 193), rgb(6 235 235))" }}>
                <div style={{ height: "80%", width: "63%", border: "1px solid", backgroundColor: "white", boxShadow: "0px 0px 7px 2px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ width: "80%", display: "flex", justifyContent: "center", marginTop: 10 }}>
                        <h2 style={{ color: "rgb(4 27 193)", marginBottom: 75 }}>Create Counter</h2>
                    </div>
                    <div style={{ width: "80%" }}>
                        <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>

                            <Input size="large" placeholder="First Name" prefix={<><span style={{ color: 'red' }}>*</span><UserOutlined /></>} onChange={(e) => handleDataChange(e, "First_Name")} />
                            <span style={{ display: (errorList.First_Name ? "block" : "none"), color: "red" }}>{errorList.First_Name}</span>
                        </div>

                        <div style={{ width: "100%", marginTop: 25 }}>
                            <Input size="large" placeholder="Last Name" prefix={<><span style={{ color: 'red' }}>*</span><UserOutlined /></>} onChange={(e) => handleDataChange(e, "Last_Name")} />
                            <span style={{ display: (errorList.Last_Name ? "block" : "none"), color: "red" }}>{errorList.Last_Name}</span>
                        </div>
                        <div style={{ width: "100%", marginTop: 25 }}>
                            <Input size="large" placeholder="Email" prefix={<><span style={{ color: 'red' }}>*</span><MailOutlined /></>} onChange={(e) => handleDataChange(e, "Email")} />
                            <span style={{ display: (errorList.Email ? "block" : "none"), color: "red" }}>{errorList.Last_Name}</span>
                        </div>
                        <div style={{ width: "100%", marginTop: 25 }}>
                            <Select
                                showSearch
                                placeholder="Select User Type"
                                optionFilterProp="children"
                                onChange={onChange}
                                onSearch={onSearch}
                                filterOption={filterOption}
                                style={{ width: "100%", height: "100%" }}
                                suffixIcon={<span style={{ color: 'red' }}>*</span>}
                                options={[
                                    {
                                        value: 'Admin',
                                        label: 'Admin',
                                    },
                                    {
                                        value: 'Counter',
                                        label: 'Counter',
                                    },
                                ]}
                            />
                            <span style={{ display: (errorList.Password ? "block" : "none"), color: "red" }}>{errorList.Password}</span>
                        </div>
                        <div style={{ width: "100%", marginTop: 25 }}>
                            <Input size="large" placeholder="Enter Password" prefix={<><span style={{ color: 'red' }}>*</span><BankOutlined /></>} onChange={(e) => handleDataChange(e, "Password")} />
                            <span style={{ display: (errorList.User_Type ? "block" : "none"), color: "red" }}>{errorList.User_Type}</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", marginTop: 25 }}>

                            <div>
                                <Button style={{ backgroundColor: `${ColorCode.GreenCode}` }} onClick={handleSubmit} type="primary">Register</Button>
                            </div>
                        </div>
                    </div>


                </div>
            </div> : <SuccessPage />}
        </div>
    )
}

export default Register