import React from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import LoanDetailsTab from './LoanViewComponents/LoanDetailsTab';
import Repayments from './LoanViewComponents/Repayments';
import RepaymentsSchedule from './LoanViewComponents/RepaymentsSchedule';

const onChange = (key: string) => {
    console.log(key);
};

const items: TabsProps['items'] = [
    {
        key: '1',
        label: 'Loan Details',
        children: <LoanDetailsTab/>,
    },
    // {
    //     key: '2',
    //     label: 'Repayments Schedule',
    //     children: <RepaymentsSchedule/>,
    // },
    {
        key: '3',
        label: 'Repayments',
        children: <Repayments/>,
    },
];

const App: React.FC = () => {

    return (
        <div style={{ height: 900, width: "100%",display:"flex",justifyContent:"center",alignItems:"center" }}>
            <div style={{width:"90%",height:"90%"}}>
                <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            </div>

        </div>
    )

}

export default App;