import React, { useEffect, useState } from 'react';
import { Space, Table, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setTotalCollection } from '../../redux/actions/ProductActions';
import { BackendRootStrings } from '../Routes';

const { Column, ColumnGroup } = Table;

interface DataType {
  key: React.Key;
  "SlNo": String,
  "COLLECTER": Number,
  "COLLECTION-SEP": Number,
  "DISBURSE-SEP": Number,
  "CIH-SEP": Number,

  "COLLECTION-OCT": Number,
  "DISBURSE-OCT": Number,
  "CIH-OCT": Number,

  "COLLECTION-NOV": Number,
  "DISBURSE-NOV": Number,
  "CIH-NOV": Number,

  "COLLECTION-DEC": Number,
  "DISBURSE-DEC": Number,
  "CIH-DEC": Number,

  "COLLECTION-JAN": Number,
  "DISBURSE-JAN": Number,
  "CIH-JAN": Number,

  "COLLECTION-FEB": Number,
  "DISBURSE-FEB": Number,
  "CIH-FEB": Number,

  "COLLECTION-MAR": Number,
  "DISBURSE-MAR": Number,
  "CIH-MAR": Number,

  "COLLECTION-APR": Number,
  "DISBURSE-APR": Number,
  "CIH-APR": Number,

  "COLLECTION-MAY": Number,
  "DISBURSE-MAY": Number,
  "CIH-MAY": Number,

  "COLLECTION-JUNE": Number,
  "DISBURSE-JUNE": Number,
  "CIH-JUNE": Number,

  "COLLECTION-JUL": Number,
  "DISBURSE-JUL": Number,
  "CIH-JUL": Number,

  "COLLECTION-AUG": Number,
  "DISBURSE-AUG": Number,
  "CIH-AUG": Number,
  "TOTAL": Number
}

let data: DataType[] = [];

const App: React.FC = () => {
  //Redux part
  const products: { allProducts: { totalCollectionsArray: DataType[], isCounterSelected: Boolean } } = useSelector((state: { allProducts: { totalCollectionsArray: DataType[], isCounterSelected: Boolean } }) => state);

  const dispatch = useDispatch();

  const [renderTableKey, setRenderTableKey] = useState(Math.random());

  const com = (a: DataType, b: DataType) => {
    return a.SlNo > b.SlNo?1:0;
  }

  useEffect(() => {
    let temp = products.allProducts.totalCollectionsArray;
    temp.sort(com);
   
    data = [];


    temp.map((obj, ind, arr) => {
      // const monthsToSum = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

      data.push({
        key: ind,
        "SlNo": obj['SlNo'],
        "COLLECTER": obj['COLLECTER'] !== null ? obj['COLLECTER'] : 0,
        "COLLECTION-SEP": obj['COLLECTION-SEP'] !== null ? obj['COLLECTION-SEP'] : 0,
        "DISBURSE-SEP": obj['DISBURSE-SEP'] !== null ? obj['DISBURSE-SEP'] : 0,
        "CIH-SEP": obj['CIH-SEP'] !== null ? obj['CIH-SEP'] : 0,
      
        "COLLECTION-OCT": obj['COLLECTION-OCT'] !== null ? obj['COLLECTION-OCT'] : 0,
        "DISBURSE-OCT": obj['DISBURSE-OCT'] !== null ? obj['DISBURSE-OCT'] : 0,
        "CIH-OCT": obj['CIH-OCT'] !== null ? obj['CIH-OCT'] : 0,
      
        "COLLECTION-NOV": obj['COLLECTION-NOV'] !== null ? obj['COLLECTION-NOV'] : 0,
        "DISBURSE-NOV": obj['DISBURSE-NOV'] !== null ? obj['DISBURSE-NOV'] : 0,
        "CIH-NOV": obj['CIH-NOV'] !== null ? obj['CIH-NOV'] : 0,
      
        "COLLECTION-DEC": obj['COLLECTION-DEC'] !== null ? obj['COLLECTION-DEC'] : 0,
        "DISBURSE-DEC": obj['DISBURSE-DEC'] !== null ? obj['DISBURSE-DEC'] : 0,
        "CIH-DEC": obj['CIH-DEC'] !== null ? obj['CIH-DEC'] : 0,
      
        "COLLECTION-JAN": obj['COLLECTION-JAN'] !== null ? obj['COLLECTION-JAN'] : 0,
        "DISBURSE-JAN": obj['DISBURSE-JAN'] !== null ? obj['DISBURSE-JAN'] : 0,
        "CIH-JAN": obj['CIH-JAN'] !== null ? obj['CIH-JAN'] : 0,
      
        "COLLECTION-FEB": obj['COLLECTION-FEB'] !== null ? obj['COLLECTION-FEB'] : 0,
        "DISBURSE-FEB": obj['DISBURSE-FEB'] !== null ? obj['DISBURSE-FEB'] : 0,
        "CIH-FEB": obj['CIH-FEB'] !== null ? obj['CIH-FEB'] : 0,
      
        "COLLECTION-MAR": obj['COLLECTION-MAR'] !== null ? obj['COLLECTION-MAR'] : 0,
        "DISBURSE-MAR": obj['DISBURSE-MAR'] !== null ? obj['DISBURSE-MAR'] : 0,
        "CIH-MAR": obj['CIH-MAR'] !== null ? obj['CIH-MAR'] : 0,
      
        "COLLECTION-APR": obj['COLLECTION-APR'] !== null ? obj['COLLECTION-APR'] : 0,
        "DISBURSE-APR": obj['DISBURSE-APR'] !== null ? obj['DISBURSE-APR'] : 0,
        "CIH-APR": obj['CIH-APR'] !== null ? obj['CIH-APR'] : 0,
      
        "COLLECTION-MAY": obj['COLLECTION-MAY'] !== null ? obj['COLLECTION-MAY'] : 0,
        "DISBURSE-MAY": obj['DISBURSE-MAY'] !== null ? obj['DISBURSE-MAY'] : 0,
        "CIH-MAY": obj['CIH-MAY'] !== null ? obj['CIH-MAY'] : 0,
      
        "COLLECTION-JUNE": obj['COLLECTION-JUNE'] !== null ? obj['COLLECTION-JUNE'] : 0,
        "DISBURSE-JUNE": obj['DISBURSE-JUNE'] !== null ? obj['DISBURSE-JUNE'] : 0,
        "CIH-JUNE": obj['CIH-JUNE'] !== null ? obj['CIH-JUNE'] : 0,
      
        "COLLECTION-JUL": obj['COLLECTION-JUL'] !== null ? obj['COLLECTION-JUL'] : 0,
        "DISBURSE-JUL": obj['DISBURSE-JUL'] !== null ? obj['DISBURSE-JUL'] : 0,
        "CIH-JUL": obj['CIH-JUL'] !== null ? obj['CIH-JUL'] : 0,
      
        "COLLECTION-AUG": obj['COLLECTION-AUG'] !== null ? obj['COLLECTION-AUG'] : 0,
        "DISBURSE-AUG": obj['DISBURSE-AUG'] !== null ? obj['DISBURSE-AUG'] : 0,
        "CIH-AUG": obj['CIH-AUG'] !== null ? obj['CIH-AUG'] : 0,
        "TOTAL": obj['TOTAL'] !== null ? obj['TOTAL'] : 0,
      });

    })

    setRenderTableKey(Math.random());
    


  }, [products.allProducts.totalCollectionsArray])


  useEffect(() => {
    const handleAllCounterCollection = async () => {
      const { data } = await axios.get(`${BackendRootStrings.apiHome}/accountReport`);
      dispatch(setTotalCollection(data.message));
     
    }

    handleAllCounterCollection();
  }, [])
  

  return (
    <Table key={renderTableKey} dataSource={data} style={{overflow:"hidden",overflowX:"visible"}}>
      <Column title="NAME" dataIndex="SlNo" key="SlNo" />
      <Column title="COLLECTER" dataIndex="COLLECTER" key="COLLECTER" />
      <ColumnGroup title="JANUARY">
        <Column title="COLLECTION" dataIndex="COLLECTION-JAN" key="COLLECTION-JAN" />
        <Column title="DISBURSE" dataIndex="DISBURSE-JAN" key="DISBURSE-JAN" />
        <Column title="CIH" dataIndex="CIH-JAN" key="CIH-JAN" />
      </ColumnGroup>
      <ColumnGroup title="FEBRUARY">
        <Column title="COLLECTION" dataIndex="COLLECTION-FEB" key="COLLECTION-FEB" />
        <Column title="DISBURSE" dataIndex="DISBURSE-FEB" key="DISBURSE-FEB" />
        <Column title="CIH" dataIndex="CIH-FEB" key="CIH-FEB" />
      </ColumnGroup>
      <ColumnGroup title="MARCH">
        <Column title="COLLECTION" dataIndex="COLLECTION-MAR" key="COLLECTION-MAR" />
        <Column title="DISBURSE" dataIndex="DISBURSE-MAR" key="DISBURSE-MAR" />
        <Column title="CIH" dataIndex="CIH-MAR" key="CIH-MAR" />
      </ColumnGroup>
      <ColumnGroup title="APRIL">
        <Column title="COLLECTION" dataIndex="COLLECTION-APR" key="COLLECTION-APR" />
        <Column title="DISBURSE" dataIndex="DISBURSE-APR" key="DISBURSE-APR" />
        <Column title="CIH" dataIndex="CIH-APR" key="CIH-APR" />
      </ColumnGroup>
      <ColumnGroup title="MAY">
        <Column title="COLLECTION" dataIndex="COLLECTION-MAY" key="COLLECTION-MAY" />
        <Column title="DISBURSE" dataIndex="DISBURSE-MAY" key="DISBURSE-MAY" />
        <Column title="CIH" dataIndex="CIH-MAY" key="CIH-MAY" />
      </ColumnGroup>
      <ColumnGroup title="JUNE">
        <Column title="COLLECTION" dataIndex="COLLECTION-JUNE" key="COLLECTION-JUNE" />
        <Column title="DISBURSE" dataIndex="DISBURSE-JUNE" key="DISBURSE-JUNE" />
        <Column title="CIH" dataIndex="CIH-JUNE" key="CIH-JUNE" />
      </ColumnGroup>
      <ColumnGroup title="JULY">
        <Column title="COLLECTION" dataIndex="COLLECTION-JUL" key="COLLECTION-JUL" />
        <Column title="DISBURSE" dataIndex="DISBURSE-JUL" key="DISBURSE-JUL" />
        <Column title="CIH" dataIndex="CIH-JUL" key="CIH-JUL" />
      </ColumnGroup>
      <ColumnGroup title="AUGUST">
        <Column title="COLLECTION" dataIndex="COLLECTION-AUG" key="COLLECTION-AUG" />
        <Column title="DISBURSE" dataIndex="DISBURSE-AUG" key="DISBURSE-AUG" />
        <Column title="CIH" dataIndex="CIH-AUG" key="CIH-AUG" />
      </ColumnGroup>
      <ColumnGroup title="SEPTEMBER">
        <Column title="COLLECTION" dataIndex="COLLECTION-SEP" key="COLLECTION-SEP" />
        <Column title="DISBURSE" dataIndex="DISBURSE-SEP" key="DISBURSE-SEP" />
        <Column title="CIH" dataIndex="CIH-SEP" key="CIH-SEP" />
      </ColumnGroup>
      <ColumnGroup title="OCTOBER">
        <Column title="COLLECTION" dataIndex="COLLECTION-OCT" key="COLLECTION-OCT" />
        <Column title="DISBURSE" dataIndex="DISBURSE-OCT" key="DISBURSE-OCT" />
        <Column title="CIH" dataIndex="CIH-OCT" key="CIH-OCT" />
      </ColumnGroup>
      <ColumnGroup title="NOVEMBER">
        <Column title="COLLECTION" dataIndex="COLLECTION-NOV" key="COLLECTION-NOV" />
        <Column title="DISBURSE" dataIndex="DISBURSE-NOV" key="DISBURSE-NOV" />
        <Column title="CIH" dataIndex="CIH-NOV" key="CIH-NOV" />
      </ColumnGroup>
      <ColumnGroup title="DECEMBER">
        <Column title="COLLECTION" dataIndex="COLLECTION-DEC" key="COLLECTION-DEC" />
        <Column title="DISBURSE" dataIndex="DISBURSE-DEC" key="DISBURSE-DEC" />
        <Column title="CIH" dataIndex="CIH-DEC" key="CIH-DEC" />
      </ColumnGroup>
      <Column title="TOTAL-CIH" dataIndex="TOTAL" key="TOTAL" />

    </Table>
  )
};

export default App;