import React, { useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Flex, Input, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { MenuProps } from 'antd';
import { Popconfirm } from 'antd';
import axios, { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { BackendRootStrings, RouteStrings } from '../Routes';
import { MenuInfo } from 'rc-menu/lib/interface';
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Cookies from 'universal-cookie';
import generatePDF, { Margin } from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';
import { CSVLink } from 'react-csv';

interface DataType {
    key: React.Key,
    Image: String,
    TNo: String,
    First_Name: String,
    Last_Name: String,
    tags: string[];
    SlNo: string,
    Phone_no: string
}




const App: React.FC = () => {

    const cookies = new Cookies();

    const userType = cookies.get('UserType');

    const [renderTableKey, setRenderTableKey] = useState(Math.random());

    const printRef = useRef<any>();
    let [isPdfTriggered, setIsPdfTriggered] = useState(false);
    const [isPrintTriggered, setIsPrintTriggered] = useState(false);

    const [renderKey, setRenderKey] = useState(Math.random());

    const [actionsHide, setActionsHide] = useState(false);

    const [searchText, setSearchText] = useState("");

    let [data, setData] = useState<DataType[]>([]);

    const [tempSearchDataArray, setTempSearchDataArray] = useState<DataType[]>([]);

    let navigate = useNavigate();

    const handleUserDataDelete = async (currentItem: any) => {
        await axios.post(`${BackendRootStrings.apiHome}/deleteUserInfo`, currentItem);
    }

    const confirmDelete = (currentItem: any) => {
        // Popconfirm({
        //     title: 'Confirm',
        //     icon: <ExclamationCircleOutlined />,
        //     okText: 'Yes',
        //     okType: 'danger',
        //     cancelText: 'No',
        //     onConfirm() {
        //         handleUserDataDelete(currentItem);
        //     },
        //     onCancel() {
        //         console.log('Delete operation canceled.');
        //     },
        // });
        const isConfirm = window.confirm("Are you sure to delete??");
        if (isConfirm) {
            handleUserDataDelete(currentItem);
            axios.get(`${BackendRootStrings.apiHome}/getAllUser`)
                .then((response: { data: { message: DataType[] } }) => {
                    const modifiedArray = response.data.message.reverse().map((item, index) => ({
                        ...item,
                        SlNo: (index + 1).toString() // Adding index property to each object
                    }));
                    setData(modifiedArray);
                    data = modifiedArray;
                    setRenderTableKey(Math.random());
                })
                .catch((error: AxiosError) => {
                    console.error('Error:', error.message);
                });
        }
    };

    const onMenuClick = (currentItem:DataType) => (info: MenuInfo) => {
        // const currentItem = data.filter((item, ind) => item.TNo == recordTNo);
        let currentItemArray=[];
        currentItemArray.push(currentItem);
        switch (info.key) {
            case '1':
                navigate(RouteStrings.EditUser, {
                    state: {
                        currentItem: currentItemArray,
                        path: 'EditUser'
                    }
                });
                break;
            case '2':
                confirmDelete(currentItemArray);
                // handleUserDataDelete(currentItem);
                break;
            case '3':
                navigate(RouteStrings.ViewUser, {
                    state: {
                        currentItem: currentItemArray,
                        path: 'ViewUser'
                    }
                });
                break;
            default:
                break;
        }
    };

    let [items, setItems] = useState<MenuProps['items']>([])
    // let items: MenuProps['items'] = [];

    useEffect(() => {
        if (!userType.includes("Counter")) {
            setItems([
                {
                    key: '1',
                    label: 'Edit',
                },
                {
                    key: '2',
                    label: 'Delete',
                },
                {
                    key: '3',
                    label: 'View',
                },
            ]);
            items = [
                {
                    key: '1',
                    label: 'Edit',
                },
                {
                    key: '2',
                    label: 'Delete',
                },
                {
                    key: '3',
                    label: 'View',
                },
            ];
        } else {
            setItems([
                {
                    key: '1',
                    label: 'Edit',
                },
                {
                    key: '3',
                    label: 'View',
                },
            ])
            items = [{
                key: '1',
                label: 'Edit',
            },
            {
                key: '3',
                label: 'View',
            },
            ];
        }
    }, [userType])

    // const menuProps = {
    //     items,
    //     onClick: onMenuClick,
    //   };

    let [columns, setColumns] = useState<ColumnsType<DataType>>([])
    // const columns123: ColumnsType<DataType> = [
    //     {
    //         title: 'SlNo',
    //         dataIndex: 'SlNo',
    //         key: 'SlNo'
    //     },
    //     {
    //         title: 'Image',
    //         dataIndex: 'Image',
    //         key: 'Image',
    //         render: (text) => <img style={{ height: 70, width: 70, borderRadius: 50 }} src={text} />,
    //     },
    //     {
    //         title: 'Account No',
    //         dataIndex: 'TNo',
    //         key: 'TNo',
    //     },
    //     {
    //         title: 'First Name',
    //         dataIndex: 'First_Name',
    //         key: 'First_Name',
    //     },
    //     {
    //         title: 'Last Name',
    //         dataIndex: 'Last_Name',
    //         key: 'Last_Name',
    //     },
    //     {
    //         title: 'Phone no',
    //         dataIndex: 'Phone_no',
    //         key: 'Phone_no',
    //     },
    //     {
    //         title: 'Action',
    //         key: 'action',
    //         render: (_, record) => (
    //             // <Space size="middle">
    //             //     <Flex className={record.TNo.toString()} align="flex-start" gap="small" vertical>
    //             //         <Dropdown.Button type="primary" menu={{ items,onClick: onMenuClick(record.TNo.toString())}}>Actions</Dropdown.Button>
    //             //     </Flex>
    //             // </Space>
    //             <Dropdown menu={{ items, onClick: onMenuClick(record.TNo.toString()) }}>
    //                 <Button type='primary'>
    //                     <Space>
    //                         Action
    //                         <DownOutlined />
    //                     </Space>
    //                 </Button>
    //             </Dropdown>

    //         ),
    //     },
    // ];


    useEffect(() => {
        if (isPrintTriggered || isPdfTriggered) {
            setColumns([
                {
                    title: 'SlNo',
                    dataIndex: 'SlNo',
                    key: 'SlNo'
                },
                {
                    title: 'Image',
                    dataIndex: 'Image',
                    key: 'Image',
                    render: (text) => <img style={{ height: 70, width: 70, borderRadius: 50 }} src={text} alt='image'/>,
                },
                {
                    title: 'Account No',
                    dataIndex: 'TNo',
                    key: 'TNo',
                },
                {
                    title: 'First Name',
                    dataIndex: 'First_Name',
                    key: 'First_Name',
                },
                {
                    title: 'Last Name',
                    dataIndex: 'Last_Name',
                    key: 'Last_Name',
                },
                {
                    title: 'Phone no',
                    dataIndex: 'Phone_no',
                    key: 'Phone_no',
                },
                
            ])
            setActionsHide(true);
        } else {
            setColumns([
                {
                    title: 'SlNo',
                    dataIndex: 'SlNo',
                    key: 'SlNo'
                },
                {
                    title: 'Image',
                    dataIndex: 'Image',
                    key: 'Image',
                    render: (text) => <img style={{ height: 70, width: 70, borderRadius: 50 }} src={text} alt='image'/>,
                },
                {
                    title: 'Account No',
                    dataIndex: 'TNo',
                    key: 'TNo',
                },
                {
                    title: 'First Name',
                    dataIndex: 'First_Name',
                    key: 'First_Name',
                },
                {
                    title: 'Last Name',
                    dataIndex: 'Last_Name',
                    key: 'Last_Name',
                },
                {
                    title: 'Phone no',
                    dataIndex: 'Phone_no',
                    key: 'Phone_no',
                },
                {
                    title: 'Action',
                    key: 'action',
                    render: (current, record) => (
                        // <Space size="middle">
                        //     <Flex className={record.TNo.toString()} align="flex-start" gap="small" vertical>
                        //         <Dropdown.Button type="primary" menu={{ items,onClick: onMenuClick(record.TNo.toString())}}>Actions</Dropdown.Button>
                        //     </Flex>
                        // </Space>
                        <Dropdown menu={{ items, onClick: onMenuClick(current) }}>
                            <Button type='primary'>
                                <Space>
                                    Action
                                    <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>

                    ),
                },
            ])
        }
        setRenderKey(Math.random());
    }, [isPrintTriggered, isPdfTriggered])


    useEffect(() => {
        axios.get(`${BackendRootStrings.apiHome}/getAllUser`)
            .then((response: { data: { message: DataType[] } }) => {

                const modifiedArray = response.data.message.reverse().map((item, index) => ({
                    ...item,
                    SlNo: (index + 1).toString() // Adding index property to each object
                }));
                setData(modifiedArray);
                setTempSearchDataArray(modifiedArray);
                setRenderTableKey(Math.random());
            })
            .catch((error: AxiosError) => {
                console.error('Error:', error.message);
            });


    }, [])

    //Search Part
    const handleSearch = (e: any) => {
        setSearchText(e?.target?.value);
    }

    useEffect(() => {

        if (searchText === null || searchText === undefined || searchText==='') {
            setTempSearchDataArray([]);
            setTempSearchDataArray(data);
        }
        else {

            setTempSearchDataArray((prevstate) => {

                let var1 = data.filter(
                    (info) => {
                        const searchTextAsNumber = parseInt(searchText);
                        return (info.First_Name?.toLowerCase().includes(searchText.toLowerCase()) || info.Last_Name?.toLowerCase().includes(searchText.toLowerCase()) || info.TNo.toString().includes(searchTextAsNumber.toString()))
                    }
                );
                return var1;
            })

            setRenderTableKey(Math.random());
        }
    }, [searchText])



    const options = {
        // default is `save`
        method: "save" as const,
        filename: `Account List.pdf`,
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        // resolution: Resolution.HIGH,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            format: 'A4',
            // default is 'portrait'
            orientation: "portrait" as const,
        },
        // canvas: {
        //    // default is 'image/jpeg' for better size performance
        //    mimeType: 'image/png',
        //    qualityRatio: 1
        // },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break, 
        // so use with caution.
        // overrides: {
        //    pdf: {
        //       compress: true
        //    },
        //    canvas: {
        //       useCORS: true
        //    }
        // },
    };

    const handlePDFPrint = () => {
        generatePDF(printRef, options);
    };


    const handlePrint = useReactToPrint({
        content: () => printRef?.current,
    });

    useEffect(() => {
        setRenderKey(Math.random());
        if (isPdfTriggered === true) {
            handlePDFPrint();
            setIsPdfTriggered(false);
            setActionsHide(false);
        }

    }, [actionsHide])

    useEffect(() => {
        setRenderKey(Math.random());
        if (actionsHide && isPrintTriggered && printRef?.current) {
            handlePrint();
            setIsPrintTriggered(false);
            setActionsHide(false);
        }
    }, [actionsHide])

    const headers = [
        {
            label: "SlNo", key: "SlNo"
        },
        {
            label: "Account No", key: "TNo"
        },
        {
            label: "First Name", key: "First_Name"
        },
        {
            label: "Last Name", key: "Last_Name"
        },
        {
            label: "Phone no", key: "Phone_no"
        },

    ]

    const csvLink = {
        filename: `Account List.csv`,
        headers: headers,
        data: tempSearchDataArray,
    }



    return (
        <>
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <h3>Account List</h3>
                </div>
                <div style={{ justifyContent: "flex-end" }}>
                    <div style={{ width: "100%", borderRadius: 5, display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                            <div style={{ width: "9%", display: "flex", justifyContent: "space-between", marginTop: 20, alignItems: "center" }}>
                                <CSVLink {...csvLink} style={{ display: "flex", textDecoration: "none", color: "black", backgroundColor: "white", height: 32, borderRadius: 5, justifyContent: "center", alignItems: "center" }}><Button style={{ backgroundColor: "gray", color: "white" }}>Excel</Button></CSVLink>
                                <Button style={{ backgroundColor: "red", color: "white" }} onClick={() => { setRenderKey(Math.random()); setIsPdfTriggered(true) }}>PDF</Button>
                                <Button style={{ backgroundColor: "green", color: "white" }} onClick={() => { setRenderKey(Math.random()); setIsPrintTriggered(true) }}>Print</Button>
                            </div>
                            <Input style={{ width: "30%" }} placeholder="Enter Account No or Name" onChange={handleSearch} />
                        </div>

                    </div>
                    <div key={renderKey} ref={printRef}>
                        <div style={{ display: "flex", width: "100%" }}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                <h3>HOSPITAL OFFICE FUND</h3>
                                <div style={{ width: 186, textAlign: "center" }}><h3>Account List</h3></div>
                            </div>

                        </div>
                        <Table key={renderTableKey} columns={columns} pagination={isPrintTriggered || isPdfTriggered ? false : { position: ['topLeft'] }} dataSource={tempSearchDataArray} />
                    </div>
                </div>

            </div>

        </>

    )
}

export default App;