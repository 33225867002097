import { Button, DatePicker, DatePickerProps, Input, message, Select, Table } from 'antd'
import React, { useEffect, useState, useRef } from 'react';
import { ColorCode } from '../../resources/ColorCodes';
import { BackendRootStrings } from '../Routes';
import axios from 'axios';
import SuccessPage from '../CreateAccount/SuccessPage';
import Cookies from 'universal-cookie';
import dayjs from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';
import { CSVLink } from 'react-csv';
import generatePDF, { Margin } from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    completedClass: {
        backgroundColor: `${ColorCode.GreenCode} !important`,
        color: `white !important`,
    },



}));



interface NewObject {
    label: string;
    value: any;
}

interface TransferTableDataInterface {
    SlNo: String,
    Date: String,
    CounterTransferredFrom: String,
    CounterTransferredTo: String,
    AmountTransferred: String,
}

interface LoanTableDataInterface {
    SlNo: String,
    LoanID: String,
    Borrower: String,
    Release_date: String,
    Applied_Amount: String,
    Status: String,
    UniqueLoanID: String
}





const Counter6Data = () => {
    const classes = useStyles();

    const cookies = new Cookies();

    const getRowClassName = (record: LoanTableDataInterface) => {
        return record.Status==="Completed" ? `${classes.completedClass}`:'' ;
    };
    const userType = cookies.get('UserType');

    //Redux part
    const products: { allProducts: { counterBalance: number } } = useSelector((state: { allProducts: { counterBalance: number } }) => state);


    const printRefTransfer = useRef<any>();
    const printRefLoan = useRef<any>();
    let [isTransferPdfTriggered, setIsTransferPdfTriggered] = useState(false);
    const [isTransferPrintTriggered, setIsTransferPrintTriggered] = useState(false);

    let [isLoanPdfTriggered, setIsLoanPdfTriggered] = useState(false);
    const [isLoanPrintTriggered, setIsLoanPrintTriggered] = useState(false);

    const [renderKey, setRenderKey] = useState(Math.random());


    let [transferTableData, setTransferTableData] = useState<TransferTableDataInterface[]>([]);
    let [loanTableData, setLoanTableData] = useState<LoanTableDataInterface[]>([]);

    const [formSuccess, setFormSuccess] = useState(false);

    const handleInitialData = async () => {
        await axios.get(`${BackendRootStrings.apiHome}/getCounterSixTransferData`)
            .then(({ data }) => {
                const { message } = data;

                setTransferTableData([]);
                transferTableData = [];

                message.map((val: TransferTableDataInterface, ind: number) => {
                    let dateParts = val.Date.split(" ");
                    setTransferTableData(prevState => [
                        ...prevState,
                        {
                            SlNo: (ind + 1).toString(),
                            Date: new Date(dateParts[1] + " " + dateParts[2] + ", " + dateParts[3]).toDateString(),
                            CounterTransferredFrom: val.CounterTransferredFrom,
                            CounterTransferredTo: val.CounterTransferredTo,
                            AmountTransferred: val.AmountTransferred,
                        }
                    ]);
                })

            })

        await axios.get(`${BackendRootStrings.apiHome}/getCounterSixLoanData`)
            .then(({ data }) => {
                const { message } = data;

                setLoanTableData([]);
                loanTableData = [];

                message.map((val: LoanTableDataInterface, ind: number) => {
                    setLoanTableData(prevState => [
                        ...prevState,
                        {
                            SlNo: (ind + 1).toString(),
                            LoanID: val.LoanID,
                            Borrower: val.Borrower,
                            Release_date: val.Release_date,
                            Applied_Amount: val.Applied_Amount,
                            Status: val.Status,
                            UniqueLoanID: val.UniqueLoanID
                        }
                    ]);
                })

            })
    }


    useEffect(() => {
        handleInitialData();
    }, [])













    const columnsTransfer = [
        {
            title: 'SlNo',
            dataIndex: 'SlNo',
            key: 'SlNo',
        },
        {
            title: 'Date',
            dataIndex: 'Date',
            key: 'Date',
        },
        {
            title: 'Counter Credited',
            dataIndex: 'CounterTransferredTo',
            key: 'CounterTransferredTo',
        },
        {
            title: 'Counter Debited',
            dataIndex: 'CounterTransferredFrom',
            key: 'CounterTransferredFrom',
        },
        {
            title: 'Amount Transferred',
            dataIndex: 'AmountTransferred',
            key: 'AmountTransferred',
        },
    ];

    const columnsLoan = [
        {
            title: 'SlNo',
            dataIndex: 'SlNo',
            key: 'SlNo',
        },
        {
            title: 'Account No',
            dataIndex: 'LoanID',
            key: 'LoanID',
        },
        {
            title: 'Borrower',
            dataIndex: 'Borrower',
            key: 'Borrower',
        },
        {
            title: 'UniqueLoanID',
            dataIndex: 'UniqueLoanID',
            key: 'UniqueLoanID',
        },
        {
            title: 'Approved Date',
            dataIndex: 'Release_date',
            key: 'Release_date',
        },
        {
            title: 'Approved Amount',
            dataIndex: 'Applied_Amount',
            key: 'Applied_Amount',
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            render:(val:any)=><span style={{fontWeight:(val==="Completed")?"bold":"normal"}}>{val}</span>
        },

    ];


    const optionsTransfer = {
        // default is `save`
        method: "save" as const,
        filename: `Transfer Statement Counter-6.pdf`,
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        // resolution: Resolution.HIGH,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            format: 'A4',
            // default is 'portrait'
            orientation: "portrait" as const,
        },
        // canvas: {
        //    // default is 'image/jpeg' for better size performance
        //    mimeType: 'image/png',
        //    qualityRatio: 1
        // },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break, 
        // so use with caution.
        // overrides: {
        //    pdf: {
        //       compress: true
        //    },
        //    canvas: {
        //       useCORS: true
        //    }
        // },
    };
    const optionsLoan = {
        // default is `save`
        method: "save" as const,
        filename: `Loan Statement Counter-6.pdf`,
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        // resolution: Resolution.HIGH,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.NONE,
            // default is 'A4'
            format: 'A4',
            // default is 'portrait'
            orientation: "portrait" as const,
        },
        // canvas: {
        //    // default is 'image/jpeg' for better size performance
        //    mimeType: 'image/png',
        //    qualityRatio: 1
        // },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break, 
        // so use with caution.
        // overrides: {
        //    pdf: {
        //       compress: true
        //    },
        //    canvas: {
        //       useCORS: true
        //    }
        // },
    };

    const handleTransferPDFPrint = () => {
        generatePDF(printRefTransfer, optionsTransfer);
    };
    const handleLoanPDFPrint = () => {
        generatePDF(printRefLoan, optionsLoan);
    };


    const handleTransferPrint = useReactToPrint({
        content: () => printRefTransfer.current,
    });
    const handleLoanPrint = useReactToPrint({
        content: () => printRefLoan.current,
    });

    useEffect(() => {
        setRenderKey(Math.random());
        if (isTransferPdfTriggered === true) {
            handleTransferPDFPrint();
            setIsTransferPdfTriggered(false);
        }
    }, [isTransferPdfTriggered])
    useEffect(() => {
        setRenderKey(Math.random());
        if (isLoanPdfTriggered === true) {
            handleLoanPDFPrint();
            setIsLoanPdfTriggered(false);
        }
    }, [isLoanPdfTriggered])

    useEffect(() => {
        setRenderKey(Math.random());
        if (isTransferPrintTriggered === true) {
            handleTransferPrint();
            setIsTransferPrintTriggered(false);
        }
    }, [isTransferPrintTriggered])

    useEffect(() => {
        setRenderKey(Math.random());
        if (isLoanPrintTriggered === true) {
            handleLoanPrint();
            setIsLoanPrintTriggered(false);
        }
    }, [isLoanPrintTriggered])

    const headersTransfer = [
        {
            label: "SlNo", key: "SlNo"
        },
        {
            label: "Date", key: "Date"
        },
        {
            label: "CounterTransferredFrom", key: "CounterTransferredFrom"
        },
        {
            label: "CounterTransferredTo", key: "CounterTransferredTo"
        },
        {
            label: "AmountTransferred", key: "AmountTransferred"
        },

    ]
    const headerLoan = [
        {
            label: "SlNo", key: "SlNo"
        },

        {
            label: "Account No", key: "LoanID"
        },
        {
            label: "Borrower", key: "Borrower"
        },
        {
            label: "UniqueLoanID", key: "UniqueLoanID"
        },
        {
            label: "Approved Date", key: "Release_date"
        },
        {
            label: "Approved Amount", key: "Applied_Amount"
        },
        {
            label: "Status", key: "Status"
        },

    ]

    const csvLinkTransfer = {
        filename: `Transfer Statement Counter-6.csv`,
        headers: headersTransfer,
        data: transferTableData,
    }
    const csvLinkLoan = {
        filename: `Loan Statement Counter-6.csv`,
        headers: headerLoan,
        data: loanTableData,
    }



    return (
        <>
            <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center" }}>
                {(!formSuccess) ? <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", height: "fit-content" }}>
                        <div style={{ width: "50%", height: "100%" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                                <div style={{ width: "9%", display: "flex", justifyContent: "space-between", marginTop: 20, alignItems: "center" }}>
                                    <CSVLink {...csvLinkTransfer} style={{ display: "flex", textDecoration: "none", color: "black", backgroundColor: "white", height: 32, borderRadius: 5, justifyContent: "center", alignItems: "center" }}><Button style={{ backgroundColor: "gray", color: "white" }}>Excel</Button></CSVLink>
                                    <Button style={{ backgroundColor: "red", color: "white" }} onClick={() => { setRenderKey(Math.random()); setIsTransferPdfTriggered(true) }}>PDF</Button>
                                    <Button style={{ backgroundColor: "green", color: "white" }} onClick={() => { setRenderKey(Math.random()); setIsTransferPrintTriggered(true) }}>Print</Button>
                                </div>
                                <div style={{ width: "30%" }}><p style={{ height: 30, width: "50%", textAlign: "center", fontWeight: "bold", paddingTop: 5 }}>Balance - {products.allProducts.counterBalance}</p></div>
                            </div>
                            <div ref={printRefTransfer}>
                                <div style={{ display: "flex", width: "100%" }}>
                                    <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                        <h3>HOSPITAL OFFICE FUND</h3>
                                        <div style={{ width: 186, textAlign: "center" }}><h3>Counter Transfer Statement Counter -6</h3></div>
                                    </div>



                                </div>
                                <Table pagination={isTransferPrintTriggered || isTransferPdfTriggered ? false : { position: ['topLeft'] }} dataSource={transferTableData} columns={columnsTransfer} />

                            </div>

                        </div>

                        <div style={{ width: "50%", height: "100%", borderLeftColor: "black", borderLeft: "2px solid" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                                <div style={{ width: "9%", display: "flex", justifyContent: "space-between", marginTop: 20, alignItems: "center" }}>
                                    <CSVLink {...csvLinkLoan} style={{ display: "flex", textDecoration: "none", color: "black", backgroundColor: "white", height: 32, borderRadius: 5, justifyContent: "center", alignItems: "center" }}><Button style={{ backgroundColor: "gray", color: "white" }}>Excel</Button></CSVLink>
                                    <Button style={{ backgroundColor: "red", color: "white" }} onClick={() => { setRenderKey(Math.random()); setIsLoanPdfTriggered(true) }}>PDF</Button>
                                    <Button style={{ backgroundColor: "green", color: "white" }} onClick={() => { setRenderKey(Math.random()); setIsLoanPrintTriggered(true) }}>Print</Button>
                                </div>
                            </div>
                            <div ref={printRefLoan}>
                                <div style={{ display: "flex", width: "100%" }}>
                                    <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                        <h3>HOSPITAL OFFICE FUND</h3>
                                        <div style={{ width: 186, textAlign: "center" }}><h3>Loan Disburse Statement Counter -6</h3></div>
                                    </div>



                                </div>
                                <Table rowClassName={getRowClassName} pagination={isLoanPrintTriggered || isLoanPdfTriggered ? false : { position: ['topLeft'] }} dataSource={loanTableData} columns={columnsLoan} />

                            </div>

                        </div>

                    </div>

                </div> : <SuccessPage />}

            </div>
        </>
    )
}

export default Counter6Data