import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Flex, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { MenuProps } from 'antd';
import axios, { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { BackendRootStrings, RouteStrings } from '../Routes';
import { MenuInfo } from 'rc-menu/lib/interface';
import { DownOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { ColorCode } from '../../resources/ColorCodes';
import Cookies from 'universal-cookie';

interface LoanProductDataType {
    name: String,
    rate: String,
    type: String,
    max_term: String,
    term_period: String,
}




const App: React.FC = () => {

    let dispatch = useDispatch();

    const [renderTableKey, setRenderTableKey] = useState(Math.random());

    let [data, setData] = useState<LoanProductDataType[]>([]);
    const cookies = new Cookies();

    let navigate = useNavigate();

    const [actionItems, setActionItems] = useState<MenuProps['items']>([]);

    const handleUserDataDelete = async (currentItem: any) => {

    }

    const [userType, setUserType] = useState('');

    const onMenuClick = (recordTNo: string) => (info: MenuInfo) => {
        const currentItem = data.filter((item, ind) => item.name === recordTNo);
        switch (info.key) {
            case '1':
                navigate(RouteStrings.AddLoanProduct, {
                    state: {
                        currentItem: currentItem,
                        path: 'AddLoanProduct',
                    }
                });
                break;
            case '2':
                handleUserDataDelete(currentItem);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        let usertype = cookies.get("UserType");
        if(usertype==="Admin"){
            setActionItems([
                {
                    key: '1',
                    label: 'Edit',
                },
                {
                    key: '2',
                    label: 'Delete',
                }]
            )
        }
        
    }, [])


    const columns: ColumnsType<LoanProductDataType> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Interest Rate (%)',
            dataIndex: 'rate',
            key: 'rate',
            render: (value) => <span>{value} %</span>
        },
        // {
        //     title: 'Interest Type',
        //     dataIndex: 'type',
        //     key: 'type',
        // },
        // {
        //     title: 'Max Term',
        //     dataIndex: 'max_term',
        //     key: 'max_term',
        // },
        // {
        //     title: 'Term Period',
        //     dataIndex: 'term_period',
        //     key: 'term_period',
        // },


        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                // <Space size="middle">
                //     <Flex className={record.TNo.toString()} align="flex-start" gap="small" vertical>
                //         <Dropdown.Button type="primary" menu={{ items,onClick: onMenuClick(record.TNo.toString())}}>Actions</Dropdown.Button>
                //     </Flex>
                // </Space>
                <Dropdown menu={{ items:actionItems, onClick: onMenuClick(record.name.toString()) }}>
                    <Button type='primary'>
                        <Space>
                            Action
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>

            ),
        },
    ];

    useEffect(() => {
        axios.get(`${BackendRootStrings.apiHome}/getAllLoanProduct`)
            .then((response: { data: { message: LoanProductDataType[] } }) => {

                console.log(response.data.message)
                setData(response.data.message);
                data = response.data.message;

                setRenderTableKey(Math.random());
            })
            .catch((error: AxiosError) => {
                console.error('Error:', error.message);
            });

    }, [])


    const handleAddLoanProduct = () => {
        navigate(RouteStrings.AddLoanProduct, {
            state: {
                path: 'AddLoanProduct',
            }
        });
    }

    useEffect(() => {
        let tempUserType = cookies.get("UserType");
        setUserType(tempUserType);
      }, [])


    return (
        <>
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}> <h3>Loan Products</h3></div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
               {userType==="Admin" && <Button style={{ backgroundColor: ColorCode.GreenCode, color: "white", fontWeight: "bold" }} onClick={handleAddLoanProduct}>Add New</Button>}
            </div>
            <Table key={renderTableKey} columns={columns} pagination={{ position: ['topLeft'] }} dataSource={data} />
        </>

    )
}

export default App;