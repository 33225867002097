import { Button, DatePicker, DatePickerProps, Input, message, Select, Table } from 'antd'
import React, { useEffect, useState, useRef } from 'react';
import { ColorCode } from '../../resources/ColorCodes';
import { BackendRootStrings } from '../Routes';
import axios from 'axios';
import SuccessPage from '../CreateAccount/SuccessPage';
import Cookies from 'universal-cookie';
import dayjs from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';
import { CSVLink } from 'react-csv';
import generatePDF, { Margin } from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';
import _ from 'lodash';

interface InputDataInterface {
    startDate: String,
    endDate: String,
    loanType: String,
    status: String,
    accountNo: String
}

interface NewObject {
    label: string;
    value: any;
}

interface StatementTableDataInterface {
    SlNo: string,
    Account_No: string,
    Borrower: string,
    LoanAmount: number,
    LoanID: string,
    LoanProduct: string,
    RepaymentAmount: string
    RepaymentCounter: string,
    RepaymentDate: string,
    RepaymentInterest: string,
    RepaymentLatePenalty: string,
    Status: string
}

interface OriginalObject {
    [key: string]: any;
}





const CounterLoanRepaymentReport = () => {

    const cookies = new Cookies();

    const userType = cookies.get('UserType');

    const printRef = useRef<any>();
    let [isPdfTriggered, setIsPdfTriggered] = useState(false);
    const [isPrintTriggered, setIsPrintTriggered] = useState(false);

    const [renderKey, setRenderKey] = useState(Math.random());

    let [loanProductName, setLoanProductName] = useState<NewObject[]>();

    const [inputData, setInputData] = useState<InputDataInterface>({ startDate: new Date().toDateString(), endDate: new Date().toDateString(), accountNo: "", loanType: "", status: "" });


    let [statementTableData, setStatementTableData] = useState<StatementTableDataInterface[]>([]);

    const [formSuccess, setFormSuccess] = useState(false);

    const [totalAmount, setTotalAmount] = useState({ totalRepaymentAmount: 0, totalInterest: 0, totalLatePenalty: 0, totalAppliedAmount: 0 });

    let [tempCurrentDate, setTempCurrentDate] = useState("");

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const handleDateChange = (value: DatePickerProps['value'] | RangePickerProps['value'], field: string) => {
        setInputData((prevState: InputDataInterface) => {
            return {
                ...prevState,
                // [field]: value?.toString() ?? new Date().toString()
                [field]: (new Date(value?.toString() ?? '').toDateString())
            };
        })
        // console.log('onOk: ', (new Date(value?.toString()??'').toDateString()));
    };

    const handleLoanProductOption = () => {
        axios.get(`${BackendRootStrings.apiHome}/getLoanProductNameData`)
            .then(({ data }) => {
                const { message } = data;
                setLoanProductName([]);
                loanProductName = [];
                setLoanProductName(prevState => [
                    ...(prevState || []),
                    {
                        label: "All",
                        value: "All"
                    }
                ]);
                const newArray: NewObject[] = message.map((obj: OriginalObject) => ({
                    label: obj.name,
                    value: obj.name
                }));
                setLoanProductName(prevState => [
                    ...(prevState || []),
                    ...newArray
                ]);




                setRenderKey(Math.random());
            })
    }

    useEffect(() => {
        handleLoanProductOption();
    }, [])


    const handleSubmit = () => {
        if (inputData.accountNo === "") {
            message.error("Account No can't be empty");
        }
        else if (inputData.loanType === "") {
            message.error("Loan Type can't be empty");
        }
        else if (inputData.status === "") {
            message.error("Status can't be empty");
        }
        else {
            axios.post(`${BackendRootStrings.apiHome}/getCounterLoanRepaymentStatement`, inputData)
                .then(({ data }) => {
                    const { message } = data;
                    setStatementTableData([]);
                    statementTableData = [];
                    setTempCurrentDate("");
                    tempCurrentDate="";
                    setTotalAmount({ totalRepaymentAmount: 0, totalInterest: 0, totalLatePenalty: 0, totalAppliedAmount: 0 });
                    totalAmount.totalRepaymentAmount = 0; totalAmount.totalInterest = 0; totalAmount.totalLatePenalty = 0; totalAmount.totalAppliedAmount = 0;
                    let currentUniqueID="";
                    let slno=0;
                    for (let i = 0; i < message.length;) {
                        let val: StatementTableDataInterface = message[i];
                        if (currentUniqueID!==val.LoanID) {
                            const duePaymentDate = dayjs(val.RepaymentDate);

                            const newDuePaymentDate = duePaymentDate.add(1, 'month');
    
                            const formattedDate = newDuePaymentDate.format('ddd MMM DD YYYY');
                            
                            setTempCurrentDate(formattedDate);
                            tempCurrentDate=formattedDate;
                            currentUniqueID=val.LoanID;
                            i++;
                            val=message[i];
                        }
                        
                        if (tempCurrentDate === val.RepaymentDate) {
                           
                            let tempSlno=_.clone(slno);
                            setStatementTableData(prevState => [
                                ...prevState,
                                {
                                    SlNo: (tempSlno + 1).toString(),
                                    Account_No: val.Account_No,
                                    Borrower: val.Borrower,
                                    LoanAmount: val.LoanAmount,
                                    LoanID: val.LoanID,
                                    LoanProduct: val.LoanProduct,
                                    RepaymentAmount: val.RepaymentAmount,
                                    RepaymentCounter: val.RepaymentCounter,
                                    RepaymentDate: val.RepaymentDate,
                                    RepaymentInterest: val.RepaymentInterest,
                                    RepaymentLatePenalty: val.RepaymentLatePenalty,
                                    Status: val.Status,
                                }
                            ]);
                            setTotalAmount((prevState) => {
                                return {
                                    ...prevState,
                                    totalRepaymentAmount: prevState.totalRepaymentAmount + parseFloat(val.RepaymentAmount.toString()),
                                    totalInterest: prevState.totalInterest + parseFloat(val.RepaymentInterest.toString()),
                                    totalLatePenalty: prevState.totalLatePenalty + parseFloat(val.RepaymentLatePenalty.toString()),
                                    totalAppliedAmount: prevState.totalAppliedAmount + parseFloat(val.LoanAmount.toString())
                                }
                            })
                            i++;
                        }
                        else {
                            let tempDate= _.clone(tempCurrentDate)
                            let tempSlno=_.clone(slno);
                            setStatementTableData(prevState => [
                                ...prevState,
                                {
                                    SlNo: (tempSlno + 1).toString(),
                                    Account_No: val.Account_No,
                                    Borrower: val.Borrower,
                                    LoanAmount: val.LoanAmount,
                                    LoanID: val.LoanID,
                                    LoanProduct: val.LoanProduct,
                                    RepaymentAmount: "0",
                                    RepaymentCounter: "",
                                    RepaymentDate: tempDate,
                                    RepaymentInterest: "0",
                                    RepaymentLatePenalty: val.RepaymentLatePenalty,
                                    Status: val.Status,
                                }
                            ]);

                        }
                        const duePaymentDate = dayjs(tempCurrentDate);

                        const newDuePaymentDate = duePaymentDate.add(1, 'month');

                        const formattedDate = newDuePaymentDate.format('ddd MMM DD YYYY');
                        setTempCurrentDate(formattedDate);
                        tempCurrentDate=formattedDate;
                        if(new Date(tempCurrentDate).toISOString()>new Date(message[message.length-1].RepaymentDate).toISOString()){
                            break;
                        }
                        slno++;
                    }
                    // message.map((val: StatementTableDataInterface, ind: number) => {
                    //     setStatementTableData(prevState => [
                    //         ...prevState,
                    //         {
                    //             SlNo: (ind + 1).toString(),
                    //             Account_No: val.Account_No,
                    //             Borrower: val.Borrower,
                    //             LoanAmount: val.LoanAmount,
                    //             LoanID: val.LoanID,
                    //             LoanProduct: val.LoanProduct,
                    //             RepaymentAmount: val.RepaymentAmount,
                    //             RepaymentCounter: val.RepaymentCounter,
                    //             RepaymentDate: val.RepaymentDate,
                    //             RepaymentInterest: val.RepaymentInterest,
                    //             RepaymentLatePenalty: val.RepaymentLatePenalty,
                    //             Status: val.Status,
                    //         }
                    //     ]);
                    //     setTotalAmount((prevState) => {
                    //         return {
                    //             ...prevState,
                    //             totalRepaymentAmount: prevState.totalRepaymentAmount + parseFloat(val.RepaymentAmount.toString()),
                    //             totalInterest: prevState.totalInterest + parseFloat(val.RepaymentInterest.toString()),
                    //             totalLatePenalty: prevState.totalLatePenalty + parseFloat(val.RepaymentLatePenalty.toString()),
                    //             totalAppliedAmount: prevState.totalAppliedAmount + parseFloat(val.LoanAmount.toString())
                    //         }
                    //     })
                    // })
                })
        }
    }









    const columns = [
        {
            title: 'SlNo',
            dataIndex: 'SlNo',
            key: 'SlNo',
        },
        {
            title: 'Member ID',
            dataIndex: 'Account_No',
            key: 'Account_No',
        },
        {
            title: 'Loan ID',
            dataIndex: 'LoanID',
            key: 'LoanID',
        },
        {
            title: 'Loan Product',
            dataIndex: 'LoanProduct',
            key: 'LoanProduct',
        },
        {
            title: 'Borrower',
            dataIndex: 'Borrower',
            key: 'Borrower',
        },
        {
            title: 'Applied Amount',
            dataIndex: 'LoanAmount',
            key: 'LoanAmount',
        },
        {
            title: 'Repayment Amount',
            dataIndex: 'RepaymentAmount',
            key: 'RepaymentAmount',
        },
        {
            title: 'Loan Interest',
            dataIndex: 'RepaymentInterest',
            key: 'RepaymentInterest',
        },
        {
            title: 'Late Penalty',
            dataIndex: 'RepaymentLatePenalty',
            key: 'RepaymentLatePenalty',
        },
        {
            title: 'Counter',
            dataIndex: 'RepaymentCounter',
            key: 'RepaymentCounter',
        },
        {
            title: 'RepaymentDate',
            dataIndex: 'RepaymentDate',
            key: 'RepaymentDate',
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
        },

    ];


    const options = {
        // default is `save`
        method: "save" as const,
        filename: `Loan Report.pdf`,
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        // resolution: Resolution.HIGH,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            format: 'A4',
            // default is 'portrait'
            orientation: "portrait" as const,
        },
        // canvas: {
        //    // default is 'image/jpeg' for better size performance
        //    mimeType: 'image/png',
        //    qualityRatio: 1
        // },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break, 
        // so use with caution.
        // overrides: {
        //    pdf: {
        //       compress: true
        //    },
        //    canvas: {
        //       useCORS: true
        //    }
        // },
    };

    const handlePDFPrint = () => {
        generatePDF(printRef, options);
    };


    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    useEffect(() => {
        setRenderKey(Math.random());
        if (isPdfTriggered === true) {
            handlePDFPrint();
            setIsPdfTriggered(false);
        }
    }, [isPdfTriggered])

    useEffect(() => {
        setRenderKey(Math.random());
        if (isPrintTriggered === true) {
            handlePrint();
            setIsPrintTriggered(false);
        }
    }, [isPrintTriggered])

    const headers = [
        {
            label: "SlNo", key: "SlNo"
        },
        {
            label: "Member ID", key: "LoanID"
        },
        {
            label: "Loan_Product", key: "Loan_Product"
        },
        {
            label: "First_Payment_Date", key: "First_Payment_Date"
        },
        {
            label: "Release_date", key: "Release_date"
        },
        {
            label: "Applied_Amount", key: "Applied_Amount"
        },
        {
            label: "Late_Payments_Penalties", key: "Late_Payments_Penalties"
        },
        {
            label: "Description", key: "Description"
        },
        {
            label: "Remarks", key: "Remarks"
        },
        {
            label: "Status", key: "Status"
        },
        {
            label: "Loan ID", key: "UniqueLoanID"
        },

    ]

    const csvLink = {
        filename: `Counter Loan Repayment Report.csv`,
        headers: headers,
        data: statementTableData,
    }



    return (
        <>
            <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center" }}>
                {(!formSuccess) ? <div style={{ width: "100%", height: "50%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div style={{ width: "100%", height: "50%", border: "1px solid", display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", justifyContent: "space-evenly", }}>
                            <div style={{ width: "15%", }}><span>Start Date</span> <DatePicker allowClear={false} value={dayjs(inputData.startDate.toString())} style={{ height: "calc(1.8em + 1.6rem + 2px)", width: "100%" }}
                                onChange={(value: DatePickerProps['value'] | RangePickerProps['value']) => handleDateChange(value, "startDate")}
                            /></div>
                            <div style={{ width: "15%", }}><span>End Date</span> <DatePicker allowClear={false} value={dayjs(inputData.endDate.toString())} style={{ height: "calc(1.8em + 1.6rem + 2px)", width: "100%" }}
                                onChange={(value: DatePickerProps['value'] | RangePickerProps['value']) => handleDateChange(value, "endDate")}
                            /></div>
                            <div style={{ width: "15%", }}><span>Loan Type</span> <Select
                                showSearch
                                placeholder="Loan Product"
                                optionFilterProp="children"
                                onChange={(e) => setInputData((prevState: InputDataInterface) => { return { ...prevState, loanType: e } })}
                                filterOption={filterOption}
                                style={{ width: "100%", height: "75%" }}
                                options={loanProductName}
                                defaultValue={inputData.loanType}
                            /></div>
                            <div style={{ width: "15%", }}><span>Status</span> <Select
                                showSearch
                                placeholder="Status"
                                optionFilterProp="children"
                                onChange={(e) => setInputData((prevState: InputDataInterface) => { return { ...prevState, status: e } })}
                                filterOption={filterOption}
                                style={{ width: "100%", height: "75%" }}
                                options={[
                                    { value: 'All', label: 'All' },
                                    { value: 'Pending', label: 'Pending' },
                                    { value: 'Completed', label: 'Completed' },
                                    { value: 'Approved', label: 'Approved' },
                                ]}
                                defaultValue={inputData.status}
                            /></div>
                            <div style={{ width: "15%", }}><span>Account No</span><Input min={0} max={100} style={{ height: "calc(1.8em + 1.6rem + 2px)" }}
                                onChange={(e) => setInputData((prevState: InputDataInterface) => { return { ...prevState, accountNo: e.target.value } })}

                            /> </div>


                        </div>

                        <div style={{ width: "100%", marginTop: 20, display: "flex", justifyContent: "center" }}><Button onClick={handleSubmit} style={{ width: "20%", height: "calc(1.8em + 1.6rem + 2px)", backgroundColor: ColorCode.GreenCode, fontWeight: "bold", color: "white" }} >Filter</Button></div>

                    </div>
                    <div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                            <div style={{ width: "9%", display: "flex", justifyContent: "space-between", marginTop: 20, alignItems: "center" }}>
                                <CSVLink {...csvLink} style={{ display: "flex", textDecoration: "none", color: "black", backgroundColor: "white", height: 32, borderRadius: 5, justifyContent: "center", alignItems: "center" }}><Button style={{ backgroundColor: "gray", color: "white" }}>Excel</Button></CSVLink>
                                <Button style={{ backgroundColor: "red", color: "white" }} onClick={() => { setRenderKey(Math.random()); setIsPdfTriggered(true) }}>PDF</Button>
                                <Button style={{ backgroundColor: "green", color: "white" }} onClick={() => { setRenderKey(Math.random()); setIsPrintTriggered(true) }}>Print</Button>
                            </div>
                        </div>
                        <div key={renderKey} ref={printRef}>
                            <div style={{ display: "flex", width: "100%", marginBottom: 10 }}>
                                <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                    <h3>HOSPITAL OFFICE FUND</h3>
                                    <div style={{ width: 186, textAlign: "center" }}><h3>Loan Repayment Report</h3></div>
                                    <div style={{ width: "fit-content", textAlign: "center", display: "flex", flexDirection: "column" }}>
                                        <span style={{ fontWeight: "bold" }}>Total Amount Applied {totalAmount.totalAppliedAmount}</span>
                                        <span style={{ fontWeight: "bold" }}>Total Amount Repayed {totalAmount.totalRepaymentAmount}</span>
                                        <span style={{ fontWeight: "bold" }}>Total Interest Paid {totalAmount.totalInterest}</span>
                                        <span style={{ fontWeight: "bold" }}>Total Late Fine Paid {totalAmount.totalLatePenalty}</span>
                                    </div>
                                </div>



                            </div>
                            <Table pagination={isPrintTriggered || isPdfTriggered ? false : { position: ['topLeft'] }} dataSource={statementTableData} columns={columns} />

                        </div>

                    </div>

                </div> : <SuccessPage />}

            </div>
        </>
    )
}

export default CounterLoanRepaymentReport