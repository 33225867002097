import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Flex, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { MenuProps } from 'antd';
import axios, { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import {BackendRootStrings, RouteStrings} from '../Routes';
import { MenuInfo } from 'rc-menu/lib/interface';
import {DownOutlined} from '@ant-design/icons';

interface DataType {
    key: React.Key,
    First_Name: String,
    Last_Name: String,
    User_Type: String,
    Email: String,
}




const App: React.FC = () => {

    const [renderTableKey, setRenderTableKey] = useState(Math.random());

    const [data, setData] = useState<DataType[]>([]);

    let navigate = useNavigate();

    const handleUserDataDelete =async(currentItem:any)=>{
        await axios.post(`${BackendRootStrings.apiHome}/deleteUserInfo`,currentItem);
    }

    const onMenuClick = (recordTNo: string) => (info: MenuInfo) => {
        // const currentItem = data.filter((item, ind) => item.TNo === recordTNo);
        // switch (info.key) {
        //     case '1':
        //         navigate(RouteStrings.EditUser,{
        //             state:{
        //                 currentItem:currentItem
        //             }
        //           });
        //         break;
        //     case '2':
        //         handleUserDataDelete(currentItem);
        //         break;
        //     case '3':
        //         navigate(RouteStrings.ViewUser,{
        //             state:{
        //                 currentItem:currentItem
        //             }
        //           });
        //         break;
        //     default:
        //         break;
        // }
    };

    const items : MenuProps['items'] = [
        {
            key: '1',
            label: 'Edit',
        },
        {
            key: '2',
            label: 'Delete',
        },
        {
            key: '3',
            label: 'View',
        },
    ];

    const menuProps = {
        items,
        onClick: onMenuClick,
      };

    const columns: ColumnsType<DataType> = [
        {
            title: 'First_Name',
            dataIndex: 'First_Name',
            key: 'First_Name',
        },
        {
            title: 'Last_Name',
            dataIndex: 'Last_Name',
            key: 'Last_Name',
        },
        {
            title: 'User_Type',
            dataIndex: 'User_Type',
            key: 'User_Type',
        },
        {
            title: 'Email',
            dataIndex: 'Email',
            key: 'Email',
        },
        // {
        //     title: 'Action',
        //     key: 'action',
        //     render: (_, record) => (
        //         // <Space size="middle">
        //         //     <Flex className={record.TNo.toString()} align="flex-start" gap="small" vertical>
        //         //         <Dropdown.Button type="primary" menu={{ items,onClick: onMenuClick(record.TNo.toString())}}>Actions</Dropdown.Button>
        //         //     </Flex>
        //         // </Space>
        //         <Dropdown menu={{ items,onClick: onMenuClick(record.Email.toString())}}>
        //         <Button type='primary'>
        //           <Space>
        //             Action
        //             <DownOutlined />
        //           </Space>
        //         </Button>
        //       </Dropdown>
                
        //     ),
        // },
    ];

    useEffect(() => {
        axios.get(`${BackendRootStrings.apiHome}/getAllCounterDetails`)
            .then((response: { data: { message: DataType[] } }) => {
               
                
                setData(response.data.message.reverse());
             
                setRenderTableKey(Math.random());
            })
            .catch((error: AxiosError) => {
                console.error('Error:', error.message);
            });

    }, [])

    return (
        <>
        <div style={{width:"100%",display:"flex",justifyContent:"center"}}> <h3>Counter Lists</h3></div>
        <Table key={renderTableKey} columns={columns} dataSource={data} />
        </>
    )
}

export default App;