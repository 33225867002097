import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import DefaultImage from '../../resources/DefaultImage.png'
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd';

const ViewUser = () => {

    interface viewValueInterface {
        First_Name: string
        Image: string
        Last_Name: string
        TNo: string
        _id: string
        Phone_no:string
    }
    const location = useLocation();

    let [viewValues, setViewValues] = useState<viewValueInterface>();
    const [data, setData] = useState<viewValueInterface[]>([]);
    const [renderKey, setRenderKey] = useState(Math.random());

    useEffect(() => {
        
        setViewValues(location.state.currentItem[0]);
        viewValues=location.state.currentItem[0];
        if(viewValues!==undefined){
            setData([viewValues]);
           
        }
        setRenderKey(Math.random());
        
    }, [])
    


    const columns: ColumnsType<viewValueInterface> = [
        {
            title: 'First Name',
            dataIndex: 'First_Name',
            key: 'First_Name',
        },
        {
            title: 'Last Name',
            dataIndex: 'Last_Name',
            key: 'Last_Name',
        },
        {
            title: 'Account Number',
            dataIndex: 'TNo',
            key: 'TNo',
        },
        {
            title: 'Phone Number',
            dataIndex: 'Phone_no',
            key: 'Phone_no',
        }
    ]

    

    return (
        <div key={renderKey} style={{ height: 934, width: "100%", display: "flex", justifyContent: "center" }}>
            <div key={renderKey} style={{ height: "100%", width: "54%", border: "1px solid", display: "flex", justifyContent: "center",flexDirection:"column",alignItems:"center" }}>
                <div style={{ height: "31%", width: "32%" }}>
                    <img
                        src={viewValues?.Image}
                        alt="User Image"
                        style={{ width: "100%", height: "100%" }}
                        onError={(e) => {
                            setViewValues((prevState: viewValueInterface | undefined) => {
                                return {
                                    First_Name: prevState?.First_Name || '',
                                    Last_Name: prevState?.Last_Name || '',
                                    Image: DefaultImage,
                                    TNo: prevState?.TNo || '',
                                    _id: prevState?._id || '',
                                    Phone_no:prevState?.Phone_no || '',
                                };
                            });
                        }}
                    />

                </div>
                <div key={renderKey} style={{ height: "31%", width: "57%",marginTop:25 }}>
                    <Table key={renderKey} columns={columns} dataSource={data} pagination={false} />

                </div>
            </div>
        </div>
    )
}

export default ViewUser