import React, { useEffect, useState } from 'react';
import { UserOutlined, BankOutlined, PhoneOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, InputNumber, Upload } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import type { RcFile, UploadProps } from 'antd/es/upload';
import { ColorCode } from '../../resources/ColorCodes';
import { RegexStrings } from '../../resources/Regex';
import axios, { AxiosError } from 'axios';
import { BackendRootStrings, RouteStrings } from '../Routes';
import SuccessPage from './SuccessPage';
import { useLocation, useNavigate } from 'react-router-dom';

const Register = () => {

    interface dataInterface {
        First_Name: string
        Image: string
        Last_Name: string
        TNo: string,
        Phone_no: string,
    }

    const location = useLocation();
    const navigate=useNavigate()

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const [renderTableKey, setRenderTableKey] = useState(Math.random());

    const [formSuccess, setFormSuccess] = useState(false);

    const [loading, setLoading] = useState(false);

    const [errorList, setErrorList] = useState<dataInterface>({ First_Name: "", Last_Name: "", TNo: "", Phone_no: "", Image: "" });

    const nameRegex = RegexStrings.nameRegex;
    const emptyRegex = RegexStrings.emptyRegex;
    const phoneNumberRegex = RegexStrings.phoneNumberRegex;




    const [submitData, setSubmitData] = useState<dataInterface>({ First_Name: "", Last_Name: "", TNo: "", Phone_no: "", Image: "" });

    const getBase64 = (file: RcFile): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });


    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    }


    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload Image</div>
        </button>
    );

    const checkError = (myString: string, name: string) => {
        if (name === "First_Name" || name === "Last_Name") { if (emptyRegex.test(myString)) return `${name.split('_').join(' ')} must contain atleast one letter`; }
        if (name === "First_Name" || name === "Last_Name") { if (!nameRegex.test(myString)) return `${name.split('_').join(' ')} must not contain numericals or spaces`; }
        if (name === "phone_no") { if (!phoneNumberRegex.test(myString)) return `phone no must be equal to 10 and should contain only numbers` };
        return "";
    }


    const handleSubmit = () => {
        setLoading(true);
        if (location.pathname === "/editUser") {
            if (errorList.First_Name === "" && errorList.Last_Name === "") {
                axios.put(`${BackendRootStrings.apiHome}/updateUserInfo`, submitData)
                    .then(() => {
                        setTimeout(() => {
                            setFormSuccess(false);
                            navigate(RouteStrings.HomePage);
                            setRenderTableKey(Math.random());
                            setLoading(false);
                        }, 2000);
                        setFormSuccess(true);
                        
                    })
                    .catch((error: AxiosError) => {
                        console.error('Error:', error.message);
                    });
            }
        }
        else {
            setSubmitData((prevState: dataInterface) => {
                return {
                    ...prevState,
                    Image: fileList[0]?.thumbUrl ?? '',
                };
            })
            submitData.Image = fileList[0]?.thumbUrl ?? '';
            let canUserSubmit = true;
            // console.log("submitdata when submit ", submitData)
            Object.keys(submitData).forEach((key) => {

                const typedKey = key as keyof dataInterface;
                // const error = checkError(submitData[typedKey], typedKey);
                if (errorList[typedKey] || !submitData[typedKey]) {
                    // console.log(errorList[typedKey], submitData[typedKey], typedKey)
                    canUserSubmit = false;
                }
            });
            if (submitData.First_Name !== "" && submitData.Last_Name !== "" && submitData.TNo !== "") {
                axios.post(`${BackendRootStrings.apiHome}/createUserAccount`, submitData)
                    .then(() => {
                        setTimeout(() => {
                            setFormSuccess(false);
                            setRenderTableKey(Math.random());
                            setLoading(false);
                        }, 2000);
                        setFormSuccess(true);
                        setSubmitData((prevState)=>{
                            return{
                                ...prevState,
                                First_Name:"",
                                Last_Name:"",
                                Image:"",
                                Phone_no:"",
                                TNo:""
                            }
                        })
                        setRenderTableKey(Math.random());
                        
                    })
                    .catch((e) => {

                    })
            }
        }



    }

    const handleDataChange = (e:string, name: string) => {

        let val = (checkError(e, name));
        if (!val) {
            if (name === "First_Name") setErrorList((prevState: dataInterface) => {
                return {
                    ...prevState,
                    First_Name: '',
                };
            });
            else if (name === "Last_Name") setErrorList((prevState: dataInterface) => {
                return {
                    ...prevState,
                    Last_Name: '',
                };
            });
            else if (name === "phone_no") setErrorList((prevState: dataInterface) => {
                return {
                    ...prevState,
                    Phone_no: '',
                };
            });
            setSubmitData((prevState: dataInterface) => {
                if (prevState === undefined) {
                    return {
                        First_Name: 'DefaultFirstName',
                        Last_Name: 'DefaultLastName',
                        Image: 'DefaultImage',
                        TNo: 'DefaultTNo',
                        Phone_no: ''
                    };
                }
                if (name === "First_Name") {
                    return {
                        ...prevState,
                        First_Name: e,
                    };
                }
                else if (name === "Last_Name") {
                    return {
                        ...prevState,
                        Last_Name: e,
                    };
                }
                else if (name === "phone_no") {
                    return {
                        ...prevState,
                        Phone_no: e,
                    };
                }
                else if (name === "TNo") {
                    return {
                        ...prevState,
                        TNo: e,
                    };
                }
                return prevState;

            });
        }
        else {
            setErrorList((prevState: dataInterface) => {
                if (!prevState) {
                    return {
                        First_Name: "",
                        Last_Name: "",
                        Image: "",
                        TNo: "",
                        Phone_no: "",
                    };
                }
                if (name === "First_Name") {
                    return {
                        ...prevState,
                        First_Name: val,
                    };
                }
                if (name === "Last_Name") {
                    return {
                        ...prevState,
                        Last_Name: val,
                    };
                }
                if (name === "phone_no") {
                    return {
                        ...prevState,
                        Phone_no: val,
                    };
                }
                return prevState;

            });
        }
        
    }

    


    useEffect(() => {

        if (location.pathname === "/editUser") {
            setSubmitData((prevState: dataInterface) => {
                return {
                    ...prevState,
                    First_Name: location.state.currentItem[0].First_Name,
                    Last_Name: location.state.currentItem[0].Last_Name,
                    TNo: location.state.currentItem[0].TNo,
                    Phone_no: location.state.currentItem[0].Phone_no,
                }
            })
            setRenderTableKey(Math.random());
        }
    }, [])



    return (

        <div key={renderTableKey} style={{ display: "flex", height: 900, width: "100%", justifyContent: "center" }}>
            {(!formSuccess) ? <div style={{ display: "flex", width: "55%", height: "90%", border: "1px solid", justifyContent: "center", alignItems: "center", backgroundImage: "linear-gradient(0deg, rgb(4 27 193), rgb(6 235 235))" }}>
                <div style={{ height: "80%", width: "63%", border: "1px solid", backgroundColor: "white", boxShadow: "0px 0px 7px 2px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ width: "80%", display: "flex", justifyContent: "center", marginTop: 10 }}>
                        <h2 style={{ color: "rgb(4 27 193)", marginBottom: 75 }}>{(location.pathname==="/editUser")?"Update Account":"Create Account"}</h2>
                    </div>
                    <div style={{ width: "80%" }}>
                        <div style={{ height: "2%", width: "50%" }}>
                            <p>First Name</p>
                        </div>
                        <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                            <Input disabled={(location.pathname==='/')?false:true} defaultValue={submitData.First_Name} size="large" placeholder="First Name" prefix={<><span style={{ color: 'red' }}>*</span><UserOutlined /></>} onChange={(e) => handleDataChange(e.target.value, "First_Name")} />
                            <span style={{ display: (errorList.First_Name ? "block" : "none"), color: "red" }}>{errorList.First_Name}</span>
                        </div>
                        <div style={{ height: "2%", width: "50%" }}>
                            <p>Last Name</p>
                        </div>
                        <div style={{ width: "100%", marginTop: 15 }}>
                            <Input disabled={(location.pathname==='/')?false:true} defaultValue={submitData.Last_Name} size="large" placeholder="Last Name" prefix={<><span style={{ color: 'red' }}>*</span><UserOutlined /></>} onChange={(e) => handleDataChange(e.target.value, "Last_Name")} />
                            <span style={{ display: (errorList.Last_Name ? "block" : "none"), color: "red" }}>{errorList.Last_Name}</span>
                        </div>
                        <div style={{ height: "2%", width: "50%" }}>
                            <p>Account No (only numbers)</p>
                        </div>
                        <div style={{ width: "100%", marginTop: 15 }}>
                            <InputNumber style={{width:"100%"}} disabled={(location.pathname==='/')?false:true} defaultValue={submitData.TNo} size="large" placeholder="Acccount Number" prefix={<><span style={{ color: 'red' }}>*</span><BankOutlined /></>} onChange={(e) => handleDataChange(e??"", "TNo")} />
                            <span style={{ display: (errorList.TNo ? "block" : "none"), color: "red" }}>{errorList.TNo}</span>
                        </div>
                        <div style={{ height: "2%", width: "50%" }}>
                            <p>Phone No</p>
                        </div>
                        <div style={{ width: "100%", marginTop: 15 }}>
                            <Input defaultValue={submitData.Phone_no} size="large" placeholder="Phone Number" prefix={<PhoneOutlined />} onChange={(e) => handleDataChange(e.target.value, "phone_no")} />
                            <span style={{ display: (errorList.Phone_no ? "block" : "none"), color: "red" }}>{errorList.Phone_no}</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: 10 }}>
                            <Upload
                                action=""
                                listType="picture-circle"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChange}
                            >
                                {fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                            <div>
                                <Button disabled={loading} style={{ backgroundColor: `${ColorCode.GreenCode}` }} onClick={handleSubmit} type="primary">{(location.pathname==="/editUser")?"Update":"Save"}</Button>
                            </div>
                        </div>
                    </div>


                </div>
            </div> : <SuccessPage />}
        </div>
    )
}

export default Register