import { Button, DatePicker, DatePickerProps, Input, message, Select, Table } from 'antd'
import React, { useEffect, useState, useRef } from 'react';
import { BackendRootStrings } from '../Routes';
import axios from 'axios';
import SuccessPage from '../CreateAccount/SuccessPage';
import Cookies from 'universal-cookie';
import { CSVLink } from 'react-csv';
import generatePDF, { Margin } from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';

interface InputDataInterface {
    startDate: String,
    endDate: String,
    accountNo: String
}




interface StatementTableDataInterface {
    SlNo: String,
    LoanID: String,
    Account_No: String,
    Borrower: String,
    DueAmount: String,
    Interest:Number
}





const LoanDueReport = () => {

    const cookies = new Cookies();

    const userType = cookies.get('UserType');

    const printRef = useRef<any>();
    let [isPdfTriggered, setIsPdfTriggered] = useState(false);
    const [isPrintTriggered, setIsPrintTriggered] = useState(false);

    const [renderKey, setRenderKey] = useState(Math.random());

    const [inputData, setInputData] = useState<InputDataInterface>({ startDate: new Date().toDateString(), endDate: new Date().toDateString(), accountNo: "" });


    let [statementTableData, setStatementTableData] = useState<StatementTableDataInterface[]>([]);

    const [formSuccess, setFormSuccess] = useState(false);

   
    const handleData = () => {
            axios.get(`${BackendRootStrings.apiHome}/getLoanDueStatement`)
                .then(({ data }) => {
                    let { message } = data;
                    message=message.sort(function(a: StatementTableDataInterface, b: StatementTableDataInterface) {
                        return parseInt(b.LoanID.toString()) - parseInt(a.LoanID.toString());
                    });
                    setStatementTableData([]);
                    statementTableData = [];
                    message.map((val: StatementTableDataInterface, ind: number) => {
                       
                        setStatementTableData(prevState => [
                            ...prevState,
                            {
                                SlNo: (ind + 1).toString(),
                                LoanID:val.LoanID,
                                Account_No: val.Account_No,
                                Borrower: val.Borrower,
                                DueAmount: val.DueAmount,
                                Interest:val.Interest
                            }
                        ]);
                    })
                })
        
    }



    useEffect(() => {
      handleData();
    }, [])
    






    const columns = [
        {
            title: 'SlNo',
            dataIndex: 'SlNo',
            key: 'SlNo',
        },
        {
            title: 'LoanID',
            dataIndex: 'LoanID',
            key: 'LoanID',
        },
        {
            title: 'Account No',
            dataIndex: 'Account_No',
            key: 'Account_No',
        },
        {
            title: 'Borrower',
            dataIndex: 'Borrower',
            key: 'Borrower',
        },
        {
            title: 'Due Principal Amount',
            dataIndex: 'DueAmount',
            key: 'DueAmount',
        },
        {
            title: 'Interest',
            dataIndex: 'Interest',
            key: 'Interest',
        },
       
    ];


    const options = {
        // default is `save`
        method: "save" as const,
        filename: `Loan Due Report.pdf`,
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        // resolution: Resolution.HIGH,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            format: 'A4',
            // default is 'portrait'
            orientation: "portrait" as const,
        },
        // canvas: {
        //    // default is 'image/jpeg' for better size performance
        //    mimeType: 'image/png',
        //    qualityRatio: 1
        // },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break, 
        // so use with caution.
        // overrides: {
        //    pdf: {
        //       compress: true
        //    },
        //    canvas: {
        //       useCORS: true
        //    }
        // },
    };

    const handlePDFPrint = () => {
        generatePDF(printRef, options);
    };


    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    useEffect(() => {
        setRenderKey(Math.random());
        if (isPdfTriggered === true) {
            handlePDFPrint();
            setIsPdfTriggered(false);
        }
    }, [isPdfTriggered])

    useEffect(() => {
        setRenderKey(Math.random());
        if (isPrintTriggered === true) {
            handlePrint();
            setIsPrintTriggered(false);
        }
    }, [isPrintTriggered])

    const headers = [
        {
            label: "SlNo", key: "SlNo"
        },
        {
            label: "LoanID", key: "LoanID"
        },
        {
            label: "Account_No", key: "Account_No"
        },
        {
            label: "Borrower", key: "Borrower"
        },
        {
            label: "DueAmount", key: "DueAmount"
        },

    ]

    const csvLink = {
        filename: `Loan Due Report.csv`,
        headers: headers,
        data: statementTableData,
    }



    return (
        <>
            <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center" }}>
                {(!formSuccess) ? <div style={{ width: "100%", height: "50%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    
                    <div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                            <div style={{ width: "9%", display: "flex", justifyContent: "space-between", marginTop: 20, alignItems: "center" }}>
                                <CSVLink {...csvLink} style={{ display: "flex", textDecoration: "none", color: "black", backgroundColor: "white", height: 32, borderRadius: 5, justifyContent: "center", alignItems: "center" }}><Button style={{ backgroundColor: "gray", color: "white" }}>Excel</Button></CSVLink>
                                <Button style={{ backgroundColor: "red", color: "white" }} onClick={() => { setRenderKey(Math.random()); setIsPdfTriggered(true) }}>PDF</Button>
                                <Button style={{ backgroundColor: "green", color: "white" }} onClick={() => { setRenderKey(Math.random()); setIsPrintTriggered(true) }}>Print</Button>
                            </div>
                        </div>
                        <div key={renderKey} ref={printRef}>
                            <div style={{ display: "flex", width: "100%" }}>
                                <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                    <h3>HOSPITAL OFFICE FUND</h3>
                                    <div style={{ width: 186, textAlign: "center" }}><h3>Loan Due Report</h3></div>
                                </div>



                            </div>
                            <Table pagination={isPrintTriggered || isPdfTriggered ? false : { position: ['topLeft'] }} dataSource={statementTableData} columns={columns} />

                        </div>

                    </div>

                </div> : <SuccessPage />}

            </div>
        </>
    )
}

export default LoanDueReport;