import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { TableRowSelection } from 'antd/es/table/interface';
import { useDispatch, useSelector } from 'react-redux';
import { setTotalCollectionAmount } from '../../redux/actions/ProductActions';
import { useReactToPrint } from 'react-to-print';
import generatePDF, { Margin } from 'react-to-pdf';
import { CSVLink } from 'react-csv';

interface DataType {
    key: React.Key;
    "SlNo": number,
    "NAME": string,
    "TNo": number,
    "September": number,
    "October": number,
    "November": number,
    "December": number,
    "January": number,
    "February": number,
    "March": number,
    "April": number,
    "May": number,
    "June": number,
    "July": number,
    "August": number,
    "TOTAL": number
}

const columns: ColumnsType<DataType> = [
    {
        title: 'SlNo',
        dataIndex: 'SlNo',
    },
    {
        title: 'Name',
        dataIndex: 'NAME',
    },
    {
        title: 'Account No',
        dataIndex: 'TNo',
    },
    {
        title: 'September',
        dataIndex: 'September',
        render: (text) =>
            <Tag style={{ color: (text > 0) ? "green" : "red" }}>{(text > 0) ? "PAID" : "UNPAID"}</Tag>
    },
    {
        title: 'October',
        dataIndex: 'October',
        render: (text) =>
            <Tag style={{ color: (text > 0) ? "green" : "red" }}>{(text > 0) ? "PAID" : "UNPAID"}</Tag>
    },
    {
        title: 'November',
        dataIndex: 'November',
        render: (text) =>
            <Tag style={{ color: (text > 0) ? "green" : "red" }}>{(text > 0) ? "PAID" : "UNPAID"}</Tag>
    },
    {
        title: 'December',
        dataIndex: 'December',
        render: (text) =>
            <Tag style={{ color: (text > 0) ? "green" : "red" }}>{(text > 0) ? "PAID" : "UNPAID"}</Tag>
    },
    {
        title: 'January',
        dataIndex: 'January',
        render: (text) =>
            <Tag style={{ color: (text > 0) ? "green" : "red" }}>{(text > 0) ? "PAID" : "UNPAID"}</Tag>
    },
    {
        title: 'February',
        dataIndex: 'February',
        render: (text) =>
            <Tag style={{ color: (text > 0) ? "green" : "red" }}>{(text > 0) ? "PAID" : "UNPAID"}</Tag>
    },
    {
        title: 'March',
        dataIndex: 'March',
        render: (text) =>
            <Tag style={{ color: (text > 0) ? "green" : "red" }}>{(text > 0) ? "PAID" : "UNPAID"}</Tag>
    },
    {
        title: 'April',
        dataIndex: 'April',
        render: (text) =>
            <Tag style={{ color: (text > 0) ? "green" : "red" }}>{(text > 0) ? "PAID" : "UNPAID"}</Tag>
    },
    {
        title: 'May',
        dataIndex: 'May',
        render: (text) =>
            <Tag style={{ color: (text > 0) ? "green" : "red" }}>{(text > 0) ? "PAID" : "UNPAID"}</Tag>
    },
    {
        title: 'June',
        dataIndex: 'June',
        render: (text) =>
            <Tag style={{ color: (text > 0) ? "green" : "red" }}>{(text > 0) ? "PAID" : "UNPAID"}</Tag>
    },
    {
        title: 'July',
        dataIndex: 'July',
        render: (text) =>
            <Tag style={{ color: (text > 0) ? "green" : "red" }}>{(text > 0) ? "PAID" : "UNPAID"}</Tag>
    },
    {
        title: 'August',
        dataIndex: 'August',
        render: (text) =>
            <Tag style={{ color: (text > 0) ? "green" : "red" }}>{(text > 0) ? "PAID" : "UNPAID"}</Tag>
    },
    {
        title: 'TOTAL',
        dataIndex: 'TOTAL',
    },
];

// let data: DataType[] = [];









const App: React.FC = () => {

    //Redux part
    const products: { allProducts: { totalCollectionsArray: DataType[], isCounterSelected: Boolean } } = useSelector((state: { allProducts: { totalCollectionsArray: DataType[], isCounterSelected: Boolean } }) => state);

    const dispatch = useDispatch();

    const printRef = useRef<any>();
    let [isPdfTriggered, setIsPdfTriggered] = useState(false);
    const [isPrintTriggered, setIsPrintTriggered] = useState(false);

    const [renderTableKey, setRenderTableKey] = useState(Math.random());

    let [totalAmount, setTotalAmount] = useState(0);

    let [data, setData] = useState<DataType[]>([]);

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const [searchText, setSearchText] = useState("");

    const [tempSearchDataArray, setTempSearchDataArray] = useState<DataType[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const com = (a: DataType, b: DataType) => {
        return a.SlNo - b.SlNo;
    }

    useEffect(() => {
        dispatch(setTotalCollectionAmount(totalAmount));
    }, [totalAmount])


    useEffect(() => {
        setTotalAmount(0);
        totalAmount = 0;
        if (data.length !== 0) {
            data.map((obj, ind, arr) => {

                setTotalAmount(totalAmount + obj?.TOTAL);
                totalAmount = (totalAmount + obj?.TOTAL);
            })
        }


    }, [data])

    const handleSetInitialData = () => {
        let temp = [];
        if (tempSearchDataArray.length === 0) {
            temp = products.allProducts.totalCollectionsArray;
        }
        else {
            temp = tempSearchDataArray;
        }

        temp.sort(com);
        data = [];


        temp.map((obj, ind, arr) => {
            const existingEntityIndex = data.findIndex((item, ind) => item.TNo === obj.TNo);
            // const monthsToSum = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            if (existingEntityIndex !== -1) {

                data[existingEntityIndex] = {
                    key: obj['TNo'],
                    "SlNo": obj['SlNo'],
                    "NAME": obj['NAME'],
                    "TNo": obj['TNo'],
                    "September": data[existingEntityIndex]['September'] + obj['September'],
                    "October": data[existingEntityIndex]['October'] + obj['October'],
                    "November": data[existingEntityIndex]['November'] + obj['November'],
                    "December": data[existingEntityIndex]['December'] + obj['December'],
                    "January": data[existingEntityIndex]['January'] + obj['January'],
                    "February": data[existingEntityIndex]['February'] + obj['February'],
                    "March": data[existingEntityIndex]['March'] + obj['March'],
                    "April": data[existingEntityIndex]['April'] + obj['April'],
                    "May": data[existingEntityIndex]['May'] + obj['May'],
                    "June": data[existingEntityIndex]['June'] + obj['June'],
                    "July": data[existingEntityIndex]['July'] + obj['July'],
                    "August": data[existingEntityIndex]['August'] + obj['August'],
                    "TOTAL": (Object.keys(data[existingEntityIndex]) as (keyof DataType)[])
                        .filter((key) => key !== 'SlNo' && key !== 'NAME' && key !== 'TNo')
                        .reduce((total, key) => {
                            const value = data[existingEntityIndex][key];
                            // return typeof value === 'number' ? total + value : total;
                            return (parseInt(total.toString()) + parseInt(value.toString()))
                        }, 0),
                };

            }
            else {
                let tempArr: DataType[] = data;
                tempArr.push({
                    key: obj['TNo'],
                    "SlNo": obj['SlNo'],
                    "NAME": obj['NAME'],
                    "TNo": obj['TNo'],
                    "September": obj['September'] !== null ? obj['September'] : 0,
                    "October": obj['October'] !== null ? obj['October'] : 0,
                    "November": obj['November'] !== null ? obj['November'] : 0,
                    "December": obj['December'] !== null ? obj['December'] : 0,
                    "January": obj['January'] !== null ? obj['January'] : 0,
                    "February": obj['February'] !== null ? obj['February'] : 0,
                    "March": obj['March'] !== null ? obj['March'] : 0,
                    "April": obj['April'] !== null ? obj['April'] : 0,
                    "May": obj['May'] !== null ? obj['May'] : 0,
                    "June": obj['June'] !== null ? obj['June'] : 0,
                    "July": obj['July'] !== null ? obj['July'] : 0,
                    "August": obj['August'] !== null ? obj['August'] : 0,
                    "TOTAL": (
                        (obj['September'] || 0) +
                        (obj['October'] || 0) +
                        (obj['November'] || 0) +
                        (obj['December'] || 0) +
                        (obj['January'] || 0) +
                        (obj['February'] || 0) +
                        (obj['March'] || 0) +
                        (obj['April'] || 0) +
                        (obj['May'] || 0) +
                        (obj['June'] || 0) +
                        (obj['July'] || 0) +
                        (obj['August'] || 0)
                    )
                });

                setData(tempArr);
            }
        })

        setRenderTableKey(Math.random());

    }

    useEffect(() => {
        handleSetInitialData();
        setTempSearchDataArray([])

    }, [products.allProducts.totalCollectionsArray])


    const rowSelection: TableRowSelection<DataType> = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
            {
                key: 'odd',
                text: 'Select Odd Row',
                onSelect: (changeableRowKeys) => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
                        if (index % 2 !== 0) {
                            return false;
                        }
                        return true;
                    });
                    setSelectedRowKeys(newSelectedRowKeys);
                },
            },
            {
                key: 'even',
                text: 'Select Even Row',
                onSelect: (changeableRowKeys) => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
                        if (index % 2 !== 0) {
                            return true;
                        }
                        return false;
                    });
                    setSelectedRowKeys(newSelectedRowKeys);
                },
            },
        ],
    };



    //Search Part
    const handleSearch = (e: any) => {
        setSearchText(e.target.value);
    }

    useEffect(() => {

        if (searchText === null || searchText === undefined) {
            setTempSearchDataArray([]);
            handleSetInitialData();
        }
        else {

            setTempSearchDataArray((prevstate) => {

                let var1 = products.allProducts.totalCollectionsArray.filter(
                    (info) => {
                        const searchTextAsNumber = parseInt(searchText);
                        return (info.NAME?.toLowerCase().includes(searchText.toLowerCase()) || info.TNo === searchTextAsNumber)
                    }

                );
                return var1;
            })

            setRenderTableKey(Math.random());
        }
    }, [searchText])

    useEffect(() => {
        handleSetInitialData();
    }, [tempSearchDataArray])


    const options = {
        // default is `save`
        method: "save" as const,
        filename: `Loan Report.pdf`,
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        // resolution: Resolution.HIGH,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            format: 'A4',
            // default is 'portrait'
            orientation: "portrait" as const,
        },
        // canvas: {
        //    // default is 'image/jpeg' for better size performance
        //    mimeType: 'image/png',
        //    qualityRatio: 1
        // },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break, 
        // so use with caution.
        // overrides: {
        //    pdf: {
        //       compress: true
        //    },
        //    canvas: {
        //       useCORS: true
        //    }
        // },
    };

    const handlePDFPrint = () => {
        generatePDF(printRef, options);
    };


    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    useEffect(() => {
        setRenderTableKey(Math.random());
        if (isPdfTriggered === true) {
            handlePDFPrint();
            setIsPdfTriggered(false);
        }
    }, [isPdfTriggered])

    useEffect(() => {
        setRenderTableKey(Math.random());
        if (isPrintTriggered === true) {
            handlePrint();
            setIsPrintTriggered(false);
        }
    }, [isPrintTriggered])

    const headers = [
        {
            label: "SlNo", key: "SlNo"
        },
        {
            label: "Member ID", key: "LoanID"
        },
        {
            label: "Loan_Product", key: "Loan_Product"
        },
        {
            label: "First_Payment_Date", key: "First_Payment_Date"
        },
        {
            label: "Release_date", key: "Release_date"
        },
        {
            label: "Applied_Amount", key: "Applied_Amount"
        },
        {
            label: "Late_Payments_Penalties", key: "Late_Payments_Penalties"
        },
        {
            label: "Description", key: "Description"
        },
        {
            label: "Remarks", key: "Remarks"
        },
        {
            label: "Status", key: "Status"
        },
        {
            label: "Loan ID", key: "UniqueLoanID"
        },

    ]

    const csvLink = {
        filename: `Loan Report.csv`,
        headers: headers,
        data: columns,
    }



    return (
        <>
        {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
            <div style={{ width: "9%", display: "flex", justifyContent: "space-between", marginTop: 20, alignItems: "center" }}>
                <CSVLink {...csvLink} style={{ display: "flex", textDecoration: "none", color: "black", backgroundColor: "white", height: 32, borderRadius: 5, justifyContent: "center", alignItems: "center" }}><Button style={{ backgroundColor: "gray", color: "white" }}>Excel</Button></CSVLink>
                <Button style={{ backgroundColor: "red", color: "white" }} onClick={() => { setRenderTableKey(Math.random()); setIsPdfTriggered(true) }}>PDF</Button>
                <Button style={{ backgroundColor: "green", color: "white" }} onClick={() => { setRenderTableKey(Math.random()); setIsPrintTriggered(true) }}>Print</Button>
            </div>
        </div> */}
            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: 5, marginBottom: 0 }}>
                <div style={{ width: "30%", border: "1px solid black", borderRadius: 5 }}>
                    <Input placeholder="Enter Account No or Name" onChange={handleSearch} />
                </div>
            </div>

            <div ref={printRef}>
                <Table pagination={isPrintTriggered || isPdfTriggered ? false : { position: ['topLeft'] }} key={renderTableKey} rowSelection={rowSelection} columns={columns} dataSource={data} />

            </div>
        </>

    )
};

export default App;