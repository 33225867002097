import { Button, DatePicker, DatePickerProps, Input, message, Select, Table } from 'antd'
import React, { useEffect, useState, useRef } from 'react';
import { ColorCode } from '../../resources/ColorCodes';
import { BackendRootStrings } from '../Routes';
import axios from 'axios';
import SuccessPage from '../CreateAccount/SuccessPage';
import Cookies from 'universal-cookie';
import dayjs from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';
import { CSVLink } from 'react-csv';
import generatePDF, { Margin } from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';

interface InputDataInterface {
    startDate: String,
    endDate: String,
    accountNo: String
}

interface NewObject {
    label: string;
    value: any;
}

interface CounterListDataType {
    key: React.Key,
    First_Name: String,
    Last_Name: String,
    User_Type: String,
}
interface StatementTableDataInterface {
    SlNo: String,
    Date: String,
    Account_No: String,
    Description: String,
    Counter: String,
    Amount: String,
    Late_Fees: String
}





const AccountStatement = () => {

    const cookies = new Cookies();

    const userType = cookies.get('UserType');

    const printRef = useRef<any>();
    let [isPdfTriggered, setIsPdfTriggered] = useState(false);
    const [isPrintTriggered, setIsPrintTriggered] = useState(false);

    const [userName, setUserName] = useState('');
    const [endYearInterest, setEndYearInterest] = useState('0');

    const [renderKey, setRenderKey] = useState(Math.random());

    const [inputData, setInputData] = useState<InputDataInterface>({ startDate: new Date().toDateString(), endDate: new Date().toDateString(), accountNo: "" });

    let [finalBalance, setFinalBalance] = useState(0);

    let [statementTableData, setStatementTableData] = useState<StatementTableDataInterface[]>([]);

    const [formSuccess, setFormSuccess] = useState(false);

    const handleDateChange = (value: DatePickerProps['value'] | RangePickerProps['value'], field: string) => {
        setInputData((prevState: InputDataInterface) => {
            return {
                ...prevState,
                // [field]: value?.toString() ?? new Date().toString()
                [field]: (new Date(value?.toString() ?? '').toDateString())
            };
        })
        // console.log('onOk: ', (new Date(value?.toString()??'').toDateString()));
    };

    const handleSubmit = async () => {
        if (inputData.accountNo === "") {
            message.error("Account No can't be empty");
        }
        else {
            await axios.post(`${BackendRootStrings.apiHome}/getAccountStatementById`, inputData)
                .then(({ data }) => {
                    const { message } = data;
                    setStatementTableData([]);
                    statementTableData = [];
                    setFinalBalance(0);
                    finalBalance = 0;
                    message.map((val: StatementTableDataInterface, ind: number) => {
                        let dateParts = val.Date.split(" ");

                        setFinalBalance(finalBalance + parseFloat(val.Amount.toString()) + ((val.Late_Fees !== '') ? parseFloat(val.Late_Fees.toString()) : 0));
                        finalBalance += parseFloat(val.Amount.toString()) + ((val.Late_Fees !== '') ? parseFloat(val.Late_Fees.toString()) : 0);
                        setStatementTableData(prevState => [
                            ...prevState,
                            {
                                SlNo: (ind + 1).toString(),
                                Date: new Date(dateParts[1] + " " + dateParts[2] + ", " + dateParts[3]).toDateString(),
                                Account_No: val.Account_No,
                                Description: val.Description,
                                Counter: val.Counter,
                                Amount: val.Amount,
                                Late_Fees: val.Late_Fees
                            }
                        ]);
                    })
                })
            const { data } = await axios.post(`${BackendRootStrings.apiHome}/getUserNameByID`, { id: inputData.accountNo });
            setUserName(data);
            {
                const { data } = await axios.post(`${BackendRootStrings.apiHome}/getInterestCreditByID`, { id: inputData.accountNo })
                setEndYearInterest(parseFloat(data).toFixed(2).toString());
            }
        }
    }

    const columns = [
        {
            title: 'SlNo',
            dataIndex: 'SlNo',
            key: 'SlNo',
        },
        {
            title: 'Date',
            dataIndex: 'Date',
            key: 'Date',
        },
        {
            title: 'Account No',
            dataIndex: 'Account_No',
            key: 'Account_No',
        },
        // {
        //     title: 'Description',
        //     dataIndex: 'Description',
        //     key: 'Description',
        // },
        {
            title: 'Counter',
            dataIndex: 'Counter',
            key: 'Counter',
        },
        {
            title: 'Amount',
            dataIndex: 'Amount',
            key: 'Amount',
            render: (value: any, record: any) => (
                <span>{(record.Late_Fees !== "" && parseFloat(record.Late_Fees) > 0) ? value + " + " + record.Late_Fees + " (Reg.Fees)" : value}</span>

            )
        },
    ];


    const options = {
        // default is `save`
        method: "save" as const,
        filename: `Account Statement.pdf`,
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        // resolution: Resolution.HIGH,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            format: 'A4',
            // default is 'portrait'
            orientation: "portrait" as const,
        },
        // canvas: {
        //    // default is 'image/jpeg' for better size performance
        //    mimeType: 'image/png',
        //    qualityRatio: 1
        // },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break, 
        // so use with caution.
        // overrides: {
        //    pdf: {
        //       compress: true
        //    },
        //    canvas: {
        //       useCORS: true
        //    }
        // },
    };

    const handlePDFPrint = () => {
        generatePDF(printRef, options);
    };


    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    useEffect(() => {
        setRenderKey(Math.random());
        if (isPdfTriggered === true) {
            handlePDFPrint();
            setIsPdfTriggered(false);
        }
    }, [isPdfTriggered])

    useEffect(() => {
        setRenderKey(Math.random());
        if (isPrintTriggered === true) {
            handlePrint();
            setIsPrintTriggered(false);
        }
    }, [isPrintTriggered])

    const headers = [
        {
            label: "SlNo", key: "SlNo"
        },
        {
            label: "Date", key: "Date"
        },
        {
            label: "Account_No", key: "Account_No"
        },
        {
            label: "Description", key: "Description"
        },
        {
            label: "Counter", key: "Counter"
        },
        {
            label: "Amount", key: "Amount"
        },

    ]

    const csvLink = {
        filename: `Account Statement.csv`,
        headers: headers,
        data: statementTableData,
    }



    return (
        <>
            <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center" }}>
                {(!formSuccess) ? <div style={{ width: "100%", height: "50%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div style={{ width: "100%", height: "50%", border: "1px solid", display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", justifyContent: "space-evenly", }}>
                            <div style={{ width: "20%", }}><span>Start Date</span> <DatePicker allowClear={false} value={dayjs(inputData.startDate.toString())} style={{ height: "calc(1.8em + 1.6rem + 2px)", width: "100%" }}
                                onChange={(value: DatePickerProps['value'] | RangePickerProps['value']) => handleDateChange(value, "startDate")}
                            /></div>
                            <div style={{ width: "20%", }}><span>End Date</span> <DatePicker allowClear={false} value={dayjs(inputData.endDate.toString())} style={{ height: "calc(1.8em + 1.6rem + 2px)", width: "100%" }}
                                onChange={(value: DatePickerProps['value'] | RangePickerProps['value']) => handleDateChange(value, "endDate")}
                            /></div>
                            <div style={{ width: "20%", }}><span>Account No</span><Input min={0} max={100} style={{ height: "calc(1.8em + 1.6rem + 2px)" }}
                                onChange={(e) => setInputData((prevState: InputDataInterface) => { return { ...prevState, accountNo: e.target.value } })}

                            /> </div>


                        </div>

                        <div style={{ width: "100%", marginTop: 20, display: "flex", justifyContent: "center" }}><Button onClick={handleSubmit} style={{ width: "20%", height: "calc(1.8em + 1.6rem + 2px)", backgroundColor: ColorCode.GreenCode, fontWeight: "bold", color: "white" }} >Filter</Button></div>

                    </div>
                    <div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                            <div style={{ width: "9%", display: "flex", justifyContent: "space-between", marginTop: 20, alignItems: "center" }}>
                                <CSVLink {...csvLink} style={{ display: "flex", textDecoration: "none", color: "black", backgroundColor: "white", height: 32, borderRadius: 5, justifyContent: "center", alignItems: "center" }}><Button style={{ backgroundColor: "gray", color: "white" }}>Excel</Button></CSVLink>
                                <Button style={{ backgroundColor: "red", color: "white" }} onClick={() => { setRenderKey(Math.random()); setIsPdfTriggered(true) }}>PDF</Button>
                                <Button style={{ backgroundColor: "green", color: "white" }} onClick={() => { setRenderKey(Math.random()); setIsPrintTriggered(true) }}>Print</Button>
                            </div>
                        </div>
                        <div key={renderKey} ref={printRef}>
                            <div style={{ display: "flex", width: "100%" }}>
                                <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                    <h3>HOSPITAL OFFICE FUND</h3>
                                    <div style={{ width: 186, textAlign: "center" }}><h3>Account Statement</h3></div>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                        <div style={{ width: "100%", display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-end' }}>
                                            <div style={{ display: 'flex', width: '100%', fontWeight: 'bold', justifyContent: 'center' }}>
                                                Account Name : {userName}
                                            </div>
                                            <div style={{ display: 'flex', width: '100%', fontWeight: 'bold', justifyContent: 'center' }}>
                                                Interest Credit : <span style={{color:'blue',marginLeft:5}}>{endYearInterest}</span>
                                            </div>
                                            <div style={{ display: 'flex', width: '100%', fontWeight: 'bold', justifyContent: 'center' }}>
                                                Deposit Amount : <span style={{color:ColorCode.GreenCode,marginLeft:5}}>{finalBalance}</span>
                                            </div>
                                            <div style={{ display: 'flex', width: '100%', fontWeight: 'bold', justifyContent: 'center' }}>
                                                Total Amount : <span style={{color:'red',marginLeft:5}}>{finalBalance+parseFloat(endYearInterest)}</span>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <Table pagination={isPrintTriggered || isPdfTriggered ? false : { position: ['topLeft'] }} dataSource={statementTableData} columns={columns} />

                        </div>

                    </div>

                </div> : <SuccessPage />}

            </div>
        </>
    )
}

export default AccountStatement