import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Flex, Input, Select, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { MenuProps } from 'antd';
import axios, { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { BackendRootStrings, RouteStrings } from '../Routes';
import { MenuInfo } from 'rc-menu/lib/interface';
import { DownOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { ColorCode } from '../../resources/ColorCodes';
import { LoginDetails } from '../../resources/UserLoginDetails';
import Cookies from 'universal-cookie';

interface LoanDataType {
    Borrower: String,
    LoanID: String,
    Member_No: String,
    Release_date: String,
    Applied_Amount: String,
    Status: string;
    UniqueLoanID: string
}

const App: React.FC = () => {

    let dispatch = useDispatch();

    const [renderTableKey, setRenderTableKey] = useState(Math.random());

    const [userName, setUserName] = useState("Default");
    const [userType, setUserType] = useState("");

    const cookies = new Cookies();

    let [data, setData] = useState<LoanDataType[]>([]);
    let [filterData, setFilterData] = useState<LoanDataType[]>([]);
    let [currentFilterValue, setCurrentFilterValue] = useState<string>();

    const [searchText, setSearchText] = useState("");

    const [tempSearchDataArray, setTempSearchDataArray] = useState<LoanDataType[]>([]);

    let navigate = useNavigate();

    const handleLoanDataDelete = async (currentItem: any) => {
        // console.log(currentItem);
        if (currentItem[0].Status !== "Pending" && currentItem[0].Status !== "Declined") {
            window.alert(`Current Loan is ${currentItem[0].Status}`);
            return false;
        }
        // navigate(RouteStrings.Loans, {
        //     state: {
        //       path: 'AllLoans',
        //     }
        //   });
        //   window.location.reload();
        await axios.post(`${BackendRootStrings.apiHome}/deleteLoanInfo`, currentItem)
            .then(() => {
                axios.get(`${BackendRootStrings.apiHome}/getAllLoanDataWithStatus`)
                    .then((response: { data: { message: LoanDataType[] } }) => {
                        // console.log('Response:', response.data.message);

                        setData(response.data.message.reverse());

                        data = response.data.message;
                        setFilterData(response.data.message);
                        filterData = response.data.message;
                        // handleMainFilter();
                        setRenderTableKey(Math.random());
                    })
                    .catch((error: AxiosError) => {
                        console.error('Error:', error.message);
                    });
            })
    }

    useEffect(() => {
        let tempUserName = cookies.get("UserName");
        let tempUserType = cookies.get("UserType");
        // console.log("Usertype",tempUserType)
        setUserName(tempUserName);
        setUserType(tempUserType);
    }, [])

    const handleLoanApprove = (id: number, field: string) => {
        axios.put(`${BackendRootStrings.apiHome}/approveLoan`, { UniqueLoanID: data[id]?.UniqueLoanID, Status: field, UserType: userType, Applied_Amount: data[id]?.Applied_Amount })
            .then(() => {
                axios.get(`${BackendRootStrings.apiHome}/getAllLoanDataWithStatus`)
                    .then((response: { data: { message: LoanDataType[] } }) => {
                        // console.log('Response:', response.data.message);

                        setData(response.data.message.reverse());

                        data = response.data.message;
                        setFilterData(response.data.message);
                        filterData = response.data.message;

                        // handleMainFilter();
                        setRenderTableKey(Math.random());
                    })
                    .catch((error: AxiosError) => {
                        console.error('Error:', error.message);
                    });
                    window.location.reload();
            })
    }


    const onMenuClick = (recordTNo: string) => (info: MenuInfo) => {
        const currentItem = data.filter((item, ind) => item.UniqueLoanID === recordTNo);
        switch (info.key) {
            case '1':
                navigate(RouteStrings.EditLoan, {
                    state: {
                        path: 'AddLoan',
                        currentItem: currentItem
                    }
                });
                break;
            case '2':
                handleLoanDataDelete(currentItem);
                break;
            case '3':
                navigate(RouteStrings.ViewLoans, {
                    state: {
                        path: 'ViewLoan',
                        currentItem: currentItem
                    }
                });
                break;
            default:
                break;
        }
    };

    let [items, setItems] = useState<MenuProps['items']>([])
    // let items: MenuProps['items'] = [];

    useEffect(() => {
        if (!userType.includes("Counter")) {
            setItems([
                {
                    key: '1',
                    label: 'Edit',
                },
                {
                    key: '2',
                    label: 'Delete',
                },
                {
                    key: '3',
                    label: 'View',
                },
            ]);
            items = [
                {
                    key: '1',
                    label: 'Edit',
                },
                {
                    key: '2',
                    label: 'Delete',
                },
                {
                    key: '3',
                    label: 'View',
                },
            ];
        } else {
            setItems([{
                key: '3',
                label: 'View',
            }
            ])
            items = [{
                key: '3',
                label: 'View',
            },
            ];
        }
    }, [userType])


    const columns: ColumnsType<LoanDataType> = [
        {
            title: 'Loan ID',
            dataIndex: 'LoanID',
            key: 'LoanID',
        },
        {
            title: 'Borrower',
            dataIndex: 'Borrower',
            key: 'Borrower',
        },
        {
            title: 'Release Date',
            dataIndex: 'Release_date',
            key: 'Release_date',
        },
        {
            title: 'Applied Amount',
            dataIndex: 'Applied_Amount',
            key: 'Applied_Amount',
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            render: (e, { Status }, ind) => {
                let color;
                if (Status === 'Pending') {
                    color = 'red';
                }
                else if (Status === 'Approved') {
                    color = 'blue';
                }
                else if (Status === 'Completed') {
                    color = 'green';
                }
                else if (Status === 'Declined') {
                    color = 'purple';
                }
                return (
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "68%" }}>
                        <Tag color={color}>
                            {Status.toUpperCase()}
                        </Tag>
                        <div style={{ display: (userType !== "Admin") ? "none" : "block" }}>
                            <div style={{ display: (Status.toUpperCase() === "PENDING" || Status.toUpperCase() === "DECLINED" ? "block" : "none") }}>
                                <Button onClick={() => handleLoanApprove(ind, "Approved")}>Approve</Button>
                                {Status.toUpperCase() !== "DECLINED" ? <Button onClick={() => handleLoanApprove(ind, "Declined")}>Decline</Button> : <></>}
                            </div>
                        </div>

                    </div>
                );
            }


        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                // <Space size="middle">
                //     <Flex className={record.TNo.toString()} align="flex-start" gap="small" vertical>
                //         <Dropdown.Button type="primary" menu={{ items,onClick: onMenuClick(record.TNo.toString())}}>Actions</Dropdown.Button>
                //     </Flex>
                // </Space>
                <Dropdown menu={{ items, onClick: onMenuClick(record?.UniqueLoanID?.toString()??'') }}>
                    <Button type='primary'>
                        <Space>
                            Action
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>

            ),
        },
    ];

    useEffect(() => {
        axios.get(`${BackendRootStrings.apiHome}/getAllLoanDataWithStatus`)
            .then((response: { data: { message: LoanDataType[] } }) => {


                setData(response.data.message.reverse());
                data = response.data.message;
                setFilterData(data);
                setTempSearchDataArray(data)
                setRenderTableKey(Math.random());
            })
            .catch((error: AxiosError) => {
                console.error('Error:', error.message);
            });
    }, [])


    const handleAddLoan = () => {
        navigate(RouteStrings.AddLoan, {
            state: {
                path: 'AddLoan',
            }
        });
    }

    const handleMainFilter = () => {
        const tempData = data.filter((loan: LoanDataType) => loan.Status.toLowerCase() === currentFilterValue?.toLowerCase() ?? '');

        setFilterData(tempData);
        setTempSearchDataArray(tempData)
        setRenderTableKey(Math.random());
        if (!tempData.length && currentFilterValue === "All") {
            setFilterData(data);
        }
    };

    useEffect(() => {
        if(currentFilterValue==='All'){
            setTempSearchDataArray(data);
        }
        else{
            handleMainFilter();
        }
        
    }, [currentFilterValue])



    useEffect(() => {
        if (searchText === null || searchText === undefined || searchText === "") {
            // setTempSearchDataArray([]);
            setTempSearchDataArray(data)
        }
        else {

            setTempSearchDataArray((prevstate) => {

                let var1 = data.filter(
                    (info) => {
                        return (info.Borrower?.toLowerCase().includes(searchText.toLowerCase()) || info.LoanID?.toString().includes(searchText))
                    }

                );
                return var1;
            })


        }
    }, [searchText])




    return (
        <>
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}> <h3>All Loans</h3></div>
            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                <div style={{width:"15%",display:"flex",flexDirection:"column"}}>
                    <span style={{fontWeight:"bold"}}>Search</span>
                    <Input onChange={(e)=>{setSearchText(e.target.value)}} placeholder='Enter Account No or Name'/>
                </div>
                <div style={{display:"flex",flexDirection:"row",width:"20%"}}>
                    <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", flexDirection: "column" }}>
                        <Select
                            showSearch
                            placeholder="Loan Product"
                            optionFilterProp="children"
                            onChange={(e) => setCurrentFilterValue(e)}
                            style={{ width: "100%", height: "100%" }}
                            defaultValue={"All"}
                            options={[
                                {
                                    label: "All",
                                    value: "All"
                                },
                                {
                                    label: "Pending",
                                    value: "Pending"
                                },
                                {
                                    label: "Approved",
                                    value: "Approved"
                                },
                                {
                                    label: "Completed",
                                    value: "Completed"
                                },
                            ]}
                        />
                    </div>
                    <Button style={{ backgroundColor: ColorCode.GreenCode, color: "white", fontWeight: "bold", marginLeft: 5 }} onClick={handleAddLoan}>Add Loan</Button>

                </div>
                </div>
            <Table key={renderTableKey} columns={columns} pagination={{ position: ['topLeft'] }} dataSource={tempSearchDataArray} />
        </>

    )
}

export default App;