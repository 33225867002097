import React, { useEffect, useState } from 'react';
import { Badge, Descriptions } from 'antd';
import type { DescriptionsProps } from 'antd';
import { useLocation } from 'react-router-dom';



const App: React.FC = () => {

  const location = useLocation();

  interface viewValueInterface {
    LoanID: string
    Borrower: string
    Member_No: string
    Release_date: string
    Applied_Amount: string
    Status: string
  }
  let [viewValues, setViewValues] = useState<viewValueInterface>();
  const [renderKey, setRenderKey] = useState(Math.random());



  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'LoanID',
      children: viewValues?.LoanID ?? '',
    },
    {
      key: '2',
      label: 'Borrower',
      children: viewValues?.Borrower ?? '',
    },
    // {
    //   key: '3',
    //   label: 'Member No',
    //   children: viewValues?.Member_No??'',
    // },
    {
      key: '4',
      label: 'Release Date',
      children: viewValues?.Release_date ?? '',
    },
    {
      key: '5',
      label: 'Applied Amount',
      children: '₹' + parseFloat(viewValues?.Applied_Amount ?? '').toLocaleString('en-IN') ?? '',
      span: 2,
    },
    {
      key: '6',
      label: 'Status',
      children: viewValues?.Status ? (
        viewValues.Status === 'Pending' ? (
          <Badge status="error" text={viewValues.Status} />
        ) : viewValues.Status === 'Approved' ? (
          <Badge status="processing" text={viewValues.Status} />
        ) : (
          viewValues.Status === 'Declined' ? (
            <Badge color='purple' text={viewValues.Status} />
          ) :
            <Badge status="success" text={viewValues.Status} />
        )
      ) : (
        <Badge status="default" text="Not Available" />
      ),
      span: 3,
    },

  ];


  useEffect(() => {
    // console.log(location.state.currentItem[0]);
    setViewValues(location.state.currentItem[0]);
    viewValues = location.state.currentItem[0];
    setRenderKey(Math.random());

  }, [])


  return (
    <Descriptions key={renderKey} title="View Loan Details" bordered items={items} column={1} />
  )
}

export default App;