import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { BackendRootStrings } from '../Routes'
import { Button, Input, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useDispatch, useSelector } from 'react-redux'
import { setDepositLateFeesAmount } from '../../redux/actions/ProductActions'
import { CSVLink } from 'react-csv'
import { useReactToPrint } from 'react-to-print'
import generatePDF, { Margin } from 'react-to-pdf'
import { ColorCode } from '../../resources/ColorCodes'



const LateFees = () => {

    const printRef = useRef<any>();

    const dispatch = useDispatch();

    let [isPdfTriggered, setIsPdfTriggered] = useState(false);
    const [isPrintTriggered, setIsPrintTriggered] = useState(false);
    const [renderKey, setRenderKey] = useState(Math.random());
    const [searchText, setSearchText] = useState("");





    const products: { allProducts: { depositLateFees: number } } = useSelector((state: { allProducts: { depositLateFees: number } }) => state);


    interface LateFeesInfoInterface {
        TNo: String,
        Name: String,
        Fees: String
    }

    let [totalValue, setTotalValue] = useState(0);


    const [LateFeesInfo, setLateFeesInfo] = useState<LateFeesInfoInterface[]>([{ Name: "", Fees: "", TNo: "" }]);

    const [tempSearchDataArray, setTempSearchDataArray] = useState<LateFeesInfoInterface[]>([]);
    useEffect(() => {


        axios.get(`${BackendRootStrings.apiHome}/getLateFessDetailsOfUsers`)
            .then(({ data }) => {
                const { message } = data;
                setLateFeesInfo([]);
                setTempSearchDataArray([])
                setTotalValue(0);
                totalValue = 0;
                message.map((obj: {
                    Name: string;
                    TNo: number;
                    Fees: number[];
                    Date: string
                }, ind: number) => {
                    for (let i = 0; i < obj?.Fees?.length??0; i++) {
                        // console.log(obj.Name, obj.TNo, obj?.Fees[i])
                        if (obj.Fees[i].toString() !== "" && obj.Fees[i] !== 0) {
                            setTotalValue(totalValue + parseFloat(obj?.Fees[i].toString()));
                            totalValue += parseFloat(obj?.Fees[i].toString());
                            console.log(totalValue)
                            setLateFeesInfo((prevState) => [
                                ...prevState,
                                {
                                    Name: obj?.Name.toString(),
                                    TNo: obj?.TNo.toString(),
                                    Fees: obj?.Fees[i].toString(),
                                    Date: new Date(obj?.Date).toDateString()
                                },
                            ]);
                            setTempSearchDataArray((prevState) => [
                                ...prevState,
                                {
                                    Name: obj?.Name.toString(),
                                    TNo: obj?.TNo.toString(),
                                    Fees: obj?.Fees[i].toString(),
                                    Date: new Date(obj?.Date).toDateString()
                                },
                            ]);
                        }


                    }

                })
            })

    }, [products])

    useEffect(() => {
        dispatch(setDepositLateFeesAmount(totalValue));

    }, [totalValue])


    const columns: ColumnsType<LateFeesInfoInterface> = [
        {
            title: 'TNo',
            dataIndex: 'TNo',
            key: 'TNo',
        },
        {
            title: 'Name',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: 'Date',
            dataIndex: 'Date',
            key: 'Date',
        },
        {
            title: 'Reg.Fees',
            dataIndex: 'Fees',
            key: 'Fees',
            render:(val)=><span>₹ {val}</span>
        },
    ];

    const headers = [
        {
            label: "TNo", key: "TNo"
        },
        {
            label: "Name", key: "Name"
        },
        {
            label: "Date", key: "Date"
        },
        {
            label: "Fees", key: "Fees"
        },

    ]

    const csvLink = {
        filename: `Registration Fees.csv`,
        headers: headers,
        data: tempSearchDataArray,
    }


    const options = {
        // default is `save`
        method: "save" as const,
        filename: `Registration Fees.pdf`,
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        // resolution: Resolution.HIGH,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            format: 'A4',
            // default is 'portrait'
            orientation: "portrait" as const,
        },
        // canvas: {
        //    // default is 'image/jpeg' for better size performance
        //    mimeType: 'image/png',
        //    qualityRatio: 1
        // },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break, 
        // so use with caution.
        // overrides: {
        //    pdf: {
        //       compress: true
        //    },
        //    canvas: {
        //       useCORS: true
        //    }
        // },
    };

    const handlePDFPrint = () => {
        generatePDF(printRef, options);
    };


    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    useEffect(() => {
        setRenderKey(Math.random());
        if (isPdfTriggered === true) {
            handlePDFPrint();
            setIsPdfTriggered(false);
        }
    }, [isPdfTriggered])

    useEffect(() => {
        setRenderKey(Math.random());
        if (isPrintTriggered === true) {
            handlePrint();
            setIsPrintTriggered(false);
        }
    }, [isPrintTriggered])

    useEffect(() => {
        if (searchText === null || searchText === undefined || searchText==="") {
            // setTempSearchDataArray([]);
            setTempSearchDataArray(LateFeesInfo)
        }
        else {

            setTempSearchDataArray((prevstate) => {

                let var1 = LateFeesInfo.filter(
                    (info) => {
                        return (info.Name?.toLowerCase().includes(searchText.toLowerCase()) || info.TNo.includes(searchText) )
                    }

                );
                return var1;
            })


        }
    }, [searchText])




    return (

        <div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                <div style={{ width: "9%", display: "flex", justifyContent: "space-between", marginTop: 20, alignItems: "center" }}>
                    <CSVLink {...csvLink} style={{ display: "flex", textDecoration: "none", color: "black", backgroundColor: "white", height: 32, borderRadius: 5, justifyContent: "center", alignItems: "center" }}><Button style={{backgroundColor:"gray",color:"white"}}>Excel</Button></CSVLink>
                    <Button style={{backgroundColor:"red",color:"white"}} onClick={() => { setRenderKey(Math.random()); setIsPdfTriggered(true) }}>PDF</Button>
                    <Button style={{backgroundColor:"green",color:"white"}} onClick={() => { setRenderKey(Math.random()); setIsPrintTriggered(true) }}>Print</Button>
                </div>
                <div style={{ width: "15%", display: "flex", justifyContent: "space-evenly", margin: "5px 0px", flexDirection: "row", border: `2px solid ${ColorCode.GreenCode}`, borderRadius: 10, alignItems: "center" }}>
                    {/* <span >SELECT MONTH</span> */}
                    <Input
                        style={{ width: "100%", height: "100%" }}
                        onChange={(e) => { setSearchText(e.target.value) }}
                        placeholder='Enter Name or TNo'
                    />


                </div>
            </div>
            <div key={renderKey} ref={printRef}>
                <div style={{ display: "flex", width: "100%"}}>
                    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", flexDirection: "column", alignItems: "center" }}>
                        <h3>HOSPITAL OFFICE FUND</h3>
                        <div style={{ width: 186, textAlign: "center" }}><h3>Registration Fees</h3></div>
                    </div>



                </div>

                <Table columns={columns} pagination={isPrintTriggered || isPdfTriggered ? false : { position: ['topLeft'] }} dataSource={tempSearchDataArray} />

            </div>

        </div>
    )
}

export default LateFees