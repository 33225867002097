import React, { useEffect, useState } from 'react';
import { Button, DatePicker, DatePickerProps, Input, Select, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { ColorCode } from '../../resources/ColorCodes';
import { RangePickerProps } from 'antd/es/date-picker';

//Table data interface
interface TableDataInterface {
    Date: String;
    Principal_Amount: String;
    Interest: String;
    Penalty: String;
    Amount_to_Pay: String;
    Balance: String;
}

//Input data Interface
interface InputDataInterface {
    Applied_Amount: String;
    Interest: String;
    // Interest_Type: String;
    Term: String;
    Term_Period: String;
    First_Payment_Date: String;
    Late_Penalty: String;

}



const columns: ColumnsType<TableDataInterface> = [
    {
        title: 'Date',
        dataIndex: 'Date',
        key: 'Date',

    },
    {
        title: 'Principal Amount(₹)',
        dataIndex: 'Principal_Amount',
        key: 'Principal_Amount',

    },
    {
        title: 'Interest (₹)',
        dataIndex: 'Interest',
        key: 'Interest',
    },
    {
        title: 'Late Penalty(₹)',
        dataIndex: 'Penalty',
        key: 'Penalty',
    },
    {
        title: 'Amount to Pay(₹)',
        dataIndex: 'Amount_to_Pay',
        key: 'Amount_to_Pay',
    },
    {
        title: 'Balance(₹)',
        dataIndex: 'Balance',
        key: 'Balance',
    },

];




const App: React.FC = () => {

    const [inputData, setInputData] = useState<InputDataInterface>({  First_Payment_Date: "", Applied_Amount: "", Interest: "", Late_Penalty: "",  Term: "", Term_Period: "Yearly" });
    let [tableData, setTableData] = useState<TableDataInterface[]>([]);
    let [unpaidDates, setUnpaidDates] = useState("");







    const handleTableDataPopulate = () => {

        // let loanDataPaymentMonths = data.Payment_Months[0];
        // let loanData = data;
        setTableData([]);
        tableData = [];

        unpaidDates = inputData.First_Payment_Date.toString();
        for (let i = 0; i < parseFloat(inputData.Term.toString()); i++) {
            let currentDate = moment(unpaidDates);
            currentDate.add(1, 'months');
            let termApplied=(inputData.Term_Period==="Monthly")?(parseFloat(inputData.Term.toString())*12):(parseFloat(inputData.Term.toString()));
            setUnpaidDates(currentDate.toString());
            unpaidDates = currentDate.toString();
            let currentPrincipalAmount=(parseFloat(inputData.Applied_Amount.toString()) / termApplied).toFixed(2);
            
            let amountInterest = (parseFloat(inputData?.Applied_Amount.toString()) * parseFloat(inputData?.Interest.toString()) / 100);
            console.log(parseFloat(inputData?.Applied_Amount.toString()) + amountInterest);
            let nextMonth = currentDate.format('ddd MMM DD YYYY');
            // let penalty=currentDate.isBefore(moment()) ? inputData.Late_Penalty : "0";
            let penalty=inputData.Late_Penalty;
            let balance = parseFloat((parseFloat(inputData?.Applied_Amount.toString()) + amountInterest -((parseFloat(inputData.Applied_Amount.toString()) / termApplied)+ (parseFloat(currentPrincipalAmount) * parseFloat(inputData?.Interest.toString()) / 100))*(i+1)).toFixed(2)).toString();
            setTableData(prevState => [
                ...prevState,
                {
                    Amount_to_Pay:((parseFloat(inputData.Applied_Amount.toString()) / termApplied)+ (parseFloat(currentPrincipalAmount) * parseFloat(inputData?.Interest.toString()) / 100)).toFixed(2).toString(),
                    Date: nextMonth,
                    Principal_Amount: ((parseFloat(inputData.Applied_Amount.toString()) / termApplied).toFixed(2)).toString(),
                    Interest:(parseFloat(currentPrincipalAmount) * parseFloat(inputData?.Interest.toString()) / 100).toFixed(2).toString(),
                    Penalty: (parseFloat(penalty.toString())*parseFloat(currentPrincipalAmount)/100).toString(),
                    Balance: balance,
                }
            ]);
        }


        // setUnpaidDates(loanDataPaymentMonths?.Repayment_Date.toString());







        // console.log(trandformedData, "transformed data")
    }



    const handleValueChange = (value: string, field: string) => {
        setInputData((prevState) => {
            return {
                ...prevState,
                [field]: value
            }

        })
    }

    const handleDateChange = (value: DatePickerProps['value'] | RangePickerProps['value'], field: string) => {
        setInputData((prevState: InputDataInterface) => {
            return {
                ...prevState,
                [field]: value?.toString() ?? new Date().toString()
            };
        })
        // console.log('onOk: ', value?.toString());
    };


    const handleCalculate = () => {
        let flag = false;
        Object.keys(inputData).map((obj, ind) => {
            let value=inputData[obj as keyof InputDataInterface];
            if ( value=== "" || value===undefined || value===null) {
                flag = true;
            }
        })
        if (!flag) {
            handleTableDataPopulate();
        }

    }






    return (
        <>
            <div style={{ width: "100%", height: 100, display: "flex", justifyContent: "center" }}>
                <div style={{ width: "90%", display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <h1 style={{ height: "fit-content", margin: 0 }}>Loan Calculator</h1>

                </div>
            </div>
            <div style={{ height: "30%", width: "100%", display: "flex", border: "1px solid", flexDirection: "column", justifyContent: "space-evenly" }}>
                <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                    <div style={{ display: "flex", flexDirection: "column", width: "20%" }}><span>Applied Amount (₹)</span><Input style={{ height: "calc(1.8em + 1.6rem + 2px)" }} onChange={(e) => handleValueChange(e.target.value, "Applied_Amount")} /></div>
                    <div style={{ width: "20%", display: "flex", flexDirection: "column" }}><span>Interest Rate (%)</span><Input style={{ height: "calc(1.8em + 1.6rem + 2px)" }} onChange={(e) => handleValueChange(e.target.value, "Interest")} /></div>
                    {/*<div style={{ width: "20%", }}><span>Interest Type</span><Select
                        style={{ width: "100%", height: "calc(1.8em + 1.6rem + 2px)" }}
                        onChange={(value) => handleValueChange(value, "Interest_Type")}
                        options={[
                            { value: 'Flat', label: 'Flat' },
                            { value: 'Fixed', label: 'Fixed' },
                        ]}
                    /></div>*/}
                    <div style={{ width: "20%" }}><span>Term</span><Input style={{ height: "calc(1.8em + 1.6rem + 2px)" }} min={0} max={12} onChange={(e) => handleValueChange(e.target.value, "Term")} /></div>

                </div>
                <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                    {/* <div style={{ width: "20%", }}><span>Term Period</span><Select
                        style={{ width: "100%", height: "calc(1.8em + 1.6rem + 2px)" }}
                        onChange={(value) => handleValueChange(value, "Term_Period")}
                        options={[
                            { value: 'Monthly', label: 'Monthly' },
                            { value: 'Yearly', label: 'Yearly' },
                        ]}
                    /></div> */}
                    <div style={{ width: "20%", }}><span>First Payment Date</span> <DatePicker style={{ width: "100%", height: "calc(1.8em + 1.6rem + 2px)" }} onChange={(value: DatePickerProps['value'] | RangePickerProps['value']) => handleDateChange(value, "First_Payment_Date")} /></div>
                    <div style={{ width: "20%", }}><span>Late Penalties (%)</span><Input min={0} max={100} style={{ height: "calc(1.8em + 1.6rem + 2px)" }} onChange={(e) => handleValueChange(e.target.value, "Late_Penalty")} /></div>
                    <div style={{ width: "20%", marginTop: 20 }}><Button onClick={handleCalculate} style={{ width: "100%", height: "calc(1.8em + 1.6rem + 2px)", backgroundColor: ColorCode.GreenCode, fontWeight: "bold", color: "white" }} >Calculate</Button></div>

                </div>
            </div>
            <Table columns={columns} dataSource={tableData} />
        </>
    )
}

export default App;