import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { BackendRootStrings } from '../Routes';
import type { ColumnsType } from 'antd/es/table';
import { Button, Select, Table } from 'antd';
import { CSVLink } from 'react-csv';
import { ColorCode } from '../../resources/ColorCodes';
import { useReactToPrint } from 'react-to-print';

import generatePDF, { Margin} from 'react-to-pdf';

const DefaulterList = () => {

  interface defaulterListInterface {
    TNo: String,
    First_Name: String,
    Last_Name: String,
    Phone_no: String,
    SlNo: string
  }

  interface NewObject {
    label: string;
    value: any;
  }

  // const targetRef = React.createRef<any>();
  const printRef = useRef<any>();
  let [isPdfTriggered, setIsPdfTriggered] = useState(false);
  const [isPrintTriggered, setIsPrintTriggered] = useState(false);

  const [renderKey, setRenderKey] = useState(Math.random());

  const [currentSelectedMonth, setCurrentSelectedMonth] = useState("");


  const [defaulterList, setDefaulterList] = useState<defaulterListInterface[]>([{ TNo: '', First_Name: '', Last_Name: '', Phone_no: '', SlNo: '' }]);

  const monthArr = ['September', 'October', 'November', 'December', 'January', 'Febuary', 'March', 'April', 'May', 'June', 'July', 'August'];

  const [defaulterListMonth, setDefaulterListMonth] = useState<{ value: string, label: string }[]>();

  useEffect(() => {
    axios.get(`${BackendRootStrings.apiHome}/getDefaulterListOfPrevMonth`)
      .then((response) => {

        setDefaulterList(response.data.message)
      })
  }, [])

  const columns: ColumnsType<defaulterListInterface> = [
    {
      title: 'SlNo',
      dataIndex: 'SlNo',
      key: 'SlNo',
    },
    {
      title: 'Account No',
      dataIndex: 'TNo',
      key: 'TNo',
    },
    {
      title: 'First Name',
      dataIndex: 'First_Name',
      key: 'First_Name',
    },
    {
      title: 'Last Name',
      dataIndex: 'Last_Name',
      key: 'Last_Name',
    },
    {
      title: 'Phone no',
      dataIndex: 'Phone_no',
      key: 'Phone_no',
    },
  ];

  const headers = [
    {
      label: "TNo", key: "TNo"
    },
    {
      label: "First_Name", key: "First_Name"
    },
    {
      label: "Last_Name", key: "Last_Name"
    },
    {
      label: "Phone_no", key: "Phone_no"
    },

  ]

  const csvLink = {
    filename: `DefaulterList - ${currentSelectedMonth}.csv`,
    headers: headers,
    data: defaulterList,
  }

  useEffect(() => {
    const newArray: NewObject[] = monthArr.map((obj: string) => ({
      label: obj,
      value: obj
    }));

    setDefaulterListMonth(newArray);
  }, [])


  const handleMonthChange = (value: string) => {
    // console.log(value);
    setCurrentSelectedMonth(value);
    axios.get(`${BackendRootStrings.apiHome}/getDefaulterListBySelectedMonth/${value.substring(0, 3)}`)
      .then((response) => {
        const modifiedArray = response.data.message.map((item: {
          TNo: String,
          First_Name: String,
          Last_Name: String,
          Phone_no: String,
        }, index: number) => ({
          ...item,
          SlNo: (index + 1).toString() // Adding index property to each object
        }));
        setDefaulterList(modifiedArray)

      })
  }

  const options = {
    // default is `save`
    method: "save" as const,
    filename: `DefaulterList - ${currentSelectedMonth}.pdf` ,
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    // resolution: Resolution.HIGH,
    page: {
       // margin is in MM, default is Margin.NONE = 0
       margin: Margin.SMALL,
       // default is 'A4'
       format: 'A4',
       // default is 'portrait'
       orientation: "portrait" as const,
    },
    // canvas: {
    //    // default is 'image/jpeg' for better size performance
    //    mimeType: 'image/png',
    //    qualityRatio: 1
    // },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break, 
    // so use with caution.
    // overrides: {
    //    pdf: {
    //       compress: true
    //    },
    //    canvas: {
    //       useCORS: true
    //    }
    // },
 };

  const handlePDFPrint = () => {
    generatePDF(printRef, options);
  };


  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    setRenderKey(Math.random());
    if (isPdfTriggered === true) {
      handlePDFPrint();
      setIsPdfTriggered(false);
    }
  }, [isPdfTriggered])

  useEffect(() => {
    setRenderKey(Math.random());
    if (isPrintTriggered === true) {
      handlePrint();
      setIsPrintTriggered(false);
    }
  }, [isPrintTriggered])




  return (
    <div style={{ height: "100%" }}>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
        <div style={{ width: "9%", display: "flex", justifyContent: "space-between", marginTop: 20, alignItems: "center" }}>
          <CSVLink {...csvLink} style={{ display: "flex", textDecoration: "none", color: "black", backgroundColor: "white", height: 32, borderRadius: 5, justifyContent: "center", alignItems: "center" }}><Button style={{backgroundColor:"gray",color:"white"}}>Excel</Button></CSVLink>
          <Button style={{backgroundColor:"red",color:"white"}} onClick={() => { setRenderKey(Math.random()); setIsPdfTriggered(true) }}>PDF</Button>
          <Button style={{backgroundColor:"green",color:"white"}} onClick={() => { setRenderKey(Math.random()); setIsPrintTriggered(true) }}>Print</Button>
        </div>
        <div style={{ width: "15%", display: "flex", justifyContent: "space-evenly", margin: "5px 0px", flexDirection: "row", border: `2px solid ${ColorCode.GreenCode}`, borderRadius: 10, alignItems: "center" }}>
          <span >SELECT MONTH</span>
          <Select
            style={{ width: "50%" }}
            onChange={(e) => handleMonthChange(e)}
            options={defaulterListMonth}
          />


        </div>
      </div>
      <div key={renderKey} ref={printRef}>
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ width: "100%",display:"flex",justifyContent:"center",flexDirection:"column",alignItems: "center" }}>
            <h3>HOSPITAL OFFICE FUND</h3>
            <h3>Month : {currentSelectedMonth}</h3>
            <div style={{width:186,textAlign:"center"}}><h3>Defaulter List</h3></div>
          </div>
          


        </div>

        <Table key={renderKey} columns={columns} pagination={isPrintTriggered || isPdfTriggered ? false : { position: ['topLeft'] }} dataSource={defaulterList} />
      </div>

    </div>

  )
}

export default DefaulterList