export const ActionTypes = {
    SET_TOTAL_COLLECTION: "SET_TOTAL_COLLECTION",
    SET_COUNTER_COLLECTION: "SET_COUNTER_COLLECTION",
    SET_SELECTED_MENU_ITEM_TOKEN: "SET_SELECTED_MENU_ITEM_TOKEN",
    SET_TOTAL_COLLECTION_AMOUNT: "SET_TOTAL_COLLECTION_AMOUNT",
    CASH_IN_HAND: "CASH_IN_HAND",
    SET_DEPOSIT_LATE_FEES: "SET_DEPOSIT_LATE_FEES",
    SET_CURRENT_COUNTER_NO: "SET_CURRENT_COUNTER_NO",
    SET_COUNTER_BALANCE: "SET_COUNTER_BALANCE",
    SET_COUNTER_PERMISSION: "SET_COUNTER_PERMISSION",
}