import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, Descriptions, Select } from 'antd';
import type { DescriptionsProps } from 'antd';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { BackendRootStrings } from '../Routes';
import { ColorCode } from '../../resources/ColorCodes';
import generatePDF, { Margin } from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';
import { CSVLink } from 'react-csv';



const App: React.FC = () => {

    const location = useLocation();
    const printRef = useRef<any>();

    interface viewValueInterface {
        Deposit: string,
        loan:string,
        loanRepayment: string,
        LoanInterest: string,
        RegistrationFees: string,
        loanLateFine: string,
        total: string
    }

    interface NewObject {
        label: string;
        value: any;
    }

    let [viewValues, setViewValues] = useState<viewValueInterface>({ Deposit: "", loanRepayment: "", loanLateFine: "", RegistrationFees: "", LoanInterest: "", total: "",loan:'' });
    const [renderKey, setRenderKey] = useState(Math.random());
    let [isPdfTriggered, setIsPdfTriggered] = useState(false);
    const [isPrintTriggered, setIsPrintTriggered] = useState(false);

    const monthArr = ['September', 'October', 'November', 'December', 'January', 'Febuary', 'March', 'April', 'May', 'June', 'July', 'August'];

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];


    const [collectionMonthList, setCollectionMonthList] = useState<{ value: string, label: string }[]>();

    const [selectedMonth, setSelectedMonth] = useState(" "+new Date().getFullYear());

    useEffect(() => {
        const newArray: NewObject[] = monthArr.map((obj: string) => ({
            label: obj,
            value: obj
        }));

        setCollectionMonthList(newArray);
    }, [])



    const items: DescriptionsProps['items'] = [
        {
            key: '1',
            label: 'Total Deposit',
            children: '₹ ' + viewValues?.Deposit ?? '',
        },
        {
            key: '7',
            label: 'Total Loan',
            children: '₹ ' + viewValues?.loan ?? '',
        },
        {
            key: '2',
            label: 'Total Loan Repayment',
            children: '₹ ' + viewValues?.loanRepayment ?? '',
        },
        {
            key: '3',
            label: 'Total Loan Interest',
            children: '₹ ' + viewValues?.LoanInterest ?? '',
        },
        {
            key: '4',
            label: 'Total Registration Fees',
            children: '₹ ' + viewValues?.RegistrationFees ?? '',
        },
        {
            key: '5',
            label: 'Total Loan Late Fine',
            children: '₹ ' + parseFloat(viewValues?.loanLateFine ?? ''),
            span: 2,
        },
        // {
        //     key: '6',
        //     label: 'Balance',
        //     children: '₹ ' + parseFloat(viewValues?.total ?? ''),
        //     span: 2,
        // },


    ];


    useEffect(() => {
        axios.get(`${BackendRootStrings.apiHome}/finalListData`)
            .then(({ data }) => {
                const { message } = data;
                setViewValues((prevState) => {
                    return {
                        ...prevState,
                        Deposit: message.totalDeposits,
                        loan:message.loan,
                        loanRepayment: message.totalRepaymentsPrincipalAmount,
                        LoanInterest: message.totalInterest,
                        RegistrationFees: message.totalRegistrationFees,
                        loanLateFine: message.totalLateFine,
                        total: (message.totalDeposits+message.totalRepaymentsPrincipalAmount + message.totalInterest + message.totalRegistrationFees + message.totalLateFine-message.loan).toString()
                    }
                })
            })
        setRenderKey(Math.random());

    }, [])

    const headers = [
        {
            label: "Deposit", key: "Deposit"
        },
        {
            label: "Total Loan", key: "loan"
        },
        {
            label: "Loan Repayment", key: "loanRepayment"
        },
        {
            label: "Loan Interest", key: "LoanInterest"
        },
        {
            label: "Loan Late Fine", key: "loanLateFine"
        },
        {
            label: "Registration Fees", key: "RegistrationFees"
        },
        
        // {
        //     label: "TOTAL", key: "total"
        // },
    ]

    const csvLink = {
        filename: `Balance Sheet- ${selectedMonth}.csv`,
        headers: headers,
        data: [viewValues],
    }

    const options = {
        // default is `save`
        method: "save" as const,
        filename: `Balance Sheet - ${selectedMonth}.pdf`,
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        // resolution: Resolution.HIGH,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            format: 'A4',
            // default is 'portrait'
            orientation: "portrait" as const,
        },
        // canvas: {
        //    // default is 'image/jpeg' for better size performance
        //    mimeType: 'image/png',
        //    qualityRatio: 1
        // },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break, 
        // so use with caution.
        // overrides: {
        //    pdf: {
        //       compress: true
        //    },
        //    canvas: {
        //       useCORS: true
        //    }
        // },
    };

    const handlePDFPrint = () => {
        generatePDF(printRef, options);
    };


    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    useEffect(() => {
        setRenderKey(Math.random());
        if (isPdfTriggered === true) {
            handlePDFPrint();
            setIsPdfTriggered(false);
        }
    }, [isPdfTriggered])

    useEffect(() => {
        setRenderKey(Math.random());
        if (isPrintTriggered === true) {
            handlePrint();
            setIsPrintTriggered(false);
        }
    }, [isPrintTriggered])





    return (
        <>
            <div style={{ display: "flex" }}>
                <div style={{ width: "50%", display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "9%", display: "flex", justifyContent: "space-between", marginTop: 20, alignItems: "center" }}>
                        <CSVLink {...csvLink} style={{ display: "flex", textDecoration: "none", color: "black", backgroundColor: "white", height: 32, borderRadius: 5, justifyContent: "center", alignItems: "center" }}><Button style={{ backgroundColor: "gray", color: "white" }}>Excel</Button></CSVLink>
                        <Button style={{ backgroundColor: "red", color: "white" }} onClick={() => { setRenderKey(Math.random()); setIsPdfTriggered(true) }}>PDF</Button>
                        <Button style={{ backgroundColor: "green", color: "white" }} onClick={() => { setRenderKey(Math.random()); setIsPrintTriggered(true) }}>Print</Button>
                    </div>
                    <div>
                        <h3 style={{textAlign:'center'}}>HOSPITAL OFFICE FUND</h3>
                        <h3 style={{textAlign:'center'}}>Balance Sheet - {selectedMonth}</h3>
                    </div>

                </div>


            </div>
            <div key={renderKey} ref={printRef}>
                <h3 style={{ textAlign: "center", display: (isPrintTriggered || isPdfTriggered) ? "block" : "none" }}>HOSPITAL OFFICE FUND</h3>
                <h3 style={{ textAlign: "center", display: (isPrintTriggered || isPdfTriggered) ? "block" : "none" }}>Balance Sheet {selectedMonth}</h3>
                <Descriptions key={renderKey} title="" bordered items={items} column={1} />
            </div>

        </>

    )
}

export default App;