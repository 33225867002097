export const RouteStrings= {
        HomePage: "/",
        UserRegister:'/userRegister',
        EditUser:'/editUser',
        ViewUser:'/viewUser',
        Loans:'/loans',
        ViewLoans:'/viewLoans',
        AddLoan:'/addLoan',
        EditLoan:'/editLoan',
        AllLoanProduct:'/allLoanProduct',
        AddLoanProduct:'/addLoanProduct',
        AddLoanRepayment:'/addLoanRepayment',
        ViewLoanRepayment:'/viewLoanRepayment',
        login:'/login',
        SuccessPage:'/successPage',
        ChangePassword:'/changePassword',
        EditProfile:"/editProfile"
    }
export const BackendRootStrings= {
        apiHome: "https://cooperative-backend.onrender.com",
        // apiHome: "http://localhost:5000",
        
    }


