import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button, Checkbox, DatePicker, Input, Select, Space, message ,Spin} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { CheckSquareOutlined } from '@ant-design/icons';
import { BackendRootStrings } from '../Routes';
import axios, { AxiosError } from 'axios';
import { ColorCode } from '../../resources/ColorCodes';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import SuccessPage from '../CreateAccount/SuccessPage';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

const DepositMain = () => {

  const navigate = useNavigate();

  const cookies = new Cookies();

  const userType = cookies.get('UserType');

  interface DataType {
    Date: String,
    Member: String,
    Account_No: String,
    Amount: String
    Status: String,
    Month: CheckboxValueType[],
    Counter: String,
    Description: String,
    NAME: String,
    Late_Fees: String
  }

  interface CounterListDataType {
    key: React.Key,
    First_Name: String,
    Last_Name: String,
    User_Type: String,
    Email: String,
  }

  interface OriginalObject {
    [key: string]: any;
  }

  interface NewObject {
    label: string;
    value: any;
  }

  interface CounterDataType {
    key: React.Key,
    "TNo": number,
    "SlNo": number,
    "NAME": string,
    "September": number,
    "October": number,
    "November": number,
    "December": number,
    "January": number,
    "February": number,
    "March": number,
    "April": number,
    "May": number,
    "June": number,
    "July": number,
    "August": number,
  }

  // interface CheckBoxInterface {
  //   January: null | number;
  //   February: null | number;
  //   March: null | number;
  //   April: null | number;
  //   May: null | number;
  //   June: null | number;
  //   July: null | number;
  //   August: null | number;
  //   September: null | number;
  //   October: null | number;
  //   November: null | number;
  //   December: null | number;
  // };

  interface CheckBoxInterface {
    [key: string]: boolean;
  }

  const products: { allProducts: { totalCollectionsArray: CounterDataType[], isCounterSelected: Boolean } } = useSelector((state: { allProducts: { totalCollectionsArray: CounterDataType[], isCounterSelected: Boolean } }) => state);

  const [submitData, setSubmitData] = useState<DataType>({ Date: new Date().toString(), Member: "", Account_No: "", Amount: "", Status: "", Month: [], Counter: "", Description: "", NAME: "", Late_Fees: "" });

  useEffect(() => {
    if (localStorage.getItem('paymentDate')!==null) {
      setSubmitData((prevState: DataType) => {
        return {
          ...prevState,
          Date: localStorage.getItem('paymentDate') || "" // Ensure a string value is assigned
        };
      })
    }
    
  }, [])
  


  const [checkBoxDisabledArray, setCheckBoxDisabledArray] = useState<CheckBoxInterface>({});

  const [loading, setLoading] = useState<boolean>(false);

  const [memberAndAccountList, setMemberAndAccountList] = useState<NewObject[]>();

  let [isRegistrationDisabled, setIsRegistrationDisabled] = useState(false);

  const [counterList, setCounterList] = useState<NewObject[]>([]);

  const [formSuccess, setFormSuccess] = useState(false);

  const [defaultCounter, setDefaultCounter] = useState("");

  const CheckboxGroup = Checkbox.Group;

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const monthArr = ['Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'];

  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setSubmitData((prevState: DataType) => {
      return {
        ...prevState,
        Month: list
      };

    })

  };

  // useEffect(() => {
  //   if (checkBoxDisabledArray !== null) {
  //     const disabledMap:CheckBoxInterface = {};
  //     for (const monthKey of Object.keys(checkBoxDisabledArray)) {
  //       const month = monthKey as keyof CheckBoxInterface; // Ensure type safety
  //       console.log(month)
  //       disabledMap[month] = true;
  //     }
  //     setCheckBoxDisabledArray(disabledMap);
  //   }
  // }, [checkBoxDisabledArray])




  const onSelectChange = async (value: string, field: string) => {
    let filterData = memberAndAccountList?.filter((item) => item.value === parseInt(value)) ?? [];

    if (field === 'Member') {
      // const filterData = products.allProducts.totalCollectionsArray.filter((item) => item.TNo == parseInt(value));
      // // setCheckBoxDisabledArray()
      // console.log(filterData[0]);
      // setCheckBoxDisabledArray((prevState) => {
      //   Object.keys(filterData[0]).map((item: string, ind) => {
      //     if (Object.prototype.hasOwnProperty.call(prevState, item)) {
      //       prevState[item as keyof CheckBoxInterface] = filterData[0][item as keyof CheckBoxInterface];
      //       // console.log(item);
      //     }
      //   });
      //   console.log(prevState);
      //   return prevState;
      // })

      // const res=await axios.get(`${BackendRootStrings.apiHome}/getRegistrationFeesById/${value}`);

      

      setCheckBoxDisabledArray({});
      setLoading(true);
      await axios.get(`${BackendRootStrings.apiHome}/getMasterDataByUserID/${filterData[0].value}`)
        .then(({ data }) => {
          const { message } = data;
          // console.log(message);
          
          Object.keys(message).map((obj, ind) => {
            if (obj !== "TNo" && message[obj] !== 0) {
              let monthSub = obj.substring(0, 3);
              setCheckBoxDisabledArray(prevState => ({
                ...prevState,
                [monthSub]: true
              }));


            }
          })
        })
        setLoading(false);


      let name = filterData[0]?.label.trim().split('-')[0] ?? '';

      setSubmitData((prevState: DataType) => {
        return {
          ...prevState,
          NAME: name.substring(0, name.length - 1) ?? '',
          Account_No: value.toString()
        }
      })

      //  axios.post(`${BackendRootStrings.apiHome}/verifyRegistration`,filterData)
      // .then(({data})=>{
      //   const {message}=data;
      //   console.log(message)
      //   setIsRegistrationDisabled(message);
      //   isRegistrationDisabled=message;
      // })
    }
    setSubmitData((prevState: DataType) => {
      return {
        ...prevState,
        [field]: value
      };
    })
  };
  const handleTextChange = (e: any, field: string) => {
    setSubmitData((prevState: DataType) => {
      return {
        ...prevState,
        [field]: e.target.value
      };
    })
  };

  const handleDateChange = (value: DatePickerProps['value'] | RangePickerProps['value'],dateString:any) => {
    localStorage.setItem("paymentDate",new Date(value?.toString()??'').toString());
    setSubmitData((prevState: DataType) => {
      return {
        ...prevState,
        Date: new Date(value?.toString()??'').toString()
      };
    })
    // console.log('onOk: ', new Date(value?.toString()??'').toString(),value?.toString());
  };

  const handleSubmit = () => {
    // console.log(submitData);
    setLoading(true);
    if (submitData.Amount === "" && submitData.Late_Fees === "") {
      message.info("Amount and Registration Fees both can't be empty!!!")
    }
    else if (submitData.Month.length !== 0 && submitData.Status !== "") {
    //  console.log(submitData);
    setLoading(true);
      axios.post(`${BackendRootStrings.apiHome}/updateDeposit`, submitData)
        .then((res) => {
          setTimeout(() => {
            setFormSuccess(false);
            if (userType.includes('Counter')) {
              setSubmitData((prevState) => {
                return {
                  ...prevState,
                  Counter: userType,
                  Amount:'',
                  Account_No:'',
                  Member:'',
                  Late_Fees:'',
                  Description:''
                }
              });
            }
            else if (cookies.get('defaultCounter')!=='') {
              setSubmitData((prevState) => {
                return {
                  ...prevState,
                  Counter: cookies.get('defaultCounter'),
                  Amount:'',
                  Account_No:'',
                  Member:'',
                  Late_Fees:'',
                  Description:''
                }
              });
            }
            else {
              setSubmitData((prevState) => {
                return {
                  ...prevState,
                  Counter: '',
                  Amount:'',
                  Account_No:'',
                  Member:'',
                  Late_Fees:'',
                  Description:''
                }
              });
            }


            setCheckedList([]);
            setLoading(false);

          }, 2000);
          setFormSuccess(true);

        })
        .catch((error: AxiosError) => {
          console.log(error);
        })
    }

  }




  useEffect(() => {
    
    axios.get(`${BackendRootStrings.apiHome}/getAllDepositData`)
      .then(({ data }) => {
        const { message } = data;
        // console.log("message is this ", message);

        const newArray: NewObject[] = message.map((obj: OriginalObject) => ({
          label: `${obj.NAME} - ${obj.TNo}`,
          value: obj.TNo
        }));
        // console.log(newArray)
        setMemberAndAccountList(newArray);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
      
  }, []);

  useEffect(() => {
    setLoading(true);
    axios.get(`${BackendRootStrings.apiHome}/getAllCounterDetails`)
      .then(({ data }) => {
        const { message } = data;
        // console.log("message is this ", message);

        let newArray: NewObject[] = message.map((obj: CounterListDataType) => ({
          label: `${obj.First_Name} - ${obj.Last_Name}`,
          value: obj.User_Type
        }));
        if (userType.toString().includes('Counter')) {
          newArray = newArray.filter((item) => item.value === userType);
        }

        // console.log(newArray)
        setCounterList(newArray);

      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
      setLoading(false);
  }, [])

  useEffect(() => {

    if (userType.toString().includes('Counter')) {
      setSubmitData((prevState) => {
        return {
          ...prevState,
          Counter: userType
        }
      })
    }

  }, [])

  const handleDefaultCounterChange = (value: string) => {
    cookies.set("defaultCounter", value);
    setDefaultCounter(value);
    setSubmitData((prevState) => {
      return {
        ...prevState,
        Counter: value
      }

    })
  }

  useEffect(() => {
    setDefaultCounter(cookies.get('defaultCounter'));
    if(userType.includes('Counter')){
      setSubmitData((prevState) => {
        return {
          ...prevState,
          Counter: userType
        }

      })
    }
    else if (cookies.get('defaultCounter') !== '') {
      setSubmitData((prevState) => {
        return {
          ...prevState,
          Counter: cookies.get('defaultCounter')
        }

      })
    }
  }, [])







  return (
    <div style={{ height: 900, width: "100%", display: "flex", justifyContent: "center" }}>
      
      {(!formSuccess) ? <div style={{ height: "80%", width: "65%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}> <div style={{ width: "50%", display: "flex", justifyContent: "flex-end" }}><h3>Deposit</h3>
        </div><div style={{ width: "50%", display: "flex", justifyContent: "flex-end" }}>
            <Select
              showSearch
              placeholder="Choose Counter"
              optionFilterProp="children"
              onChange={(e) => handleDefaultCounterChange(e)}
              filterOption={filterOption}
              style={{ width: "40%", height: "100%" }}
              options={counterList}
              value={defaultCounter !== '' ? defaultCounter : 'Choose Default Counter'}
              disabled={userType.toString().includes('Counter')}
            />
          </div></div>
        <div style={{ height: "7%", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div style={{ height: "100%", width: "10%", display: "flex", flexDirection: "row" }}>
            <p style={{ fontSize: 17 }}>Counter</p><p style={{ color: 'red' }}>*</p>
          </div>
          <div style={{ height: "100%", width: "80%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Select
              showSearch
              placeholder="Choose Counter"
              optionFilterProp="children"
              onChange={(e) => onSelectChange(e, 'Counter')}
              filterOption={filterOption}
              style={{ width: "80%", height: "100%" }}
              options={counterList}
              value={userType.toString().includes('Counter') ? counterList[0]?.label : (submitData.Counter !== "") ? submitData.Counter.toString() : "Choose Counter"}
              disabled={userType.toString().includes('Counter')}
            />
          </div>
        </div>
        <div style={{ height: "7%", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div style={{ height: "100%", width: "20%" }}>
            <p style={{ fontSize: 17 }}>Payment Date</p>
          </div>
          <div style={{ height: "80%", width: "80%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Space style={{ width: "80%", height: "100%" }} direction="vertical" size={12}>
              <DatePicker
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
                style={{ width: "100%", height: "192%" }}
                defaultValue={dayjs(submitData.Date.toString())}
                onChange={handleDateChange}
                value={dayjs(submitData.Date.toString())}
                allowClear={false}
              // disabled={ true}
              />
            </Space>
            
          </div>
        </div>
        <div style={{ height: "7%", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div style={{ height: "100%", width: "10%", display: "flex" }}>
            <p style={{ fontSize: 17 }}>Member</p><p style={{ color: 'red' }}>*</p>
          </div>
          <div style={{ height: "100%", width: "80%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Select
              showSearch
              placeholder="Select one"
              optionFilterProp="children"
              onChange={(e) => onSelectChange(e, 'Member')}
              filterOption={filterOption}
              style={{ width: "80%", height: "100%" }}
              options={memberAndAccountList}
              disabled={(submitData.Counter) ? false : true}
            />
          </div>
        </div>
        <div style={{ height: "7%", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div style={{ height: "100%", width: "17%", display: "flex" }}>
            <p style={{ fontSize: 17 }}>Account Number</p><p style={{ color: 'red' }}>*</p>
          </div>
          <div style={{ height: "100%", width: "80%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ height: "100%", width: "80%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Select
                showSearch
                placeholder="Choose Account Number"
                optionFilterProp="children"
                onChange={(e) => onSelectChange(e, 'Account_No')}
                filterOption={filterOption}
                value={submitData.Account_No.toString()}
                disabled={(submitData.Counter) ? false : true}
                style={{ width: "100%", height: "100%" }}
                options={[
                  {
                    value: submitData.Member.toString(),
                    label: submitData.Member.toString(),
                  }

                ]}
              />
            </div>
          </div>
        </div>
        <div style={{ height: "7%", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div style={{ height: "100%", width: "20%", display: "flex" }}>
            <p style={{ fontSize: 17 }}>Deposit Amount</p><p style={{ color: 'red' }}>*</p>
          </div>
          <div style={{ height: "100%", width: "80%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Input disabled={(submitData.Counter) ? false : true} style={{ width: "80%", height: "100%" }} placeholder="Enter Amount" onChange={(e) => handleTextChange(e, 'Amount')} />
          </div>
        </div>
        <div style={{ height: "7%", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div style={{ height: "100%", width: "20%" }}>
            <p style={{ fontSize: 17 }}>Registration Fees {isRegistrationDisabled}</p>
          </div>
          <div style={{ height: "100%", width: "80%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Input disabled={(!submitData.Counter) ? true : 
              isRegistrationDisabled} style={{ width: "80%", height: "100%" }} placeholder="Enter Registration Fees" onChange={(e) => handleTextChange(e, 'Late_Fees')} />
          </div>
        </div>
        
        <div style={{ height: "7%", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div style={{ height: "100%", width: "10%", display: "flex" }}>
            <p style={{ fontSize: 17 }}>Month</p><p style={{ color: 'red' }}>*</p>
          </div>
          <div style={{ height: "100%", width: "72%", display: "flex", justifyContent: "flex-start", }}>
            <div style={{ height: "100%", width: "90%" }}>
              {/* <CheckboxGroup disabled={true}  options={monthArr} value={checkedList} onChange={onChange} /> */}
              <Checkbox.Group value={checkedList} onChange={onChange} >
                {monthArr.map((month) => (
                  <Checkbox key={month} value={month} disabled={checkBoxDisabledArray[month]}
                  // disabled={(Object.keys(checkBoxDisabledArray).length!==0)?checkBoxDisabledArray[month as keyof CheckBoxInterface] ? true : false:true}
                  >
                    {month}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </div>

          </div>
        </div>

        <div style={{ height: "7%", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div style={{ height: "100%", width: "10%", display: "flex" }}>
            <p style={{ fontSize: 17 }}>Status</p><p style={{ color: 'red' }}>*</p>
          </div>
          <div style={{ height: "100%", width: "80%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Select
              showSearch
              placeholder="Select Status"
              optionFilterProp="children"
              onChange={(e) => onSelectChange(e, 'Status')}
              filterOption={filterOption}
              disabled={(submitData.Counter) ? false : true}
              style={{ width: "80%", height: "100%" }}
              options={[
                {
                  value: 'Pending',
                  label: 'Pending',
                },
                {
                  value: 'Completed',
                  label: 'Completed',
                },
              ]}
            />
          </div>
        </div>

        {/* <div style={{ height: "10%", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div style={{ height: "100%", width: "10%" }}>
            <h2>Enter Interest</h2>
          </div>
          <div style={{ height: "100%", width: "80%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Input disabled={(submitData.Counter) ? false : true} style={{ width: "80%", height: "100%" }} placeholder="Enter Interest" onChange={(e) => handleTextChange(e, 'Amount')} />
          </div>
        </div> */}
        <div style={{ height: "7%", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div style={{ height: "100%", width: "10%" }}>
            <p style={{ fontSize: 17 }}>Description</p>
          </div>
          <div style={{ height: "100%", width: "80%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <TextArea disabled={(submitData.Counter) ? false : true} style={{ width: "80%", height: "100%" }} rows={4} onChange={(e) => handleTextChange(e, 'Description')} />
          </div>
        </div>
        <div style={{ height: "7%", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div style={{ height: "100%", width: "10%" }}>

          </div>
          <div style={{ height: "100%", width: "72%", display: "flex", alignItems: "center" }}>
            <Button disabled={(submitData.Counter) ? loading : true} type="primary" icon={<CheckSquareOutlined />} style={{ backgroundColor: ColorCode.GreenCode }} size={'large'} onClick={handleSubmit}>
              Submit
            </Button>
            <Spin spinning={loading}></Spin>
          </div>
        </div>


      </div> : <SuccessPage />}
      
    </div >
  )
}

export default DepositMain