import React, { useEffect, useRef, useState } from 'react';
import { Button, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { CSVLink } from 'react-csv';
import { useLocation } from 'react-router-dom';
import { BackendRootStrings } from '../../Routes';
import axios from 'axios';
import moment from 'moment';
import generatePDF, { Margin } from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';

interface TransformedDataInterface {
  Repayment_Date: String;
  Principal_Amount: String;
  Late_Penalty: String;
  Total_Interest: String;
  Total_Amount: String;
}


interface DataType {
  key: String;
  Repayment_Date: String;
  Total_Interest: String;
  Principal_Amount: String;
  Interest: String;
  Late_Penalty: String;
  LoanProduct: String;
  Balance: String;
  Payment_Months: TransformedDataInterface[]
}



const columns: ColumnsType<TransformedDataInterface> = [
  {
    title: 'Date',
    dataIndex: 'Repayment_Date',
    key: 'Repayment_Date',

  },

  {
    title: 'Principal Amount(₹)',
    dataIndex: 'Principal_Amount',
    key: 'Principal_Amount',

  },
  {
    title: 'Interest(₹)',
    dataIndex: 'Total_Interest',
    key: 'Total_Interest',
  },
  {
    title: 'Late Penalty(₹)',
    dataIndex: 'Late_Penalty',
    key: 'Late_Penalty',
  },
  {
    title: 'Total Amount(₹)',
    dataIndex: 'Total_Amount',
    key: 'Total_Amount',
  },
];





const headers = [
  {
    label: "Date", key: "Repayment_Date"
  },
  {
    label: "Total Amount", key: "Total_Amount"
  },
  {
    label: "Principal Amount", key: "Principal_Amount"
  },
  {
    label: "Interest", key: "Total_Interest"
  },
  {
    label: "Late Penalty", key: "Late_Penalty"
  }

]



const App: React.FC = () => {

  const location = useLocation();

  const printRef = useRef<any>();
  let [isPdfTriggered, setIsPdfTriggered] = useState(false);
  const [isPrintTriggered, setIsPrintTriggered] = useState(false);
  const [renderKey, setRenderKey] = useState(Math.random());


  const [data, setData] = useState<DataType>({ key: "", Repayment_Date: "", Total_Interest: "", Principal_Amount: "", Interest: "", Late_Penalty: "", Payment_Months: [], Balance: "", LoanProduct: "" });
  let [trandformedData, setTransformedData] = useState<TransformedDataInterface[]>([]);
  let [unpaidDates, setUnpaidDates] = useState("");
  const [loanData, setLoanData] = useState([]);

  let [key, setKey] = useState(11);

  useEffect(() => {
    axios.get(`${BackendRootStrings.apiHome}/getLoanRepaymentByID/${location.state.currentItem[0].UniqueLoanID}`)
      .then((data) => {
        const { message } = data.data;
        // console.log(message[0])
        setData(message[0]);
      })

  }, [])


  useEffect(() => {
    // console.log(trandformedData);
  }, [trandformedData])




  useEffect(() => {
    if (data.Payment_Months.length !== 0) {
      let loanDataPaymentMonths = data.Payment_Months[0];
      let loanData = data;
      setTransformedData([]);
      trandformedData = ([]);
      if (data.Payment_Months.length > 1) {

        if (key === 12) {
          let flag = false;
          console.log(data, " is data")
          setTransformedData([]);
          trandformedData = [];

          data?.Payment_Months.map((val, ind, arr) => {
            if (flag) {
              // let newData = {};
              // newData = {
              //   ...newData,
              //   Date: val.Repayment_Date,
              //   payAmount: parseInt(val.Principal_Amount.toString()) + parseInt(val.Total_Interest.toString()) + parseInt(val.Late_Penalty.toString()),
              //   Repayment_Date: val.Repayment_Date,
              //   Principal_Amount: val.Principal_Amount,
              //   Interest: val.Interest,
              //   Late_Penalty: val.Late_Penalty
              // }
              // setTransformedData(prevState => ({
              //   ...prevState,
              //   ...newData
              // }));

              setTransformedData(prevState => [
                ...prevState,
                {
                  Total_Amount: (parseInt(val.Principal_Amount.toString()) + parseInt(val.Total_Interest.toString()) + parseInt(val.Late_Penalty.toString())).toString(),
                  Repayment_Date: val.Repayment_Date,
                  Principal_Amount: val.Principal_Amount,
                  Interest: val.Total_Interest,
                  Late_Penalty: val.Late_Penalty,
                  LoanProduct: "",
                  Total_Interest: val.Total_Interest,
                  Balance: "",
                  Status: "Paid"
                }
              ]);
            }
            flag = true;
          })


          // for (let i = 0; i < 12 - data.Payment_Months.length - 1; i++) {
          //   setTransformedData(prevState => [
          //     ...prevState,
          //     {
          //       payAmount: "0",
          //       Repayment_Date: val.Repayment_Date,
          //       Principal_Amount: val.Principal_Amount,
          //       Interest: val.Interest,
          //       Late_Penalty: val.Late_Penalty,
          //       Total_Interest: val.Total_Interest
          //     }
          //   ]);
          // }

        }
      }


    }


    // console.log(trandformedData, "transformed data")
  }, [data])

  useEffect(() => {
    if (data?.LoanProduct !== "" && key === 11) {
      axios.get(`${BackendRootStrings.apiHome}/getAllLoanProductByLoanProductName/${data.LoanProduct}`)
        .then(({ data }) => {
          const { message } = data;
          console.log(message[0].rate, message[0].term_period);
          setData((prevState: DataType) => {
            return {
              ...prevState,
              Interest: (message[0].term_period !== "Yearly") ? (parseInt(message[0].rate) * 12) : (message[0].rate)
            }
          })
          setKey(12);
          key = 12;
        })
    }

  }, [data])



  const options = {
    // default is `save`
    method: "save" as const,
    filename: `Repayment Schedule ${location.state.currentItem[0].Borrower}.pdf`,
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    // resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: 'A4',
      // default is 'portrait'
      orientation: "portrait" as const,
    },
    // canvas: {
    //    // default is 'image/jpeg' for better size performance
    //    mimeType: 'image/png',
    //    qualityRatio: 1
    // },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break, 
    // so use with caution.
    // overrides: {
    //    pdf: {
    //       compress: true
    //    },
    //    canvas: {
    //       useCORS: true
    //    }
    // },
  };

  const handlePDFPrint = () => {
    generatePDF(printRef, options);
  };


  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    setRenderKey(Math.random());
    if (isPdfTriggered === true) {
      handlePDFPrint();
      setIsPdfTriggered(false);
    }
  }, [isPdfTriggered])

  useEffect(() => {
    setRenderKey(Math.random());
    if (isPrintTriggered === true) {
      handlePrint();
      setIsPrintTriggered(false);
    }
  }, [isPrintTriggered])




  const csvLink = {
    filename: `Repayment Schedule ${location.state.currentItem[0].Borrower}.csv`,
    headers: headers,
    data: trandformedData,
  }


  return (
    <div ref={printRef}>
      <div style={{ width: "100%", height: 100, display: "flex", justifyContent: "center" }}>
        <div style={{ width: "90%", display: "flex", alignItems: "center", flexDirection: "column" }}>
          <h1 style={{ height: "fit-content", margin: 0 }}>HOSPITAL OFFICE FUND</h1>
          <h3 style={{ height: "fit-content", margin: 0 }}>Repayments Schedule</h3>
          <p style={{ height: "fit-content", margin: 0 }}>{location.state.currentItem[0].Borrower + ',' + location.state.currentItem[0].LoanID
          }</p>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
        <div style={{ width: "9%", display: (isPrintTriggered || isPdfTriggered)?"none":"flex", justifyContent: "space-between", marginTop: 20, alignItems: "center" }}>
          <CSVLink {...csvLink} style={{ display: "flex", textDecoration: "none", color: "black", backgroundColor: "white", height: 32, borderRadius: 5, justifyContent: "center", alignItems: "center" }}><Button style={{ backgroundColor: "gray", color: "white" }}>Excel</Button></CSVLink>
          <Button style={{ backgroundColor: "red", color: "white" }} onClick={() => { setRenderKey(Math.random()); setIsPdfTriggered(true) }}>PDF</Button>
          <Button style={{ backgroundColor: "green", color: "white" }} onClick={() => { setRenderKey(Math.random()); setIsPrintTriggered(true) }}>Print</Button>
        </div>
      </div>
      <Table pagination={isPrintTriggered || isPdfTriggered ? false : { position: ['topLeft'] }} columns={columns} dataSource={trandformedData} />
    </div>
  )
}

export default App;