import { Button, Input, message } from 'antd'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { BackendRootStrings } from '../Routes';
import Cookies from 'universal-cookie';

const ChangePassword = () => {
    const [inputData, setInputData] = useState({currentPassword:'',newPassword:'',repeatedPassword:'',Email:''});
    const [loading, setLoading] = useState(false);
    const cookies = new Cookies();

    useEffect(() => {
    let temp1= cookies.get("Email");
    setInputData((prevState)=>{
        return{
            ...prevState,
            Email:temp1
        }
    })
    }, [])
    

    const handlePasswordChange=async()=>{
        
        setLoading(true);
        await axios.post(`${BackendRootStrings.apiHome}/changePassword`,inputData);
        setLoading(false);
    }

    return (
        <div style={{ display: "flex", height: "100%", width: "100%", justifyContent: "center" }}>
            <div style={{ display: "flex", height: "50%", width: "50%", border: "2px solid", flexDirection: "column", justifyContent: "space-evenly",alignItems:"center" }}>
                <div style={{width:"80%"}}>
                    <p>Enter Current Password</p>
                    <Input.Password
                    onChange={(e)=>{
                        setInputData((prevState)=>{
                            return{
                                ...prevState,
                                currentPassword:e.target.value
                            }
                        })
                    }}
                    placeholder='Current Password' />
                </div>
                <div style={{width:"80%"}}>
                    <p>Enter New Password</p>
                    <Input.Password 
                    onChange={(e)=>{
                        setInputData((prevState)=>{
                            return{
                                ...prevState,
                                newPassword:e.target.value
                            }
                        })
                    }}
                    placeholder='New Password' />
                </div>
                <div style={{width:"80%"}}>
                    <p>Repeat New Password</p>
                    <Input.Password
                    onChange={(e)=>{
                        setInputData((prevState)=>{
                            return{
                                ...prevState,
                                repeatedPassword:e.target.value
                            }
                        })
                    }}
                    placeholder='New Password' />
                </div>
                <div style={{display:"flex",justifyContent:"center",}}>
                    <Button disabled={loading} onClick={handlePasswordChange} style={{ width: "100%",backgroundColor:"#00bb00",color:"white" }}>Change Password</Button>
                </div>

            </div>
        </div>
    )
}

export default ChangePassword