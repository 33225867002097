import { ActionTypes } from '../constants/ActionTypes.js'


export const setTotalCollection = (products) => {
    return {
        type: ActionTypes.SET_TOTAL_COLLECTION,
        payload: products,
    }
}
export const setCounterCollection = (products) => {
    return {
        type: ActionTypes.SET_COUNTER_COLLECTION,
        payload: products,
    }
}
export const setSelectedMenuItemToken = (products) => {
    return {
        type: ActionTypes.SET_SELECTED_MENU_ITEM_TOKEN,
        payload: products,
    }
}
export const setTotalCollectionAmount = (products) => {
    return {
        type: ActionTypes.SET_TOTAL_COLLECTION_AMOUNT,
        payload: products,
    }
}
export const setCashInHandAmountAmount = (products) => {
    return {
        type: ActionTypes.CASH_IN_HAND,
        payload: products,
    }
}
export const setDepositLateFeesAmount = (products) => {
    return {
        type: ActionTypes.SET_DEPOSIT_LATE_FEES,
        payload: products,
    }
}
export const setCurrentCounterNo = (products) => {
    return {
        type: ActionTypes.SET_CURRENT_COUNTER_NO,
        payload: products,
    }
}
export const setCounterBalance = (products) => {
    return {
        type: ActionTypes.SET_COUNTER_BALANCE,
        payload: products,
    }
}

export const setCounterPermission = (products) => {
    return {
        type: ActionTypes.SET_COUNTER_PERMISSION,
        payload: products,
    }
}